import { Button } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

interface NavigationItemProps {
    label: string;
    route: string;
    onClick?: any;
}

/**
 * BaseLayout is the page that will wrap all the common pages in the application
 */
const NavigationItem: React.FC<NavigationItemProps> = ({ label, route, onClick }) => {

    return (
        <Link to={route}>
            <Button
                _hover={{ color: 'teal.500' }}
                _active={{ bg: 'teal.700', color: 'white' }}
                onClick={onClick}
                padding={2}
                borderRadius={6}
                fontSize={16}
                w="100%"
                colorScheme='teal'
                color={'teal.700'}
                isActive={window.location.pathname === route}
                variant='link'
                justifyContent="start!important">
                {label}
            </Button>
        </Link>
    )
}

export default NavigationItem