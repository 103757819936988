import { ChevronDownIcon } from '@chakra-ui/icons';
import { HStack, Menu, MenuButton, Button, MenuList, MenuOptionGroup, MenuItemOption, Popover, PopoverTrigger, PopoverContent, PopoverCloseButton, PopoverArrow, PopoverBody, useDisclosure, Icon } from '@chakra-ui/react';
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { SimpleDayPicker } from '../Marketplace/DayPickerV2';

const menuOptions = [
    { value: 'all', label: 'Alla' },
    { value: 'ongoing', label: 'Pågående' },
    { value: 'waiting', label: 'Kommande' },
    { value: 'finished', label: 'Avslutade' },
    { value: 'canceled', label: 'Avbokade' },
];

const FilterSection: React.FC<{
    setStatus: (val: string) => void;
    status: string;
    setSelectedDate: (val: string) => void;
    selectedDate: string;
}>
    = ({ setStatus, status, setSelectedDate, selectedDate }) => {
        const { onClose, onOpen, isOpen } = useDisclosure();
        const menuLabel = menuOptions.find(option => option.value === status)?.label;

        function handleChangeTab(tab: string) {
            setStatus(tab)
        }

        return (
            <HStack spacing={4}>
                <Menu>
                    <MenuButton w={[20, 40, 40, 40]} h={8} fontSize={[12, 14, 14, 14]} as={Button} rightIcon={<ChevronDownIcon />} >
                        {menuLabel}
                    </MenuButton>
                    <MenuList minWidth='200px'>
                        <MenuOptionGroup title='Status på arbete' type='radio' onChange={val => handleChangeTab(val as string)}>
                            {menuOptions.map(option => (
                                <MenuItemOption key={option.value} value={option.value}>{option.label}</MenuItemOption>
                            ))}
                        </MenuOptionGroup>
                    </MenuList>
                </Menu>
                <Popover
                    placement='bottom'
                    isOpen={isOpen}
                    onOpen={onOpen}
                >
                    <PopoverTrigger>
                        <Button size='sm' rightIcon={<ChevronDownIcon />}>{selectedDate || `Välj kalenderdag`}</Button>
                    </PopoverTrigger>

                    <PopoverContent p={2} width='auto'>
                        <PopoverCloseButton onClick={onClose} />
                        <PopoverArrow />
                        <PopoverBody p={0} mt={6}>
                            <SimpleDayPicker setSelectedDate={setSelectedDate} onClose={onClose} />
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
                {selectedDate && (
                    <Icon borderRadius={'full'} cursor={'pointer'} fontSize={'xl'} as={FaTimes} color={'teal.700'} _hover={{ color: 'teal.900' }} onClick={() => {
                        setSelectedDate('');
                    }} />
                )}
            </HStack>
        )
    }

export default FilterSection;