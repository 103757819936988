import React from 'react';
import { motion } from 'framer-motion';

const CheckAnimation: React.FC<{ isChecked: boolean, h?: number, w?: number }> = ({ isChecked, h, w }) => {
    const checkVariants = {
        hidden: {
            pathLength: 0,
            opacity: 0,
        },
        visible: {
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: { duration: .6, ease: 'easeInOut' },
                opacity: { duration: 0.5, ease: 'easeInOut' },
            },
        },
    };

    return (
        <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            className="checkmark"
            initial="hidden"
            animate={isChecked ? 'visible' : 'hidden'}
            width={w || 24}
            height={h || 24}
        >
            <motion.circle
                cx="26"
                cy="26"
                r="25"
                fill="none"
                stroke="#4caf50"
                strokeWidth="2"
                initial={{ scale: 0 }}
                animate={{ scale: isChecked ? 1 : 0 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
            />
            <motion.path
                fill="none"
                stroke="#4caf50"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14 27l10 10L38 17"
                variants={checkVariants}
            />
        </motion.svg>
    );
};

export default CheckAnimation;
