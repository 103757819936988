import { Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Input, DrawerFooter, Button, Heading, Text, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Tooltip, Stack } from '@chakra-ui/react';
import * as React from 'react';

interface FilterDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	btnRef: any;
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({ isOpen, onClose, btnRef }) => {
	const [distance, setDistance] = React.useState(15)
	const [distanceTooltip, setDistanceTooltip] = React.useState(false)
	
	const [maxAge, setMaxAge] = React.useState(10)
	const [maxAgeTooltip, setMaxAgeTooltip] = React.useState(false)

	return (
		<>
			<Drawer
				size={'sm'}
				isOpen={isOpen}
				placement='right'
				onClose={onClose}
				finalFocusRef={btnRef}

			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>Filtrering</DrawerHeader>
					<DrawerBody>
						<Stack spacing={16}>

							<Stack spacing={4}>
								<Stack spacing={2}>
									<Heading size="sm">Avstånd</Heading>
									<Text fontSize={14}>Sätt avståndet på förfrågningar du vill ta emot</Text>
								</Stack>

								<Slider
									id='slider'
									defaultValue={15}
									min={0}
									width={'80%'}
									max={50}
									colorScheme='teal'
									onChange={(v) => setDistance(v)}
									onMouseEnter={() => setDistanceTooltip(true)}
									onMouseLeave={() => setDistanceTooltip(false)}
								>
									<SliderMark value={0} mt='1' fontSize='sm'>
										0km
									</SliderMark>
									<SliderMark value={50} mt='1' ml='-15.5'  fontSize='sm'>
										50km
									</SliderMark>
									<SliderTrack>
										<SliderFilledTrack />
									</SliderTrack>
									<Tooltip
										hasArrow
										bg='teal.500'
										color='white'
										placement='top'
										isOpen={distanceTooltip}
										label={`${distance}km`}
									>
										<SliderThumb boxSize={5} />
									</Tooltip>
								</Slider>
							</Stack>
							<Stack spacing={4}>

								<Stack spacing={2}>
									<Heading size="sm">Bilens ålder</Heading>
									<Text fontSize={14}>Visa endast offertförfrågningar på bilar upp till en viss ålder</Text>
								</Stack>

								<Slider
									id='slider'
									defaultValue={10}
									min={0}
									width={'80%'}
									max={30}
									colorScheme='teal'
									onChange={(v) => setMaxAge(v)}
									onMouseEnter={() => setMaxAgeTooltip(true)}
									onMouseLeave={() => setMaxAgeTooltip(false)}
								>
									<SliderMark value={0} mt='1' fontSize='sm'>
										0 år
									</SliderMark>
									<SliderMark value={30} mt='1' ml='-15.5' fontSize='sm'>
										30år
									</SliderMark>
									<SliderTrack>
										<SliderFilledTrack />
									</SliderTrack>
									<Tooltip
										hasArrow
										bg='teal.500'
										color='white'
										placement='top'
										isOpen={maxAgeTooltip}
										label={`${maxAge} år`}
									>
										<SliderThumb boxSize={5} />
									</Tooltip>
								</Slider>
							</Stack>
						</Stack>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	)
}

export default FilterDrawer;