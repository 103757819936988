import { Box, Spinner as ChSpinner } from "@chakra-ui/react";
import React from "react";

export const Spinner: React.FC<{ height?: string | number, size?: 'xl' | 'lg' | 'sm' }> = ({ height, size = 'xl' }) => {
    return (
        <Box height={height || '60vh'} display="flex" justifyContent="center" alignItems="center">
            <ChSpinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.600'
                size={size}
            />
        </Box>
    )
}