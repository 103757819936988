import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const isProd =  window.location.hostname === 'app.bilgo.se';
 
// Initialize Firebase
const app = initializeApp ({
    apiKey: isProd ? 'AIzaSyB6kPch-QgLMto48Pu2sJawxQBxcI10jWQ' : 'AIzaSyB6kPch-QgLMto48Pu2sJawxQBxcI10jWQ',
    authDomain: isProd ? "bilgo-prod.firebaseapp.com" : "bilsmart.firebaseapp.com",
    projectId: isProd ? "bilgo-prod": "bilsmart",
    storageBucket: isProd ? "bilgo-prod.appspot.com" : "bilsmart.appspot.com",
    messagingSenderId: isProd ? "116869736876" : "442483110622",
    appId: isProd ? "1:116869736876:web:3d0b0452329e5c08127209" : "1:442483110622:web:a210d8aea55720f2aa484f",
    measurementId: isProd ? "G-CKG5PSPS77" : "G-MJ59V3HFZ4"
});
 
// Firebase storage reference
const storage = getStorage(app);
export default storage;