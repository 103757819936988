import { Button, Icon, Stack, HStack, Text, Heading } from "@chakra-ui/react";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";

const AdditionalWorkSuccess: React.FC<{ onCloseModal: () => void; }> = ({ onCloseModal }) => {

    return (
        <Stack spacing={4}>
            <HStack justifyContent={'center'}>
                <Stack spacing={8} alignItems={'center'} textAlign='center'>
                    <Heading size={'md'} opacity={.8}>Tilläggsarbete skickat till kund</Heading>
                    <Icon as={FaCheckCircle} color='teal.400' width={32} height={32} />
                    <Text>Kunden har nu blivit meddelad om ett nytt tilläggsarbete.</Text>
                </Stack>
            </HStack>
            <Stack spacing={8} pt={16}>
                <Button
                    colorScheme='teal'
                    variant='solid'
                    onClick={onCloseModal}
                >
                    Stäng
                </Button>
            </Stack>
        </Stack>
    )
}

export default AdditionalWorkSuccess;
