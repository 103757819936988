import useSWRImmutable from 'swr/immutable'
import { CompanyInfo, CompanyServiceResponse, MediaAsset, OpeningHours } from '../types'
import { Endpoints } from './endpoints'
import { fetcher } from "./utils"

export function useCompanyInfo() {
    const { data, error, mutate, isValidating } = useSWRImmutable<CompanyInfo, Error>(`${Endpoints.COMPANY_INFO}/me`, fetcher)

    if (data) {
        localStorage.setItem('companyName', data.companyName)
        localStorage.setItem('companyEmail', data.companyEmail)
    }

    return {
        companyInfo: data,
        isValidating,
        isLoading: !error && typeof data === undefined,
        isError: error,
        mutateCompanyInfo: mutate
    }
}

export function useMediaAssets() {
    const { data, error, mutate } = useSWRImmutable<MediaAsset[], Error>(Endpoints.COMPANY_ASSETS, fetcher)
    return {
        mediaAssets: data,
        isLoadingMediaAssets: !error && !data,
        isErrorMediaAssets: error,
        mutateMediaAssets: mutate
    }
}


export function useOpeningHours() {
    const { data, error, mutate } = useSWRImmutable<OpeningHours[], Error>(Endpoints.COMPANY_OPENING_HOURS, fetcher)

    return {
        openingHours: data,
        isLoadingOpeningHours: !error && !data,
        isErrorOpeningHours: error,
        mutateOpeningHours: mutate
    }
}

export function useCompanyServices() {
    const { data, error, mutate } = useSWRImmutable<CompanyServiceResponse, Error>(Endpoints.COMPANY_SERVICES, fetcher)

    return {
        companyServices: data,
        isLoadingCompanyServices: !error && !data,
        isErrorCompanyServices: error,
        mutateCompanyServices: mutate
    }
}