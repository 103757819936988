import React, { Fragment, useRef, useState } from "react";
import { Box, Text, Icon, Stack, Button, HStack, useMediaQuery, useDisclosure, SkeletonCircle, SkeletonText, Center, Badge, Tooltip, Divider, Heading, Flex } from "@chakra-ui/react";
import { FaMapMarkerAlt, FaPlus, FaRegComment, FaRocket, FaTrashAlt } from "react-icons/fa";
import 'moment/locale/sv'
import moment from "moment";
import LicensePlate from "../LicensePlate";
import Reparations from "./Reparations";
import Service from "./Service";
import AddOfferConfirmation from "../modals/AddOffer/AddOfferConfirmation";
import { UseOfferRequests, useOfferRequests } from "../../hooks/api/offerRequests";
import { axiosPut } from "../../hooks/api/utils";
import { Endpoints } from "../../hooks/api/endpoints";
import { useCustomToast } from "../../hooks/useCustomToast";
import isEmpty from 'lodash/isEmpty';
import { MARKETPLACE_CONTENT_WIDTH } from "../../pages/Marketplace";
import { useCompanyFeatures } from "../../hooks/api/feature";
import { useSearchParams } from "react-router-dom";
import noDataImage from '../../common/undraw_happy_music_g6wc.svg';

const demoClients = ["VpT7QMIuMtVMyWS4zOCds9qbFh32", "R70dUJuzYdgaVjRrsl3CvcjLhHu1", "GJB6LdiZd4QPsRVed8dFSSFM2sr1"];

const OfferEnquiriesList: React.FC = () => {
    const { isLoading, mutateOfferRequests, offerRequests: rawEnquiries }: UseOfferRequests = useOfferRequests();
    const { features } = useCompanyFeatures();
    const demoEnquiries = rawEnquiries?.filter(e => demoClients?.includes(e.clientId) && features?.includes('demo-for-review')) || []
    const enquiries = rawEnquiries?.filter(e => !demoClients?.includes(e.clientId));
    const [searchParams] = useSearchParams();
    const highlightedEnquiryId = searchParams.get('highlight');

    const { infoToast } = useCustomToast();
    const [isLargeScreen] = useMediaQuery('(min-width: 1024px)')

    const fileUploadInputRef = useRef(null);
    const offerEnquiryRef = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [lisItemsToShow, setListItemsToShow] = useState(8);
    const [isLoadingRejection, setIsloadingRejection] = useState<string | null>(null);
    // Temp state make it seem like the loading is improved when refetching
    const [isRemoved, setIsRemoved] = useState<string[]>([])
    const [isMobile] = useMediaQuery('(max-width: 480px)')

    const directedEnquiries = enquiries?.filter(enquiry => enquiry?.directedCompanyId);

    if (isLoading) {
        return (
            <Stack spacing={4} p={[4, 4, 0, 0]} margin={'0 auto'}>
                <Box padding='6' bg='white' height={200}>
                    <SkeletonCircle size='10' />
                    <SkeletonText mt='6' noOfLines={4} spacing='6' />
                </Box>
                <Box padding='6' bg='white' height={200} >
                    <SkeletonCircle size='10' />
                    <SkeletonText mt='6' noOfLines={4} spacing='6' />
                </Box>
                <Box padding='6' bg='white' height={200} >
                    <SkeletonCircle size='10' />
                    <SkeletonText mt='6' noOfLines={4} spacing='6' />
                </Box>
            </Stack>
        )
    }

    const highlightedEnquiry = enquiries?.find(enq => enq?.offerEnquiryId === highlightedEnquiryId);
    const normalEnquiries = enquiries?.filter(enq => enq?.offerEnquiryId !== highlightedEnquiryId)
    const allEnquiries = enquiries && [highlightedEnquiry, ...directedEnquiries, ...normalEnquiries, ...demoEnquiries?.filter(enq => !enq?.directedCompanyId)];

    return (
        <Box>
            <AddOfferConfirmation isOpenModal={isOpen} onCloseModal={onClose} enquiry={offerEnquiryRef.current} mutateOfferRequests={mutateOfferRequests} />
            {allEnquiries?.filter(enq => enq)?.length === 0 && (
                <Center height={96} mt={[8, 10, 20, 12]} pb={12}>
                    <Stack spacing={12} alignItems={'center'}>
                        <Box textAlign={'center'}>
                            <Heading size={'sm'}>Det finns inga offertförfrågningar just nu.</Heading>
                            <Text fontSize={14}>Men oroa dig inte, dem är på gång!</Text>
                        </Box>

                        <img src={noDataImage} width={isMobile ? 180 : 260} alt="bild som visas när data saknas" />
                    </Stack>
                </Center>
            )}
            {allEnquiries?.map((offerReq, i) => {
                if (!offerReq) {
                    return null;
                }

                if (i === lisItemsToShow) {
                    return (
                        <Center pt={4} key={i}>
                            <Button
                                key={`${offerReq.createdAt}-${offerReq.regNo}`}
                                onClick={() => setListItemsToShow(lisItemsToShow + 5)}
                                colorScheme='teal'
                                width={MARKETPLACE_CONTENT_WIDTH}>
                                Visa fler
                            </Button>
                        </Center>
                    )
                }

                if (i > lisItemsToShow) {
                    return <Fragment key={`${offerReq.createdAt}-${offerReq.regNo}`} />;
                }

                if (isRemoved?.includes(offerReq?.offerEnquiryId)) {
                    return <Fragment key={`${offerReq.createdAt}-${offerReq.regNo}`} />;
                }

                const isDirectedEnquiry = !isEmpty(offerReq?.directedCompanyId);
                const isHighlightedEnquiry = offerReq?.offerEnquiryId === highlightedEnquiryId;
                const isLastItem = i === allEnquiries.length - 1;

                return (
                    <>
                        <Box borderBottomRadius={isLastItem ? 16 : 0} position={'relative'} key={`${offerReq.createdAt}-${offerReq.regNo}`} margin={'0 auto'} bg={isHighlightedEnquiry ? 'gray.100' : 'white'} >
                            {/* <Box shadow={isHighlightedEnquiry ? 'xl' : 'none'} position={'relative'} key={`${offerReq.createdAt}-${offerReq.regNo}`} margin={'0 auto'} border={isDirectedEnquiry ? '2px solid' : '1px solid'} borderColor={isDirectedEnquiry ? 'green.400' : isHighlightedEnquiry ? 'gray.600' : 'gray.300'} borderRadius={16} mb={4} backgroundColor="white"> */}
                            {isDirectedEnquiry && (
                                <Tooltip
                                    hasArrow
                                    bg='teal.600'
                                    color='white'
                                    placement='top'
                                    label={'Riktad offertförfrågan - den här offertenförfrågan är skickad av kunden riktad till endast er!'}
                                >
                                    <Center position={'absolute'} right={-4} top={-4} padding={3} bg={'teal.200'} borderRadius={'full'} cursor={'pointer'}>
                                        <Icon as={FaRocket} fontSize={[20, 24, 24, 24]} color='InfoText' />
                                    </Center>
                                </Tooltip>

                            )}
                            <Box padding={[0, 4, 4, 4]} pt={4} pb={4}>
                                <Stack padding={[0, 2]} spacing={4}>
                                    {!isLargeScreen && (
                                        <Stack spacing={4} w={['100%', '100%', '60%', '60%']}>
                                            <HStack alignItems="start" justifyContent="space-between" paddingRight={2}>
                                                <LicensePlate regNo={offerReq.regNo} size={'xs'} />
                                                {isMobile && (
                                                    <Text fontSize={10}>Skapad {moment(offerReq.createdAt, 'YYYY-MM-DDTHH:mm:ss').fromNow()}</Text>
                                                )}
                                            </HStack>
                                        </Stack>
                                    )}
                                    <Flex flexDir={isMobile ? 'column' : 'row'} align='start' w={'100%'} justifyContent={'space-between'}>
                                        <Stack spacing={4} >
                                            {offerReq?.reparations?.length > 1 ? (
                                                <Stack spacing={3}>
                                                    <Service enquiry={offerReq} />
                                                    <Reparations enquiry={offerReq} />
                                                </Stack>
                                            ) : (
                                                <Stack spacing={3}>
                                                    <Service enquiry={offerReq} />
                                                    <Reparations enquiry={offerReq} />
                                                </Stack>
                                            )}
                                            {offerReq.comment && (
                                                <HStack align={'start'}>
                                                    <Icon as={FaRegComment} color="gray.500" />
                                                    <Text fontSize={14}>{offerReq.comment}</Text>
                                                </HStack>
                                            )}
                                        </Stack>

                                        {!isMobile && (
                                            <Stack alignItems='flex-end'>
                                                <Text w={'max-content'} fontSize={10}>Skapad {moment(offerReq.createdAt, 'YYYY-MM-DDTHH:mm:ss').fromNow()}</Text>
                                                {isLargeScreen && (
                                                    <LicensePlate size={'xs'} regNo={offerReq.regNo} />
                                                )}
                                                <Stack>
                                                    <HStack alignItems="center">
                                                        <Icon as={FaMapMarkerAlt} />
                                                        <Text fontSize={12}>{offerReq.distance} km ifrån</Text>
                                                    </HStack>
                                                </Stack>
                                            </Stack>
                                        )}
                                    </Flex>
                                </Stack>
                                <HStack justifyContent={['start', 'start', 'start', 'start']} p={2} paddingTop={4} spacing={4}>
                                    <Button colorScheme='gray' fontSize={isMobile && 12} variant={'link'} size={'sm'} isLoading={offerReq.offerEnquiryId === isLoadingRejection} onClick={async () => {
                                        // put request where we update status with reject or something
                                        try {
                                            setIsloadingRejection(offerReq.offerEnquiryId)
                                            await axiosPut(`${Endpoints.OFFER_ENQUIRY}/${offerReq.offerEnquiryId}/reject`)
                                            setIsRemoved([...isRemoved, offerReq.offerEnquiryId])
                                            mutateOfferRequests()
                                            setIsloadingRejection(null)

                                        } catch (error) {
                                            setIsloadingRejection(null)
                                            setIsRemoved(null)
                                            return infoToast('Det gick inte att arkivera den här förfrågan just nu, försök snart igen.')
                                        }
                                    }}
                                        leftIcon={<Icon as={FaTrashAlt} fontSize={14} />}
                                    >
                                    </Button>

                                    <Button size={'sm'} fontSize={isMobile && 12} colorScheme={'blue'} leftIcon={<Icon as={FaPlus} />} variant={'link'} onClick={() => {
                                        offerEnquiryRef.current = offerReq;
                                        onOpen()
                                    }}>
                                        <input
                                            type="file"
                                            ref={fileUploadInputRef}
                                            style={{ display: 'none' }}
                                        />
                                        Lägg till offert
                                        {/* -> Öppna modal där man har knapp "skicka" och ev även fält att fylla i gällande pris och delar */}
                                    </Button>
                                </HStack>
                            </Box>
                        </Box>
                        {!isLastItem && (
                            <Divider />
                        )}
                    </>
                )
            })}
        </Box>

    )

}

export default OfferEnquiriesList;