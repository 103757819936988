import { Box, Button, Center, chakra, Divider, Heading, HStack, Icon, IconButton, Input, InputGroup, InputRightElement, Select, shouldForwardProp, Stack, Text, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FaBolt, FaEdit, FaPlus, FaTrashAlt, FaUndo } from "react-icons/fa";
import { CustomServiceSelect } from "../../CreateBooking/WorkSelectorV2";
import { motion, isValidMotionProp, AnimatePresence } from "framer-motion";
import { InfoIcon } from "@chakra-ui/icons";
import WorkOfferHelpModal from "../AddWorkDetailsModal";
import OfferEnquiryPopover from "./OfferEnquiryPopover";
import { OfferEnquiry } from "../../Marketplace/types";
import CheckAnimation from "../../Animations/CheckAnimation";
import { useCustomToast } from "../../../hooks/useCustomToast";
import { SETTINGS, useCompanySettings } from "../../../hooks/api/companySettings";
import { Endpoints } from "../../../hooks/api/endpoints";
import { axiosPost } from "../../../hooks/api/utils";
import { WorkArticleSelect } from "../../CreateBooking/WorkArticlesSelect";
import EditAddonArticlePercentageCostModal from "../EditAddonArticlePercentageCostModal";

const ChakraIcon = chakra(motion.div, {
    /**
     * Allow motion props and non-Chakra props to be forwarded.
     */
    shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});


// TODO: Move to backend as metasettings
export const units = [
    {
        key: 'meter',
        label: 'meter',
        abbreviation: 'm'
    },
    {
        key: 'unit',
        label: 'styck',
        abbreviation: 'st'
    },
    {
        key: 'liter',
        label: 'liter',
        abbreviation: 'L'
    }
]

export type ArticleDetails = {
    articleName: string;
    articlePrice: string;
    articleAmount: string;
    valueType: string;
    articleUnit: string;
    id: number;
}

type AddWorkDetailsFormProps = {
    rerender?: (val: any) => void;
    resetFields?: boolean;
    unFinishedWorkArticleError?: boolean;
    setUnfinishedWorkArticleError?: (val: boolean) => void;
    source?: 'offer' | 'additionalOffer' | 'booking'; // Slight different validation when rendering this when about to send new offer.
    articleRowRef?: MutableRefObject<any>;
    setArticleFieldsIsDirty?: (val: boolean) => void;
    selectedOfferEnquiry?: OfferEnquiry;
}

export function formatMoney(value: any): string {
    return new Intl.NumberFormat('sv-se', { style: 'currency', currency: 'SEK' }).format(value || 0);
}

export function getLaborCostSum(price: string, quantity: string) {
    if (!price) {
        return 0;
    }

    return parseFloat(price.replace(',', '.')) * parseFloat(quantity) || 0;
}

export function getCostSum(workArticles: ArticleDetails[], addonPercentage: string = '0'): { sum: number, addonSum: number } {
    let sum = 0;
    workArticles?.forEach(work => {
        if (work.articlePrice && work.articleAmount) {
            return sum += parseFloat(work.articlePrice.replace(',', '.')) * parseFloat(work.articleAmount)
        }
    })

    const addonDecimal = (parseFloat(addonPercentage) / 100) + 0;

    return {
        sum,
        addonSum: sum * addonDecimal
    };
}

export function getVatCost(sumTotal: number): number {
    return sumTotal - (sumTotal / 1.25);
}


// Dem kan också sätta en beskrivning om dem vill som är som en template typ, kanske här att vi kan använda Gemini åt dem.
export const VALUE_TYPE = {
    SPARE_PARTS: 'spareParts',
    WORK: 'work'
}

export const SELECTED_UNIT = {
    METER: 'meter',
    UNIT: 'unit'
}

const AddWorkDetailsForm: React.FC<AddWorkDetailsFormProps> = ({ rerender, resetFields, unFinishedWorkArticleError, setUnfinishedWorkArticleError, source, articleRowRef, setArticleFieldsIsDirty, selectedOfferEnquiry }) => {
    // Keep local state of the added work for displayability
    const [addedWork, setAddedWork] = useState<ArticleDetails[]>([]);
    const { mutateCompanySettings, companySettings } = useCompanySettings();

    const hourlyWorkRateSetting = companySettings?.find(val => val.name === SETTINGS.HOURLY_WORK_RATE)?.value
    const addonArticleCostSetting = companySettings?.find(val => val.name === SETTINGS.ADDON_ARTICLE_PERCENTAGE_COST)?.value

    const initialArticleFormState = {
        articleName: '',
        articlePrice: '',
        articleAmount: '',
        valueType: VALUE_TYPE.SPARE_PARTS,
        articleUnit: SELECTED_UNIT.UNIT
    };

    const defaultHourlyRate = '1200';

    // TODO: Gär detta till en inställning som inlämningsdatum
    const initialLaborFormState = {
        laborCost: hourlyWorkRateSetting || defaultHourlyRate,
        laborHours: '1',
        articleAddonPercentageCost: '20',
        isDirtyLaborCost: false
    };

    const [articleFormValues, setArticleFormValues] = useState(initialArticleFormState);
    const [laborFormValues, setLaborFormValues] = useState(initialLaborFormState)
    const [unitValue, setUnitValue] = useState<string>(SELECTED_UNIT.UNIT)
    const [addMaterial, setAddMaterial] = useState<Boolean>(false)
    const [controlledReset, setControlledReset] = useState<number>(0)
    const [resetArticleSelect, setResetArticleSelect] = useState<boolean>(false);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { isOpen: isOpenAddonCostModal, onClose: onCloseAddonCostModal, onOpen: onOpenAddonCostModal } = useDisclosure();

    const [articleError, setArticleError] = useState<null | string>(null);
    const [workError, setWorkError] = useState<null | string>(null);
    const { successToast } = useCustomToast({ position: 'top-right' });
    const { sum, addonSum } = getCostSum(addedWork, addonArticleCostSetting);
    const sumTotal = sum + addonSum + getLaborCostSum(laborFormValues.laborCost, laborFormValues.laborHours);

    const { setValue, clearErrors } = useFormContext();
    const errorRef = useRef(null);
    const hasDirtyArticleFields = articleFormValues.articleName?.length > 0 && articleFormValues.articleAmount?.length > 0 && articleFormValues.articlePrice?.length > 0;
    const isOffer = source === 'offer';

    const [isMobile] = useMediaQuery('(max-width: 492px)')
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isCompleteSave, setIsCompleteSave] = useState(false);

    useEffect(() => {
        let timeout = null;
        const laborValueNotChanged = hourlyWorkRateSetting === laborFormValues.laborCost;
        if (isCompleteSave || laborValueNotChanged) {
            timeout = setTimeout(() => {
                setLaborFormValues({ ...laborFormValues, isDirtyLaborCost: false })
            }, 1500);
        }

        return () => clearTimeout(timeout)
    }, [setLaborFormValues, isCompleteSave, laborFormValues])

    // Set initial values here otherwise they will be undefined, later on we will fetch these from settings
    useEffect(() => {
        setValue('laborCost', initialLaborFormState.laborCost)
        setValue('laborHours', initialLaborFormState.laborHours)

        return () => {
            clearErrors()
        }
    }, [])

    useEffect(() => {
        if (resetFields) {
            setAddedWork([])
        }
    }, [resetFields])

    useEffect(() => {
        if (setArticleFieldsIsDirty && hasDirtyArticleFields) {
            setArticleFieldsIsDirty(hasDirtyArticleFields)
        }
    }, [setArticleFieldsIsDirty, hasDirtyArticleFields])

    // Function to handle changes to form field values
    const handleInputChange = (value: string | number, name: string, valueType?: typeof VALUE_TYPE.WORK | typeof VALUE_TYPE.SPARE_PARTS) => {
        setValue(name, value)
        setValue('currentValueType', valueType)

        // Update the state with the new value
        setArticleFormValues({
            ...articleFormValues,
            valueType,
            [name]: value,
        });
    };

    // Function to handle changes to form field values
    const handleInputChangeLaborForm = (value: string | number, name: string) => {
        // Update the state with the new value
        setValue(name, value)
        if (rerender) {
            rerender({})
        }
        const isDirtyLaborCost = name === 'laborCost' && value !== initialLaborFormState.laborCost ? true : laborFormValues.isDirtyLaborCost
        if (isCompleteSave && isDirtyLaborCost) {
            setIsCompleteSave(false)
        }
        setLaborFormValues({
            ...laborFormValues,
            [name]: value,
            isDirtyLaborCost: isDirtyLaborCost
        });
    };

    const handleRemoveWorkRow = (id: number) => {
        const updatedWorkList = addedWork.filter(work => work.id !== id)

        setAddedWork(updatedWorkList);
        setValue('addedWork', updatedWorkList)
        setValue('errorRef', errorRef)
        setControlledReset(prev => prev - 1)

        if (rerender) {
            rerender({})
        }
    }

    function isValidArticleInputValues() {
        if (!articleFormValues.articleAmount ||
            !articleFormValues.articlePrice ||
            !articleFormValues.articleName ||
            Number(articleFormValues.articleAmount) <= 0 ||
            Number(articleFormValues.articlePrice) <= 0) {
            return false
        }
        return true
    }

    function isValidWorkInputValue() {
        if (articleFormValues.articleName?.length === 0) {
            return false
        }
        return true;
    }

    async function handleSaveHourlyRate() {
        setIsLoadingSave(true)
        try {
            const payload = {
                setting: SETTINGS.HOURLY_WORK_RATE,
                settingValue: laborFormValues.laborCost
            }
            await axiosPost(`${Endpoints.COMPANY_SETTINGS_V1}/update`, payload)
            await mutateCompanySettings();
            setIsLoadingSave(false)
            setIsCompleteSave(true)
            return successToast('Arbetskostnad i timmen ändrad')
        } catch (error) {
            setIsCompleteSave(false)
            setIsLoadingSave(false)
        }
    }

    return (
        <Box>
            <WorkOfferHelpModal isOpenModal={isOpen} onCloseModal={onClose} />
            <EditAddonArticlePercentageCostModal isOpen={isOpenAddonCostModal} onClose={onCloseAddonCostModal} action={addonArticleCostSetting ? 'edit' : 'add'} settingsValue={addonArticleCostSetting} />
            <Box pb={6}>
                <HStack>
                    <Heading size={'sm'}>Arbetets omfattning</Heading>
                    <HStack align={'center'} color={'blue.600'} spacing={1} cursor={'pointer'} _hover={{ color: 'blue.500' }} onClick={() => onOpen()}>
                        <Text fontSize={12}>Hjälp</Text>
                        <InfoIcon fontSize={12} />
                    </HStack>
                </HStack>
            </Box>
            {!isOffer && (
                <Box pb={0} pos='relative' data-testid='add-work-container'>
                    {workError && (
                        <Text pos={'absolute'} zIndex={9999} top={-4} fontSize={10} color={'red.500'}>{workError}</Text>
                    )}
                    <HStack align={'center'}>
                        <CustomServiceSelect source={source} resetKey={controlledReset} handleInputChange={(val) => handleInputChange(val, 'articleName', VALUE_TYPE.WORK)} />
                        <IconButton
                            colorScheme='blue'
                            aria-label='Add work'
                            data-testid='add-work-icon-button'
                            size={isMobile ? 'xs' : 'sm'}
                            icon={<FaPlus size={isMobile ? 10 : 16} />}
                            onClick={() => {
                                if (!isValidWorkInputValue()) {
                                    return setWorkError('Välj ett alternativ för arbete')
                                }

                                const id = addedWork.length + 1;
                                const listWithUpdatedValues = [...addedWork, { ...articleFormValues, valueType: VALUE_TYPE.WORK, id }]

                                setAddedWork(listWithUpdatedValues)
                                setValue('addedWork', listWithUpdatedValues)
                                setValue('articleName', null)
                                setValue('articlePrice', null)
                                setValue('articleAmount', null)

                                if (rerender) {
                                    rerender({})
                                }

                                // Reset form
                                clearErrors()
                                setArticleFormValues(initialArticleFormState)
                                setControlledReset(prev => prev + 1)
                                setWorkError(null)
                                if (setUnfinishedWorkArticleError) {
                                    setUnfinishedWorkArticleError(false)
                                }
                            }} />
                    </HStack>
                </Box>
            )}


            <Stack spacing={2} pt={2}>
                {addedWork.map((work: ArticleDetails) => {
                    const unitPerWork = units?.find(unit => unit.key === work.articleUnit)
                    const valueType = work.valueType
                    return (
                        <HStack key={work.id} align={'center'} spacing={2} data-testid={`added-cost-stack-${work.articleName}`}>
                            <InputGroup size='md'>
                                <Input
                                    type='text'
                                    fontSize={[11, 14]}
                                    placeholder={valueType === 'spareParts' ? 'Namn på reservdel / material (styckpris)' : 'T.ex. Felsökning / Däckbyte / Däckskifte osv'}
                                    readOnly
                                    value={work.articleName}
                                    onChange={e => handleInputChange(e.target.value, 'articleName')}
                                />
                                {valueType === VALUE_TYPE.WORK && (
                                    <InputRightElement width={['8.5rem', '11.4rem']}>
                                        <Box bg={'gray.100'} p={1} pr={2} pl={2} borderRadius={16}>
                                            <Text fontSize={[8, 12]}>Ingår i arbetskostnaden</Text>
                                        </Box>
                                    </InputRightElement>
                                )}
                            </InputGroup>

                            {valueType === VALUE_TYPE.SPARE_PARTS && (
                                <>
                                    <InputGroup size='md' w={['66%', '40%']}>
                                        <Input
                                            type='text'
                                            min={0}
                                            fontSize={[11, 14]}
                                            readOnly
                                            placeholder='Pris'
                                            value={work.articlePrice}
                                        />
                                        <InputRightElement width='2.5rem'>
                                            <Text fontSize={[11, 14]}>kr</Text>
                                        </InputRightElement>
                                    </InputGroup>

                                    <InputGroup size='md' w={['52%', '40%']}>
                                        <Input
                                            type='number'
                                            step={.01}
                                            min={1}
                                            readOnly
                                            fontSize={[11, 14]}
                                            placeholder='Ant.'
                                            value={work.articleAmount}
                                        />
                                        <InputRightElement width='2.5rem'>
                                            <Text fontSize={[11, 14]}>{unitPerWork?.abbreviation}</Text>
                                        </InputRightElement>
                                    </InputGroup>
                                </>
                            )}
                            <Center onClick={() => handleRemoveWorkRow(work.id)} data-testid={`delete-added-cost-${work.articleName}`} cursor={'pointer'} p={[3, 4]} _hover={{ bg: 'red.200' }} bg={'red.100'} w={4} h={4} borderRadius='full'>
                                <Icon as={FaTrashAlt} fontSize={[10, 14]} />
                            </Center>
                        </HStack>
                    )
                })}
            </Stack>

            {!addMaterial && !isOffer && (
                <HStack cursor={'pointer'} color='blue.600' _hover={{ color: 'blue.400' }} w='max-content' spacing={1} pt={4} pb={2} onClick={() => {
                    // No validation needed when offer
                    if (isOffer) {
                        return setAddMaterial(true)
                    }

                    // At least one work item needs to be added before materials can be added
                    const allowedToAddMaterial = addedWork.some(work => work.valueType === 'work');
                    const isFieldDirty = articleFormValues.valueType === 'work' && articleFormValues.articleName?.length > 0

                    if (!allowedToAddMaterial && !isFieldDirty) {
                        return setWorkError('Välj ett arbete innan du lägger till reservdelar')
                    }

                    if (!allowedToAddMaterial && isFieldDirty) {
                        return setWorkError('Tryck på plus-knappen till höger för att lägga till arbetet')
                    }
                    setAddMaterial(true)
                }}>
                    <Heading fontSize={14} color='blue.600' _hover={{ color: 'blue.400' }} data-testid='add-spare-parts-or-material'>Lägg till reservdelar/material</Heading>
                    <Icon as={FaPlus} fontSize={14} />
                </HStack>
            )}

            {(addMaterial || isOffer) && (
                <Box h={16}>
                    <HStack align={'center'} spacing={[2, 2]} pt={2}>
                        <WorkArticleSelect resetArticleSelect={resetArticleSelect} setResetArticleSelect={setResetArticleSelect} placeholder={unitValue === SELECTED_UNIT.METER ? 'Tex Plåt / Bromsslang osv (Meterpris)' : unitValue === SELECTED_UNIT.UNIT ? 'Reservdel / material (Styckpris)' : 'Tex Motorolja (Literpris)'} resetKey={controlledReset} handleInputChange={val => handleInputChange(val, 'articleName')} />
                        <>
                            <InputGroup size='md' w={['66%', '40%']}>
                                <Input
                                    type='text'
                                    inputMode="numeric"
                                    min={0}
                                    fontSize={[11, 14]}
                                    placeholder='Pris'
                                    value={articleFormValues.articlePrice}
                                    data-testid="article-price-field"
                                    onChange={e => {
                                        const numbersOnlyRegex = /^[0-9\b.,]+$/;
                                        let inputValue = e.target.value;
                                        // remove leading zeros if presnet, 0900 -> 900
                                        inputValue = inputValue.replace(/^0+(?!$)/, '')
                                        // if value is not blank, then test the regex
                                        if (inputValue === '' || numbersOnlyRegex.test(inputValue)) {
                                            handleInputChange(inputValue?.replace('.', ','), 'articlePrice')
                                        }
                                    }}
                                />
                                <InputRightElement width='2.0rem' p={0}>
                                    <Text fontSize={[11, 14]}>kr</Text>
                                </InputRightElement>
                            </InputGroup>

                            <InputGroup size='md' w={['52%', '40%']}>
                                <Input
                                    type='number'
                                    fontSize={[11, 14]}
                                    min={0}
                                    value={articleFormValues.articleAmount}
                                    data-testid="article-unit-field"
                                    onChange={e => {
                                        let inputValue = e.target.value;

                                        if (articleFormValues.articleUnit === 'unit') {
                                            const wholeNumbersOnly = /^[1-9]\d*$/;
                                            if (inputValue === '' || wholeNumbersOnly.test(inputValue)) {
                                                setArticleError(null)
                                                return handleInputChange(inputValue, 'articleAmount')
                                            }
                                            setArticleError('Styckmåttet måste vara ett heltal, välj måttet "Per liter" eller "Per meter" för att använda decimaltal')
                                            return;
                                        } else {
                                            const decimalRegex = /^[0-9\b.,]+$/;
                                            // remove leading zeros if presnet, 0900 -> 900
                                            inputValue = inputValue.replace(/^0+(?!$)/, '')
                                            // if value is not blank, then test the regex
                                            if (inputValue === '' || decimalRegex.test(inputValue)) {
                                                handleInputChange(inputValue, 'articleAmount')
                                            }
                                        }
                                    }}
                                />
                                <InputRightElement width={['2.6rem', '3.0rem']} h={'2.5rem'}>
                                    <HStack spacing={0} p={0} m={0} align={'center'}>
                                        <Select display={'flex'} iconSize={'16px'} left={isMobile ? 2 : 0} zIndex={2} variant='unstyled' cursor={'pointer'} fontSize={[11, 14]} value={articleFormValues.articleUnit} onChange={(e) => {
                                            const value = e.target.value;
                                            handleInputChange(value, 'articleUnit')
                                            setUnitValue(value)
                                        }} m={0} p={0}>
                                            {units.map(unit => {
                                                const isSelected = unit.key === articleFormValues.articleUnit;
                                                return (
                                                    <option key={unit.key} value={unit.key}>
                                                        {isSelected ? unit.abbreviation : `Per ${unit.label}`}
                                                    </option>
                                                )
                                            })}
                                        </Select>
                                    </HStack>
                                </InputRightElement>
                            </InputGroup>
                        </>

                        <Box pos={'relative'}>
                            {isValidArticleInputValues() && articleRowRef?.current && (
                                <ChakraIcon
                                    pos={'absolute'}
                                    right={-1}
                                    top={-2}
                                    zIndex={999}
                                    animate={{
                                        scale: [1, 1.2, 1, 1.2, 1],
                                    }}
                                    transition={{
                                        duration: 3,
                                        // @ts-ignore
                                        repeat: Infinity,
                                        repeatType: "loop",
                                    }}
                                >
                                    <Center bg={'teal.100'} borderRadius={'full'} p={1}>
                                        <Icon as={FaBolt} color={'teal.700'} fontSize={8} _hover={{ color: 'teal.800' }} />
                                    </Center>
                                </ChakraIcon>
                            )}
                            <IconButton
                                ref={hasDirtyArticleFields ? articleRowRef : null}
                                colorScheme='blue'
                                aria-label='Add article'
                                data-testid='add-article-icon-button'
                                size={isMobile ? 'xs' : 'sm'}
                                icon={<FaPlus size={isMobile ? 10 : 16} />}
                                onClick={() => {
                                    if (!isValidArticleInputValues()) {
                                        return setArticleError('Fyll i fälten för att lägga till bilreservdelar i offerten')
                                    }

                                    const id = addedWork.length + 1;
                                    const listWithUpdatedValues = [...addedWork, { ...articleFormValues, valueType: VALUE_TYPE.SPARE_PARTS, id }]

                                    setAddedWork(listWithUpdatedValues)
                                    setValue('addedWork', listWithUpdatedValues)
                                    setValue('articleName', null)
                                    setValue('articlePrice', null)
                                    setValue('articleAmount', null)

                                    if (articleRowRef?.current) {
                                        articleRowRef.current.blur();  // Remove focus from the button
                                    }

                                    if (rerender) {
                                        rerender({})
                                    }

                                    // Reset form
                                    clearErrors();
                                    if (setArticleFieldsIsDirty) {
                                        setArticleFieldsIsDirty(false)
                                    }

                                    setArticleFormValues(initialArticleFormState)
                                    setResetArticleSelect(true);
                                    setUnitValue(SELECTED_UNIT.UNIT)
                                    setArticleError(null)
                                    if (setUnfinishedWorkArticleError) {
                                        setUnfinishedWorkArticleError(false)
                                    }
                                }} />
                        </Box>
                    </HStack>
                    {articleError && (
                        <Text fontSize={10} color={'red.500'}>{articleError}</Text>
                    )}

                    {unFinishedWorkArticleError && (
                        <Text fontSize={10} color={'red.500'}>{'Vänligen lägg till artikeln som du påbörjat fylla i eller rensa fälten'}</Text>
                    )}
                </Box>
            )}

            {source === 'offer' && (
                <OfferEnquiryPopover selectedOfferEnquiry={selectedOfferEnquiry} />
            )}

            <Box pos={'relative'}>
                <HStack pt={4}>
                    <Box w={'70%'}>
                        <Text fontSize={12}>Arbetskostnad i timmen</Text>
                        <Input
                            type='text'
                            inputMode="numeric"
                            data-testid='work-cost-per-hour-input'
                            value={laborFormValues.laborCost || hourlyWorkRateSetting}
                            onChange={e => {
                                const numbersOnlyRegex = /^[0-9\b]+$/;
                                let inputValue = e.target.value;

                                // if value is not blank, then test the regex
                                if (inputValue === '') {
                                    handleInputChangeLaborForm('0', 'laborCost')
                                } else if (numbersOnlyRegex.test(inputValue)) {
                                    // remove leading zeros if presnet, 0900 -> 900
                                    inputValue = inputValue.replace(/^0+(?!$)/, '')

                                    handleInputChangeLaborForm(inputValue, 'laborCost')
                                }
                            }}
                        />
                    </Box>
                    <Box w={'30%'}>
                        <Text fontSize={12}>Antal timmar</Text>
                        <Input
                            type='number'
                            onFocus={(e) => e.target.addEventListener("wheel", (event) => event.preventDefault())}
                            onBlur={(e) => e.target.removeEventListener("wheel", (event) => event.preventDefault())}
                            inputMode="numeric"
                            step={.01}
                            min={0}
                            data-testid='number-of-hours-input'
                            value={laborFormValues.laborHours}
                            onChange={e => {
                                let inputValue = e.target.value;
                                // remove leading zeros if presnet, 0900 -> 900
                                inputValue = inputValue.replace(/^0+(?!$)/, '')

                                handleInputChangeLaborForm(inputValue, 'laborHours')
                            }}
                        />
                    </Box>
                </HStack>
                <AnimatePresence>
                    {laborFormValues.isDirtyLaborCost && (
                        <motion.div
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -20, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                        >
                            <Stack bg={'gray.100'}>
                                <Stack p={2}>
                                    <HStack spacing={4}>
                                        <Button isLoading={isLoadingSave} onClick={() => handleSaveHourlyRate()} rightIcon={<Icon as={FaBolt} size={4} />} size={'xs'} variant='secondary' borderRadius={16}>Kom ihåg kostnaden</Button>
                                        <Icon _hover={{ color: 'gray.600' }} as={FaUndo} cursor={'pointer'} onClick={() => setLaborFormValues({ ...laborFormValues, laborCost: hourlyWorkRateSetting, isDirtyLaborCost: false })} />
                                    </HStack>
                                    <HStack spacing={4}>
                                        <HStack>
                                            <Text fontSize={14}>Nuvarande tim-pris:</Text>
                                            <Heading size={'xs'}>{hourlyWorkRateSetting || defaultHourlyRate}kr</Heading>
                                        </HStack>
                                        <HStack>
                                            <Text fontSize={14}>Nytt tim-pris:</Text>
                                            <Heading size={'xs'}>{laborFormValues.laborCost}kr</Heading>
                                        </HStack>
                                        <CheckAnimation isChecked={isCompleteSave} h={20} w={20} />
                                    </HStack>
                                </Stack>
                            </Stack>
                        </motion.div>
                    )}
                </AnimatePresence>
            </Box>

            <Box pt={6}>
                <Stack spacing={2}>
                    <HStack justify={'space-between'} align='start'>
                        <Box>
                            <Text fontSize={14}>Materialkostnad</Text>
                            {addonArticleCostSetting && addonArticleCostSetting?.length > 0 ? (
                                <HStack _hover={{ color: 'blue.400' }} cursor={'pointer'} onClick={onOpenAddonCostModal}>
                                    <Text fontSize={11}>{`Varav påslag (${addonArticleCostSetting || 0}%)`}</Text>
                                    <Icon as={FaEdit} fontSize={14} color={'blue.600'} cursor={'pointer'} />
                                </HStack>
                            ) : (
                                <Text fontSize={11}>{`Varav påslag (${addonArticleCostSetting || 0}%)`}</Text>
                            )}
                        </Box>
                        <Stack align={'end'} spacing={0}>
                            <Text fontSize={14} data-testid='material-cost-text'>{addonSum ? formatMoney(sum + addonSum) : formatMoney(sum)}</Text>
                            {addonArticleCostSetting && addonArticleCostSetting?.length > 0 ? (
                                <Text fontSize={12}>{formatMoney(addonSum)}</Text>
                            ) : (
                                <HStack _hover={{ color: 'blue.400' }} cursor={'pointer'} onClick={onOpenAddonCostModal}>
                                    <Heading fontSize={12} color='blue.600' data-testid='add-spare-parts-or-material'>Lägg till påslag</Heading>
                                    <Icon as={FaPlus} fontSize={12} color='blue.600' />
                                </HStack>
                            )}
                        </Stack>
                    </HStack>
                    <HStack justify={'space-between'} align='start'>
                        <Text fontSize={14}>Arbetskostnad</Text>
                        <Stack align={'flex-end'}>
                            <Text fontSize={14} data-testid='labour-cost-text'>{formatMoney(getLaborCostSum(laborFormValues.laborCost, laborFormValues.laborHours))}</Text>
                            <Text fontSize={14}>{laborFormValues.laborHours} timmar</Text>
                        </Stack>
                    </HStack>
                    <Divider pt={2} />
                    <HStack pt={2} justify={'space-between'} align='start'>
                        <Heading size={'sm'}>Totalt</Heading>
                        <Heading size={'sm'} data-testid='total-cost-text'>{formatMoney(sumTotal)}</Heading>
                    </HStack>
                    <HStack justify={'space-between'} align='start'>
                        <Text fontSize={11}>{'Varav moms (25%)'}</Text>
                        <Text fontSize={12} data-testid='vat-cost-text'>{formatMoney(getVatCost(sumTotal))}</Text>
                    </HStack>
                </Stack>
            </Box>
        </Box>
    )
}

export default AddWorkDetailsForm