import { Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Heading } from '@chakra-ui/react';
import * as React from 'react';

interface CompanyInfoDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	btnRef: any;
}

const CompanyInfoDrawer: React.FC<CompanyInfoDrawerProps> = ({ isOpen, onClose, btnRef }) => {
	return (
		<>
			<Drawer
				isOpen={isOpen}
				placement='right'
				onClose={onClose}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>Frågor och svar</DrawerHeader>
					<DrawerBody>
						<Heading size="sm">Företagskortet</Heading>
						Här finns info kring företagskortet och hur allting funkar när man fyller i och sparar infon osv osv.
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	)
}

export default CompanyInfoDrawer;