import useSWRImmutable from "swr/immutable"
import { Endpoints } from "./endpoints"
import { fetcher } from "./utils"

export function useCompanyFeatures() {
    const { data, error } = useSWRImmutable(`${Endpoints.FEATURES}/workshop`, fetcher, { refreshInterval: 1000 * 60 * 60 }) // refresh every hour

    return {
        features: data?.map(feature => feature.id) as String[],
        isLoadingFeatures: !error && !data,
        isError: error,
    }
}
