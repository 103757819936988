import { Navigate } from 'react-router-dom';

const RequiredAuth = ({ children, isAuthProtected, route }) => {
	const auth = localStorage.getItem('token')

	if (!auth && isAuthProtected) return <Navigate to="/login" replace />;

	return children
}

export default RequiredAuth;