import { Box, Center, Flex, Icon, Stack, Image, useDisclosure, HStack, Tooltip, Text } from "@chakra-ui/react";
import React from "react";
import { FiLogOut } from 'react-icons/fi';
import CreateBooking from "../pages/CreateBooking";
import { getFirebaseImageUrl } from "../utils/firebase";
import EventBell from "./Events/Event";
import AssetsModal from "./modals/Assets";
import NavigationItem from "./NavigationItem";
import { Spinner } from "./Spinner";
import appIcon from '../assets/appicon.png';

export const navigations = [
    {
        label: 'Översikt',
        route: '/',
    },
    {
        label: 'Bokningar',
        route: '/bookings',
    },

    {
        label: 'Offertförfrågningar',
        route: '/marketplace',
    },
    {
        label: 'Mitt BilGo',
        route: '/my-company',
    },
]

interface SidebarProps {
    companyInfoEntity: any;
}

/**
 * BaseLayout is the page that will wrap all the common pages in the application
 */
const TopMenu: React.FC<SidebarProps> = ({ companyInfoEntity }) => {
    const { companyInfo = null, isLoading: isLoadingCompanyBaseInfo = false } = companyInfoEntity;
    const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure()

    if (isLoadingCompanyBaseInfo) {
        return <Spinner />
    }

    return (
        <>
            <Flex
                w="100vw"
                background="white"
                boxShadow="md"
            >
                <AssetsModal isOpenModal={isOpenModal} onCloseModal={onCloseModal} imageType={'companyLogo'} />
                <HStack spacing={8} justifyContent={'space-between'} w={'100%'} paddingLeft={4} paddingRight={8}>
                    <HStack spacing={12}>
                        <Stack>
                            <Box padding={4}>
                                <Tooltip
                                    hasArrow
                                    bg='teal.500'
                                    color='white'
                                    placement='top'
                                    label={'Ändra'}
                                >
                                    <HStack cursor="pointer" onClick={(e) => onOpenModal()}>
                                        <Image
                                            borderRadius="full"
                                            height={'40px'}
                                            width={'40px'}
                                            objectFit='cover'
                                            src={companyInfo?.companyLogo ? getFirebaseImageUrl(companyInfo?.companyLogo) : appIcon}
                                            alt='Företagsbild'
                                        />
                                    </HStack>
                                </Tooltip>
                            </Box>
                        </Stack>
                        <HStack spacing={8}>
                            {navigations.map((navItem, i) => (
                                <NavigationItem
                                    key={i}
                                    label={navItem.label}
                                    route={navItem.route}
                                />
                            ))}
                        </HStack>
                    </HStack>
                    <HStack spacing={12}>
                        <HStack spacing={4}>
                            <EventBell />
                            <CreateBooking />
                        </HStack>

                        <Tooltip
                            hasArrow
                            bg='teal.500'
                            color='white'
                            placement='bottom'
                            label={'Logga ut'}
                        >
                            <Center>
                                <a href="/login" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Icon as={FiLogOut} color={'teal.700'} fontSize={20} marginRight={2} />
                                </a>
                            </Center>
                        </Tooltip>
                        <Text right={4} bottom={2} pos={'absolute'} fontSize={9}>v{process.env.REACT_APP_VERSION}</Text>
                    </HStack>
                </HStack>
            </Flex>
        </>

    )
}

export default TopMenu