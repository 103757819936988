import useSWR from "swr"
import useSWRImmutable from "swr/immutable"
import { OfferEnquiry } from "../../components/Marketplace/types";
import { fetcher } from "./utils"

export interface UseOfferRequests {
    offerRequests: OfferEnquiry[];
    isError: boolean;
    isLoading: boolean;
    mutateOfferRequests: any;
    isValidating?: boolean;
}

export function useOfferRequests() {
    const { data, error, mutate, isValidating} = useSWR(`/api/offer-request/enquiry-v1`, fetcher)

    return {
        offerRequests: data,
        isLoading: !error && !data,
        isError: error,
        mutateOfferRequests: mutate,
        isValidating
    }
}

export function useHandledOfferRequests() {
    const { data, error, mutate } = useSWR(`/api/offer-request/enquiry-v1/handled`, fetcher)

    return {
        offerRequests: data,
        isLoading: !error && !data,
        isError: error,
        mutateOfferRequests: mutate
    }
}


export function useHandledOfferRequestsImmutable() {
    const { data, error, mutate } = useSWRImmutable(`/api/offer-request/enquiry-v1/handled`, fetcher)

    return {
        offerRequests: data,
        isLoading: !error && !data,
        isError: error,
        mutateHandledOfferRequests: mutate
    }
}