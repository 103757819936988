import { AddIcon, EditIcon } from '@chakra-ui/icons';
import { Box, Button, FormControl, FormErrorMessage, HStack, IconButton, Input, InputGroup, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Stack, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { SETTINGS, useCompanySettings } from '../../hooks/api/companySettings'
import { Endpoints } from '../../hooks/api/endpoints';
import { axiosPost } from '../../hooks/api/utils';
import { useCustomToast } from '../../hooks/useCustomToast';

interface HandInTimeIntervalSettingProps {
    onClose: () => void;
    onOpen: () => void;
    isOpen: boolean;
}

export function getFormattedTimeInterval(value: string) {
    const timeSplit = value?.split('-');
    if (!timeSplit) {
        return {
            fromTime: null,
            toTime: null
        }
    }
    return {
        fromTime: timeSplit[0]?.trim() || null,
        toTime: timeSplit[1]?.trim() || null
    }
}

const HandInTimeIntervalSetting: React.FC<HandInTimeIntervalSettingProps> = ({ onClose, onOpen, isOpen }) => {
    const { companySettings } = useCompanySettings();
    const [timeFrom, setTimeFrom] = useState<string | null>(null);
    const [timeTo, setTimeTo] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { mutateCompanySettings } = useCompanySettings();
    const handInInterval = companySettings?.find(setting => setting?.name === SETTINGS.HAND_IN_TIME_INTERVAL);

    const { fromTime: currentTimeFrom, toTime: currentTimeTo } = getFormattedTimeInterval(handInInterval?.value);
    const { successToast } = useCustomToast({ position: 'top-right' });

    async function handleSaveTime() {
        setIsLoading(true)
        try {
            const payload = {
                setting: SETTINGS.HAND_IN_TIME_INTERVAL,
                settingValue: `${timeFrom || currentTimeFrom} - ${timeTo || currentTimeTo}`
            }
            await axiosPost(`${Endpoints.COMPANY_SETTINGS_V1}/update`, payload)
            await mutateCompanySettings();
            setIsLoading(false)
            onClose();
            return successToast('Förinställd tid för inlämning ändrad')
        } catch (error) {
            setIsLoading(false)
            onClose()
        }
    }

    function renderForm() {
        return (
            <>
                <HStack align={'start'} spacing={4}>
                    <FormControl>
                        <InputGroup>
                            <Box>
                                <Text fontSize={10} as='em'>Från</Text>
                                <Input
                                    defaultValue={currentTimeFrom}
                                    type='time'
                                    placeholder='Inlämningstid Från'
                                    onChange={e => setTimeFrom(e.target.value)}
                                />
                            </Box>
                        </InputGroup>
                        <FormErrorMessage>

                        </FormErrorMessage>
                    </FormControl>
                    <FormControl >
                        <InputGroup>
                            <Box>
                                <Text fontSize={10} as='em'>Till</Text>
                                <Input
                                    defaultValue={currentTimeTo}
                                    type='time'
                                    placeholder='Inlämningstid Till'
                                    onChange={e => setTimeTo(e.target.value)}
                                />
                            </Box>
                        </InputGroup>
                        <FormErrorMessage>
                        </FormErrorMessage>
                    </FormControl>
                </HStack>
                <HStack justifyContent={'space-between'} pt={4}>
                    <Button isLoading={isLoading} size={'sm'} variant='primary' onClick={() => handleSaveTime()}>Spara</Button>
                </HStack>
            </>
        )
    }

    if (!handInInterval?.name) {
        return (
            <Popover
                placement='auto'
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
            >
                <PopoverTrigger>
                    <IconButton aria-label='edit' size='xs' colorScheme={'blue'} icon={<AddIcon />} />
                </PopoverTrigger>

                <PopoverContent p={5}>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody p={0} mt={2}>
                        <Stack spacing={4}>
                            <Stack>
                                <Text fontSize={12}>Ställ in en standardtid för inlämning av bil och spara för att inte behöva göra om det varje gång.</Text>
                                <Text fontSize={10} as='em'>Var noga med att ställa in en tid när ni har personal på verkstaden så kunden har någon att lämna bilen till.</Text>
                            </Stack>
                            {renderForm()}
                        </Stack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        )
    }

    return (
        <Popover
            placement='auto'
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
        >
            <PopoverTrigger>
                <IconButton aria-label='edit' size='xs' colorScheme={'blue'} icon={<EditIcon />} />
            </PopoverTrigger>

            <PopoverContent p={5}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody p={0} mt={2}>
                    <Stack spacing={4}>
                        <Stack>
                            <Text fontSize={12}>Ändra det förinställda tidsintervallet för inlämning av bilar.</Text>
                            <Text fontSize={10} as='em'>Var noga med att välja ett tidsintervall där ni alltid har personal på plats som kan ta emot bilar som ska lämnas in.</Text>
                        </Stack>
                        {renderForm()}
                    </Stack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

export default HandInTimeIntervalSetting;