import { Box } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface CardProps {
    containerStyles?: React.CSSProperties; 
    contentStyles?: React.CSSProperties;
    padding?: 'sm' | 'md' | 'lg'
    children: ReactNode;
}

const Card: React.FC<CardProps> = ({ children, contentStyles, containerStyles, padding = 'sm' }) => {

    const sizeMapping = {
        sm: {
            padding: 4,
        },
        md: {
            padding: 6,
        },
        lg: {
            padding: 8,
        }
    }

    const contentPadding = sizeMapping[padding].padding;

    return (
        <Box bg={'white'} boxShadow={'base'} borderRadius={'2xl'} style={containerStyles}>
            <Box padding={contentPadding} style={contentStyles}>
                {children}
            </Box>
        </Box>
    )
}

export default Card;