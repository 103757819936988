import React, { Fragment, useEffect, useState } from "react";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Badge,
    useMediaQuery,
    Text,
    HStack,
    Divider,
    Stack,
    Heading,
    SkeletonText,
    Tooltip,
    Center,
} from '@chakra-ui/react'
import moment from "moment";
import 'moment/locale/sv'
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getSourceType, getWorkStatusColor } from "../../common/utils";
import LicensePlate from "../LicensePlate";
import { Icon, InfoIcon } from "@chakra-ui/icons";
import ReactPaginate from 'react-paginate';
import styles from './Pagination.module.scss';
import { SearchBox } from "./SearchBox";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import isEmpty from 'lodash/isEmpty';
import noDataImage from '../../common/undraw_no_data.svg';
import { useBookings } from "../../hooks/api/booking";
import FilterSection from "./FilterSection";
import { getCategories } from "./WorkCategoryUtils";

const BOOKING_STATUS = {
    ALL: 'all',
    ONGOING: 'ongoing',
    WAITING: 'waiting',
    FINISHED: 'finished',
    CANCELED: 'canceled',
};


const BookingsTable: React.FC = () => {
    const navigate = useNavigate();

    const [isLargerThan1128] = useMediaQuery('(min-width: 1128px)');
    const [page, setPage] = useState(0);
    const [status, setStatus] = useState(BOOKING_STATUS.ALL);
    const [regNoParam, setRegNoParam] = useState('');
    const [searchParams] = useSearchParams();
    const highlightedBookingId = searchParams.get('highlight');
    const [selectedDate, setSelectedDate] = useState('');
    const { state } = useLocation();
    const [, rerender] = useState({});

    const query = !isEmpty(regNoParam) && `?regNo=${regNoParam}` || `?status=${status}&page=${page}&date=${selectedDate}`
    const { bookings, isLoading: isLoadingBookings, mutateBooking } = useBookings(query);

    useEffect(() => {
        (async () => {
            if (state?.forceRerender) {
                await mutateBooking();
                rerender({});
            }
        })()
    }, [state, mutateBooking])

    const handlePageClick = (event) => setPage(event.selected);

    function getPageCount() {
        if (page === 0 && bookings?.length !== 15) {
            return 1
        }
        return 20
    }

    const renderBookings = () => {
        return bookings?.map((booking, i) => {
            const pickupDate = booking.updatedAcceptedDate && moment(booking.updatedAcceptedDate).format('YYYY-MM-DD') || booking.pickupDate;
            const isHighlightedBooking = highlightedBookingId === booking.bookingId;

            return (
                <Tr
                    _hover={{ bg: 'gray.50' }}
                    borderLeft={isHighlightedBooking ? '4px solid' : 'none'}
                    borderColor={'green.500'}
                    bg={isHighlightedBooking ? 'gray.100' : 'none'}
                    margin={24}
                    cursor="pointer"
                    onClick={() => navigate(`/bookings/${booking.bookingId}`, {
                        state: booking
                    })}
                    key={i}
                >
                    <Td><LicensePlate regNo={booking.regNo} size='xs' /></Td>
                    <Td fontSize={14}>{moment(booking.acceptedDate).locale('sv').format('LL')}</Td>
                    <Td fontSize={14}>{moment(pickupDate).locale('sv').format('LL')}</Td>
                    <Td fontSize={14}>
                        <Stack spacing={2}>
                            {(booking.status.key === 'ongoing' ||
                                booking.status.key === 'waiting' &&
                                moment(booking.acceptedDate).isSameOrBefore(new Date())) ? (
                                <Box>
                                    <Badge colorScheme={'messenger'}>Pågående</Badge>
                                </Box>
                            ) : (
                                <Box>
                                    <Badge colorScheme={getWorkStatusColor(booking.status.key)}>{booking.status.label}</Badge>
                                </Box>
                            )}
                        </Stack>
                    </Td>
                    <Td fontSize={14}>{moment.utc(booking.createdAt).locale('sv').format('LL, HH:mm')}</Td>
                    <Td fontSize={14}><Badge colorScheme={getSourceType(booking.bookingSource).badgeColor}>{getSourceType(booking.bookingSource).label}</Badge></Td>
                </Tr>
            )
        })

    }

    const renderPagination = () => {
        return (
            <ReactPaginate
                className={styles.paginationContainer}
                activeClassName={styles.activePage}
                pageClassName={styles.page}
                breakLabel="..."
                nextLabel={<Icon _hover={{ color: 'teal.600' }} fontSize={'xl'} as={FaChevronRight} color='teal.700' />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={getPageCount()}
                previousLabel={<Icon _hover={{ color: 'teal.600' }} fontSize={'xl'} as={FaChevronLeft} color='teal.700' />}
                renderOnZeroPageCount={null}
            />
        )
    }

    if (isLargerThan1128) {
        return (
            <Box>
                <HStack justifyContent={'space-between'} p={4} bg={'white'} borderRadius={12} border={'1px solid'} borderColor={'gray.300'}>
                    <FilterSection setStatus={setStatus} status={status} setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
                    <SearchBox setRegNoParam={setRegNoParam} />
                    {renderPagination()}

                </HStack>
                <Box mt={4} boxShadow="base" height="auto" rounded="2xl" background="white">
                    <Table variant='simple' size={'md'}>
                        <Thead paddingTop="10">
                            <Tr>
                                <Th>Reg-nummer</Th>
                                <Th>Inlämning</Th>
                                <Th>Upphämtning</Th>
                                <Th>Status på arbete</Th>
                                <Th>Bokning skapad</Th>
                                <Th>
                                    <HStack spacing={2}>
                                        <p>Bokat via</p>
                                        <Tooltip placement="top" label={'Specificerar källan till bokningen, antingen via mobilappen eller via plattformen.'} hasArrow bg="teal.700">
                                            <InfoIcon />
                                        </Tooltip>
                                    </HStack>
                                </Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {isLoadingBookings ? (
                                <Tr>
                                    <Td>
                                        <SkeletonText mt='6' noOfLines={24} spacing='6' skeletonHeight={'8'} />
                                    </Td>
                                    <Td>
                                        <SkeletonText mt='6' noOfLines={24} spacing='6' skeletonHeight={'8'} />
                                    </Td>
                                    <Td>
                                        <SkeletonText mt='6' noOfLines={24} spacing='6' skeletonHeight={'8'} />
                                    </Td>
                                    <Td>
                                        <SkeletonText mt='6' noOfLines={24} spacing='6' skeletonHeight={'8'} />
                                    </Td>
                                    <Td>
                                        <SkeletonText mt='6' noOfLines={24} spacing='6' skeletonHeight={'8'} />
                                    </Td>
                                    <Td>
                                        <SkeletonText mt='6' noOfLines={24} spacing='6' skeletonHeight={'8'} />
                                    </Td>
                                </Tr>
                            ) : renderBookings()}
                        </Tbody>
                    </Table>
                </Box>
                <Center pt={12}>
                    {bookings?.length === 0 && (
                        <Stack spacing={4} alignItems={'center'}>
                            <Heading size={'sm'}>Här var det tomt!</Heading>
                            <img src={noDataImage} width={180} alt="bild som visas när data saknas" />
                        </Stack>
                    )}
                </Center>
            </Box >
        )
    }

    return (
        <Box padding={2}>
            <Stack justify={'space-between'} pr={4} spacing={4}>
                <SearchBox width={'100%'} inputWidth={'100%'} setRegNoParam={setRegNoParam} />
                <FilterSection setStatus={setStatus} status={status} setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
            </Stack>
            <Box mt={4} boxShadow="base" height="auto" rounded="md" background="white">
                <Box>
                    <Stack spacing={4}>
                        <HStack justifyContent={'center'} pt={4}>
                            {renderPagination()}
                        </HStack>
                        <HStack padding={6} justifyContent={'space-between'}>
                            <Heading size={'sm'}>Regnr</Heading>
                            <Heading size={'sm'}>Status</Heading>
                        </HStack>
                    </Stack>

                    <Box>
                        {isLoadingBookings ? (
                            <>
                                <SkeletonText mt='6' noOfLines={6} spacing='6' skeletonHeight={'24'} />
                                <Divider />
                            </>
                        ) : (
                            <>
                                {bookings?.map((booking, i) => {
                                    const pickupDate = booking.updatedAcceptedDate || booking.pickupDate;
                                    return (
                                        <Fragment key={i}>
                                            <Divider />
                                            <Stack padding={6} spacing={4}
                                                cursor="pointer"
                                                onClick={() => navigate(`/bookings/${booking.bookingId}`, {
                                                    state: booking
                                                })}>
                                                <HStack>
                                                    <Text fontSize={11} opacity={.7}>Bokning skapad</Text>
                                                    <Text fontSize={11}>{moment(booking.createdAt).locale('sv').format('LL, HH:mm')}</Text>
                                                </HStack>
                                                <HStack justifyContent={'space-between'}>
                                                    <Text>{booking.regNo}</Text>
                                                    <Text>{getCategories(booking)}</Text>
                                                    {(booking.status.key === 'ongoing' ||
                                                        booking.status.key === 'waiting' &&
                                                        moment(booking.acceptedDate).isSameOrBefore(new Date())) ? (
                                                        <Box>
                                                            <Badge colorScheme={'messenger'}>Pågående</Badge>
                                                        </Box>
                                                    ) : (
                                                        <Box>
                                                            <Badge colorScheme={getWorkStatusColor(booking.status.key)}>{booking.status.label}</Badge>
                                                        </Box>
                                                    )}
                                                </HStack>

                                                <HStack justifyContent={'space-between'}>
                                                    <Stack>
                                                        <Text fontSize={11} opacity={.7}>Inlämningsdatum</Text>
                                                        <Text fontSize={11}>{moment(booking.acceptedDate).locale('sv').format('LL')} mellan {booking.acceptedTime}</Text>
                                                    </Stack>
                                                    <Stack>
                                                        <Text fontSize={11} opacity={.7}>Upphämtning</Text>
                                                        <Text fontSize={11}>{moment(pickupDate).locale('sv').format('LL')}</Text>
                                                    </Stack>
                                                </HStack>
                                            </Stack>
                                            <Divider />
                                        </Fragment>
                                    )
                                })}
                                {bookings?.length === 0 && (
                                    <Center height={64}>
                                        <Stack spacing={4} alignItems={'center'}>
                                            <Heading size={'sm'}>Här var det tomt!</Heading>
                                            <img src={noDataImage} width={120} alt="bild som visas när data saknas" />
                                        </Stack>
                                    </Center>
                                )}
                            </>
                        )}

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default BookingsTable;