import { InfoIcon } from "@chakra-ui/icons";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, ModalHeader, Heading, Text, Box, HStack, Stack, Divider, Badge, Icon, Tooltip, useMediaQuery } from "@chakra-ui/react";
import moment from "moment";
import React, { Fragment } from "react";
import { FaPaperclip } from "react-icons/fa";
import { getMessageStatus, getSeverityStatus } from "../../../common/utils";
import { AdditionalWork, Booking, File, Message } from "../../../hooks/api/booking";
import { getFirebaseImageUrl } from "../../../utils/firebase";
import LicensePlate from "../../LicensePlate";
import { Video } from "../../Video";
import AdditionalWorkSummaryItem from "./AdditionalWorkSummaryItem";
interface AddOfferConfirmationProps {
    isOpenModal: boolean;
    onCloseModal: () => void;
    booking: React.MutableRefObject<Booking>;
}
// FOr sound - use sound like this pomodoro shiet http://www.tomatotimers.com/ so workshop can hear when user answers
const AdditionalWorkSummaryModal: React.FC<AddOfferConfirmationProps> = ({ isOpenModal, onCloseModal, booking }) => {
    const messages: Message[] = booking.current?.messages || [];
    const [isMobile] = useMediaQuery('(max-width: 492px)')

    return (
        <>
            <Modal isOpen={isOpenModal} onClose={() => {
                onCloseModal()
            }} size={isMobile ? 'full' : '2xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader >
                        <HStack justifyContent={'space-between'}>
                            <Heading size={isMobile ? 'xs' : 'sm'}>Tilläggsarbete summering</Heading>
                            <Box pr={12}>
                                {isMobile ? (
                                    <Heading size={'sm'}>{booking.current?.regNo}</Heading>
                                ) : (
                                    <LicensePlate regNo={booking.current?.regNo} size='xs' />
                                )}
                            </Box>
                        </HStack>
                    </ModalHeader>
                    <ModalCloseButton size={'lg'} />
                    <ModalBody padding={isMobile ? 3 : 6}>
                        <Stack spacing={4}>
                            {messages?.map((message, i) => {
                                return (
                                    <Box key={i} pb={4} >
                                        <Box bg={'gray.50'} borderRadius={'lg'}>
                                            <Stack spacing={4} padding={4}>
                                                <HStack justifyContent={'space-between'} alignItems='center' mb={2}>
                                                    <Text fontSize={'12px'}>{`Skickat ${moment(message.createdAt).format('LLLL')}`}</Text>
                                                </HStack>
                                                <AdditionalWorkSummaryItem files={message.files} additionalWorkOffers={message.additionalWorkOffers} />
                                                {message.handledAt && (
                                                    <Text fontSize={'12px'}>{`${message.handledAt ? 'Läst ' + moment(message.handledAt).format('LLLL') : 'Inte läst än'}`}</Text>
                                                )}
                                            </Stack>
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AdditionalWorkSummaryModal;
