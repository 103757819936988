import React, { useEffect, useRef } from 'react';

interface VideoProps {
    selectedVideo: string | undefined;
    w?: string | number;
    h?: string | number;
}

export const Video: React.FC<VideoProps> = ({ selectedVideo, w, h }) => {
    const videoRef = useRef<any>();
    const previousUrl = useRef(selectedVideo);

    useEffect(() => {
        if (previousUrl.current === selectedVideo) {
            return;
        }

        if (videoRef.current) {
            videoRef.current.load();
        }

        previousUrl.current = selectedVideo;
    }, [selectedVideo]);

    return (
        <video style={{ width: w || 400, height: h || 200 }} controls ref={videoRef}>
            <source src={selectedVideo}
                type="video/mp4" />
            Din browser stödjer inte embeddade videos..
        </video>
    )
}