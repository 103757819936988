import { Box, Button, Center, Flex, Heading, Icon, Stack, Image, Text, useDisclosure, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, HStack } from "@chakra-ui/react";
import React from "react";
import { FaEdit } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import AssetsModal from "./modals/Assets";
import NavigationItem from "./NavigationItem";
import { HamburgerIcon } from '@chakra-ui/icons'
import { Spinner } from "./Spinner";
import { navigations } from "./TopMenu";
import { getFirebaseImageUrl } from "../utils/firebase";
import CreateBooking from "../pages/CreateBooking";
import EventBell from "./Events/Event";
import appIcon from '../assets/appicon.png';

interface SidebarProps {
    companyInfoEntity: any;
}

/**
 * BaseLayout is the page that will wrap all the common pages in the application
 */
const MobileMenu: React.FC<SidebarProps> = ({ companyInfoEntity }) => {
    const { companyInfo = null, isLoading: isLoadingCompanyBaseInfo = false } = companyInfoEntity;

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure()

    return (
        <>
            <AssetsModal isOpenModal={isOpenModal} onCloseModal={onCloseModal} imageType={'companyLogo'} />

            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerBody width={'100%'}>
                        <Stack spacing={6} marginTop={12}>
                            <Center>
                                <Heading size={'sm'} color="teal.500">{companyInfo?.companyName}</Heading>
                            </Center>
                            <Center>
                                {isLoadingCompanyBaseInfo ? (
                                    <Spinner />
                                ) : (

                                    <Box>
                                        <Flex cursor="pointer" onClick={(e) => onOpenModal()} display="inline-flex" marginBottom={2}>
                                            <Icon as={FaEdit} color="teal" fontSize="18px" />
                                            <Text color="teal.500" fontSize="sm" >Ändra</Text>
                                        </Flex>
                                        <Image
                                            borderRadius="full"
                                            height={'74px'}
                                            width={'74px'}
                                            objectFit='cover'
                                            src={companyInfo?.companyLogo ? getFirebaseImageUrl(companyInfo?.companyLogo) : appIcon}
                                            alt='Företagsbild'
                                        />
                                    </Box>
                                )}
                            </Center>

                            <Divider />
                        </Stack>

                        <Stack spacing={4} marginTop="12">
                            {navigations.map((navItem, i) => (
                                <NavigationItem
                                    key={i}
                                    label={navItem.label}
                                    route={navItem.route}
                                />
                            ))}
                        </Stack>

                        <Center marginTop={'24'}>
                            <a href="/login" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Icon as={FiLogOut} color='teal.500' size={18} marginRight={2} />
                                <Button colorScheme='teal' variant='link'>
                                    Logga ut
                                </Button>
                            </a>
                        </Center>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
            <HStack padding={4} justifyContent={'space-between'} >
                <Flex justifyContent="flex-start">
                    <HamburgerIcon w={8} h={8} color={'GrayText'} onClick={() => onOpen()} />
                </Flex>
                <HStack spacing={0}>
                    <EventBell />
                    <CreateBooking />
                </HStack>
            </HStack>
        </>
    )
}

export default MobileMenu