import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme, defineStyleConfig, defineStyle } from '@chakra-ui/react'
import "@fontsource/montserrat"
import "@fontsource/montserrat/600.css"
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        fontFamily: 'Montserrat'
      },
    }),
  },
  components: {
    Button: {
      baseStyle: { _focus: { boxShadow: 'none' } },
      variants: {
        'primary': {
          bg: 'teal.700',
          color: 'white',
          _hover: {
            bg: 'teal.600'
          }
        },
        'secondary': {
          bg: 'blue.600',
          color: 'white',
          _hover: {
            bg: 'blue.700'
          }
        }
      }
    },
    IconButton: { baseStyle: { _focus: { boxShadow: 'none' } } },
    Accordion: { baseStyle: { _focus: { boxShadow: 'none' } } },
    Heading: defineStyleConfig({
      baseStyle: { color: 'captionText', fontFamily: 'Montserrat', fontWeight: '600' },
      variants: {
        'light': defineStyle({ color: 'blackAlpha.800' })
      }
    }),
  }
});

export default theme;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ChakraProvider>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
