import React, { createContext, useContext, useReducer } from 'react';

const CreateBookingContext = createContext({
    state: undefined,
    dispatch: undefined
});

const initialState = { editBookingLimit: false }


function reducer(state, action) {
    switch (action.type) {
        case 'open':
            return { editBookingLimit: true };
        case 'close':
            return { editBookingLimit: false };
        default:
            throw new Error();
    }
}

export const CreateBookingProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <CreateBookingContext.Provider
            value={{ state, dispatch }}
        >
            {children}
        </CreateBookingContext.Provider>
    );
};

export const useCreateBooking = () => useContext(CreateBookingContext);