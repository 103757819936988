import useSWRImmutable from 'swr/immutable'
import { CompanySettings } from '../types'
import { Endpoints } from './endpoints'
import { fetcher } from "./utils"

export const SETTINGS = {
    HAND_IN_TIME_INTERVAL: 'hand_in_time_interval',
    HOURLY_WORK_RATE: 'hourly_work_rate',
    ADDON_ARTICLE_PERCENTAGE_COST: 'addon_article_percentage_cost'
}

export function useCompanySettings(setting?: string) {
    const { data, error, mutate, isValidating } = useSWRImmutable<CompanySettings[], Error>(Endpoints.COMPANY_SETTINGS_V1, fetcher)
    
    const settingValue = data?.find(settingVal => settingVal?.name === setting)?.value;

    return {
        settingValue,
        companySettings: data,
        isValidating,
        isLoading: !error && !data,
        isError: error,
        mutateCompanySettings: mutate
    }
}