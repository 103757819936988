
import { Stack, Heading, Box, HStack, Divider, Text } from '@chakra-ui/react';
import React from 'react';
import { WorkArticleDetailsData } from './Marketplace/types';
import { formatMoney } from './modals/AddOffer/AddWorkDetailsForm';

const WorkArticleDetails: React.FC<{ data: WorkArticleDetailsData }> = ({ data }) => {
    const { laborCost, laborHours, laborTotalCost, workArticles, vat, priceTotalFormatted, isOfferByFormValues, addonArticlePercentageCost, totalAddonCost } = data

    if (!isOfferByFormValues) {
        return null;
    }
    return (
        <Stack spacing={4}>
            {workArticles?.map((article, i) => {
                const { articleAmount, articleTotalPrice, articleName, articleUnit } = article;
                return (
                    <Box key={i}>
                        <HStack justify={'space-between'}>
                            {article.isSparePart ? (
                                <>
                                    <HStack spacing={2}>
                                        <Text fontSize={14}>{articleAmount} {articleUnit?.label}</Text>
                                        <Text fontSize={14}>{articleName}</Text>
                                    </HStack>
                                    <Heading size={'xs'}>{articleTotalPrice}</Heading>
                                </>
                            ) : (
                                <>
                                    <HStack w={'100%'} spacing={2} justifyContent='space-between'>
                                        <Text fontSize={14}>{articleName}</Text>
                                        <Heading size={'xs'}>Ingår i arbetskostnaden</Heading>
                                    </HStack>
                                </>
                            )}
                        </HStack>
                        <Divider pt={4} />
                    </Box>
                )
            })}
            <HStack justify={'space-between'}>
                <Text fontSize={11}>{`Varav påslag (${addonArticlePercentageCost ?? 0}%)`}</Text>
                <Heading fontSize={12}>{formatMoney(totalAddonCost)}</Heading>
            </HStack>

            <HStack justify={'space-between'}>
                <Box>
                    <Text fontSize={14}>{'Arbetskostnad'}</Text>
                    <Text fontSize={12}>{laborHours} timmar á {laborCost} kr</Text>
                </Box>
                <Heading size={'xs'}>{laborTotalCost}</Heading>
            </HStack>

            <Stack>
                <HStack justify={'space-between'}>
                    <Heading size={'xs'}>{'Totalt pris'}</Heading>
                    <Heading size={'sm'}>{priceTotalFormatted}</Heading>
                </HStack>
                <HStack justify={'space-between'}>
                    <Text fontSize={11}>{'Varav moms (25%)'}</Text>
                    <Heading fontSize={12}>{vat}</Heading>
                </HStack>
            </Stack>
        </Stack>
    )
}

export default WorkArticleDetails;