import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, TableContainer, Table, TableCaption, Thead, Tr, Th, Tbody, Td, Heading, Badge, Icon, Text } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { FaFilePdf } from "react-icons/fa";
import { getWorkStatusColor } from "../../../common/utils";
import { Booking } from "../../../hooks/api/booking";
import { getFirebaseImageUrl } from "../../../utils/firebase";
interface BookingsSummaryTableProps {
    selectedDateBookings: Booking[];
    selectedDate?: string;
    isOpen: boolean;
    onClose: () => void;
}
// TODO: Fixa status då den numera bara visar 
export const BookingsSummaryTable: React.FC<BookingsSummaryTableProps> = ({ selectedDateBookings, selectedDate, isOpen, onClose }) => {

    function getActualStatus(booking: Booking) {
        if (booking.acceptedDate === moment(new Date()).format('YYYY-MM-DD') && booking.status.key !== 'finished') {
            return {
                colorScheme: 'green',
                label: 'Påbörjas idag'
            }
        }
        return {
            colorScheme: getWorkStatusColor(booking.status.key),
            label: booking.status.label
        }
    }

    function renderPriceCell(booking: Booking) {
        if (booking?.price) {
            return <Td>{booking.price + 'kr'}</Td>
        }

        if (booking?.offer?.price) {
            return <Td>{booking?.offer?.price + 'kr'}</Td>
        }

        return 'saknas'
    }

    return (
        <Modal isOpen={isOpen} onClose={() => {
            onClose()
        }} size={'6xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody padding={8} minHeight={200}>
                    {selectedDate && (
                        <Heading pb={6} size={'sm'}>{`Följande bilar lämnas in ${moment(selectedDate).format('LL')}`}</Heading>
                    )}
                    <TableContainer>
                        <Table variant='simple' size={'sm'}>
                            <TableCaption>Totalt {selectedDateBookings?.length} bokningar</TableCaption>
                            <Thead>
                                <Tr>
                                    <Th>Reg.nr</Th>
                                    <Th>Bokarens Namn</Th>
                                    <Th>Bokarens E-post</Th>
                                    <Th>Bokarens Tel.nr</Th>
                                    <Th>Status</Th>
                                    <Th>Pris</Th>
                                    <Th>Arbete</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {selectedDateBookings?.map((booking, i) => (
                                    <Tr key={i}>
                                        <Td>{booking.regNo}</Td>
                                        <Td>{booking.fullName || 'Saknas'}</Td>
                                        <Td>{booking.email}</Td>
                                        <Td>{booking.phoneNo || 'Saknas'}</Td>
                                        <Td><Badge fontSize={11} colorScheme={getActualStatus(booking).colorScheme}>{getActualStatus(booking).label}</Badge></Td>
                                        {renderPriceCell(booking)}
                                        <Td>
                                            {booking?.offer?.filePath || booking?.offerFileToken ? (
                                                <a style={{ fontSize: 14 }} target="_blank" rel="noreferrer" href={getFirebaseImageUrl(booking?.offer?.filePath || booking?.offerFileToken)}>
                                                    <Icon style={{ fontSize: 16 }} cursor={'pointer'} _hover={{ color: 'red.600' }} color="red.400" as={FaFilePdf} />.pdf
                                                </a>
                                            ) : (
                                                <Text fontSize={14}>saknas</Text>
                                            )}
                                        </Td>
                                        {/* För detaljer om det skulle vara aktuellt */}
                                        {/* <Td>{booking?.description?.length > 16 ? `${booking.description.slice(0, 16)}...` : booking.description}</Td> */}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}