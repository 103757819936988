import { Button, Stack, HStack, Text, Heading, Box } from "@chakra-ui/react";
import React from "react";
import Lottie from 'lottie-react';
import SuccessAnimation from '../../../Lotties/Animation - 1705355399319.json';

const OfferSendSuccess: React.FC<{ onCloseModal: () => void; }> = ({ onCloseModal }) => {

    return (
        <Stack spacing={4}>
            <HStack pt={8} justifyContent={'center'}>
                <Stack spacing={8} alignItems={'center'}>
                    <Heading size={'lg'} color='blackAlpha.800'>Offerten har nu skickats</Heading>
                    <Box w={260} h={260}>
                        <Lottie animationData={SuccessAnimation} loop={false}/>
                    </Box>
                    <Text>Den här förfrågan har nu flyttats till listan med hanterade offertförfrågningar</Text>
                </Stack>
            </HStack>
            <Stack spacing={8} pt={16} justify={'center'} width={'100%'} align={'center'}>
                <Box>
                    <Button
                        w={80}
                        colorScheme='teal'
                        variant='secondary'
                        onClick={onCloseModal}
                    >
                        Stäng
                    </Button>
                </Box>
            </Stack>
        </Stack>
    )
}

export default OfferSendSuccess;
