import useSWR from 'swr';
import { Event } from "../types"
import { Endpoints } from './endpoints';
import { fetcher } from "./utils"

export function useEvents() {// 1000 * 60 * 2 == 2 min
    const { data, error, mutate, isValidating } = useSWR<Event[] | null>(`${Endpoints.EVENTS_V1}/latest`, fetcher, { refreshInterval: 1000 * 60 * 2, refreshWhenHidden: true, refreshWhenOffline: true })

    return {
        events: data || null,
        isLoadingEvents: !error && !data,
        isError: error,
        isValidating,
        mutateEvents: mutate
    }
}
