

/**
 * Regex validation /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
 * @param email input email
 * @returns Validation message and boolean for valid or invalid
 */
export function validateEmail(email: string) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!email || !emailRegex.test(email)) {
        return {
            emailError: true,
            emailMessage: 'Felaktig mejladress, vänligen skriv in korrekt mejladress'
        }
    }
    return {
        emailError: false,
        emailMessage: ''
    }
}

/**
 * 
 * @param phoneNo input Phone number
 * @returns Validation message and boolean for valid or invalid
 */
export function validatePhoneNo(phoneNo: string) {
    if (phoneNo?.length < 6) {
        return {
            phoneNoError: true,
            companyPhoneNoMessage: 'Ogiltigt telefonnummer'
        }
    }

    return {
        phoneNoError: false,
        companyPhoneNoMessage: ''
    }
}

/**
 * @param password input password
 * @param confirmPassword Optional confirmation password
 * @returns Validation message and boolean for valid or invalid
 */
export function validatePassword(password: string, confirmPassword?: string | null | undefined) {
    if (!password) {
        return {
            pwError: true,
            pwMessage: 'Du måste skriva ett lösenord'
        }
    }
    if (password?.length < 6) {
        return {
            pwError: true,
            pwMessage: 'Lösenordet måste vara minst 6 tecken långt'
        }
    }
    if (confirmPassword !== undefined) {
        if (password !== confirmPassword) {
            return {
                pwError: true,
                pwMessage: 'Du har inte skrivit rätt lösenord på båda lösenordsfälten'
            }
        }
    }

    return {
        pwError: false,
        pwMessage: ''
    }
}


export function validateCompanyName(companyName: string) {
    if (!companyName) {
        return {
            companyNameError: true,
            companyNameMessage: 'Företagsnamnet måste fyllas i'
        }
    }
    if (companyName?.length < 3) {
        return {
            companyNameError: true,
            companyNameMessage: 'Företagsnamnet måste innehålla minst 3 tecken'
        }
    }
    return {
        companyNameError: false,
        companyNameMessage: ''
    }
}