import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, ModalHeader, useMediaQuery, Box, Stack, Text, Center, Input, InputGroup, InputRightElement, Button } from "@chakra-ui/react";
import React, { useState } from "react";
import { axiosPost } from "../../hooks/api/utils";
import { Endpoints } from "../../hooks/api/endpoints";
import { SETTINGS, useCompanySettings } from "../../hooks/api/companySettings";
import { useCustomToast } from "../../hooks/useCustomToast";

interface EditAddonArticlePercentageCostModalProps {
    isOpen: boolean;
    onClose: () => void;
    action: 'add' | 'edit';
    settingsValue: string;
}
const EditAddonArticlePercentageCostModal: React.FC<EditAddonArticlePercentageCostModalProps> = ({ isOpen, onClose, action, settingsValue }) => {
    const [value, setValue] = useState(null);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isMobile] = useMediaQuery('(max-width: 492px)')
    const { mutateCompanySettings } = useCompanySettings();
    const { successToast } = useCustomToast();

    const isAdd = action === 'add';

    async function onSave() {
        setIsLoadingSave(true)
        try {
            const payload = {
                setting: SETTINGS.ADDON_ARTICLE_PERCENTAGE_COST,
                settingValue: value || settingsValue
            }
            await axiosPost(`${Endpoints.COMPANY_SETTINGS_V1}/update`, payload)
            await mutateCompanySettings();
            setIsLoadingSave(false)
            onClose();
            return successToast(isAdd ? 'Påslag har lagts till' : 'Påslag har ändrats')
        } catch (error) {
            console.log(error);
            setIsLoadingSave(false)
        }
    }

    const renderModalBody = () => {
        return (
            <Box pb={4}>
                <Stack spacing={4}>
                    <Box>
                        <Text fontSize={12}>Material-påslag i procent</Text>
                        <InputGroup>
                            <Input
                                autoFocus
                                onFocus={(e) => e.target.addEventListener("wheel", (event) => event.preventDefault())}
                                onBlur={(e) => e.target.removeEventListener("wheel", (event) => event.preventDefault())}
                                type='number'
                                inputMode="numeric"
                                placeholder="Skriv in ditt påslag"
                                min={0}
                                max={100}
                                data-testid='addon-percentage-cost-input'
                                value={value ?? settingsValue}
                                onChange={e => setValue(e.target.value)}
                            />
                            <InputRightElement width={['2.6rem', '3.0rem']} h={'2.5rem'}>
                                <Center w={8} h={8} borderRadius={'full'} bg={'gray.100'}>
                                    <Text as={'b'}>%</Text>
                                </Center>
                            </InputRightElement>
                        </InputGroup>
                    </Box>
                    <Stack>
                        <Text fontSize={14}>När du sparat ett påslag så kommer du såklart alltid att kunna ändra det igen. Påslaget hjälper dig automatiskt beräkna priset och visas såklart inte för din kund.</Text>
                    </Stack>

                    <Button
                        mt={4}
                        w="140px"
                        variant='primary'
                        size="sm"
                        isLoading={isLoadingSave}
                        onClick={onSave}
                    >
                        Spara
                    </Button>
                </Stack>
            </Box >
        )
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={() => {
                onClose()
            }} size={isMobile ? 'full' : 'md'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontSize={16}>{isAdd ? 'Lägg till påslag' : 'Ändra påslag'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {renderModalBody()}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default EditAddonArticlePercentageCostModal;