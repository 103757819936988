import { Box, Grid, GridItem, useMediaQuery } from "@chakra-ui/react";
import React, { ReactNode, useEffect } from "react";
import MobileMenu from "../components/MobileMenu";
import TopMenu from "../components/TopMenu";
import { Spinner } from "../components/Spinner";
import { useCompanyInfo, useCompanyServices, useOpeningHours } from "../hooks/api/companyInfo";
import axios from "axios";
import { useBookings, useCurrentUnfinishedBookings } from "../hooks/api/booking";
import { useEvents } from "../hooks/api/events";
import { useHandledOfferRequests, useOfferRequests } from "../hooks/api/offerRequests";
import { useCompanySettings } from "../hooks/api/companySettings";
import { useAdditionalWorkStatistics, useBookingStatistics } from "../hooks/api/statistics";

/**
 * BaseLayout is the page that will wrap all the common pages in the application
 */
const BaseLayout: React.FC<{ fullWidth?: boolean, children: ReactNode, mb?: number, pl?: number | number[] | null }> = ({ children, fullWidth = false, mb = 4, pl = null }) => {
    const { companyInfo, isLoading: isLoadingCompanyBaseInfo, isError, mutateCompanyInfo } = useCompanyInfo();
    const { mutateBooking: mutateBookings } = useBookings();
    const { mutateBooking: mutateCurrentBookings } = useCurrentUnfinishedBookings();
    const { mutateEvents } = useEvents()
    const { mutateOfferRequests } = useOfferRequests();
    const { mutateOpeningHours } = useOpeningHours();
    const { mutateOfferRequests: mutateHandledOfferRequests } = useHandledOfferRequests();
    const { mutateCompanySettings } = useCompanySettings();
    const { mutateBookingStatistics } = useBookingStatistics();
    const { mutateAdditionalWorkStatistics } = useAdditionalWorkStatistics();
    const { mutateCompanyServices } = useCompanyServices();

    useCompanyServices();

    const companyInfoEntity = {
        companyInfo, isLoading: isLoadingCompanyBaseInfo, isError, mutateCompanyInfo
    }

    const [isLargerThan1128] = useMediaQuery('(min-width: 916px)', { fallback: true })

    useEffect(() => {
        refreshToken()

        let timer = setTimeout(() => {
            refreshToken()
        }, 60 * 15)

        // this will clear Timeout
        return () => {
            clearTimeout(timer);
        };

    }, [children, axios])

    function getToken() {
        return document.cookie
            .split('; ')
            .find((row) => row.startsWith('token='))
            ?.split('=')[1];
    }

    function getHost() {
        if (window.location.hostname === 'app.bilgo.se') {
            return 'AIzaSyB6kPch-QgLMto48Pu2sJawxQBxcI10jWQ'
        }
        return 'AIzaSyDcLADN0MLvytQwLIRingkKDgIbE8xpZOA'
    }

    function refreshToken() {
        const token = getToken();

        if (!token) {
            (async () => {
                return axios.post(`https://securetoken.googleapis.com/v1/token?key=${getHost()}`, {
                    grant_type: 'refresh_token',
                    refresh_token: localStorage.getItem('refreshToken'),
                })
                    .then((res) => {
                        document.cookie = `token=${res.data.id_token}; max-age=600;`
                        localStorage.setItem('token', res.data.id_token)
                        localStorage.setItem('refreshToken', res.data.refresh_token)
                        mutateCompanyInfo();
                        mutateBookings();
                        mutateCurrentBookings();
                        mutateEvents();
                        mutateOfferRequests();
                        mutateOpeningHours();
                        mutateHandledOfferRequests();
                        mutateCompanySettings();
                        mutateAdditionalWorkStatistics()
                        mutateBookingStatistics();
                        mutateCompanyServices();
                        return;
                    })
                    .catch(err => {
                        // Gör nått när fel kommer in, typ logga ut de eller nått
                    })
            })();
        }
    }

    if (isLoadingCompanyBaseInfo) {
        return <Spinner />
    }

    /**
     * Context som används i Menyerna, inte wrappar children mao för att minimera risken för omrendering och dess skada
     */
    return (
        <Grid
            background={'#fafcff'}
            templateColumns='repeat(12, 1fr)'
            height="100%"
            width='100%'
        >
            {!isLargerThan1128 && (
                <GridItem colSpan={12}>
                    <Box position={'fixed'} width={'full'} top={0} zIndex={99} bg={'white'} shadow={'lg'}>
                        <MobileMenu companyInfoEntity={companyInfoEntity} />
                    </Box>
                </GridItem>
            )}
            <GridItem mb={mb} colSpan={12} width={isLargerThan1128 ? 'full' : 'inherit'}>
                {isLargerThan1128 && (
                    <Box position={'fixed'} top={0} zIndex={99}>
                        <TopMenu companyInfoEntity={companyInfoEntity} />
                    </Box>
                )}

                <Box maxW={'1920px'} margin={'0 auto'} mb={4} mt={'84px'}>
                    <Box mt={4}>
                        <Box pl={pl || [0, 0, 7, 7]} pr={[0, 0, 7, 7]} pt={[0, 0, 7, 7]}>
                            {children}
                        </Box>
                    </Box>
                </Box>
            </GridItem>
        </Grid>
    )
}

export default BaseLayout;