import { Icon } from "@chakra-ui/icons";
import { Box, Button, Heading, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useDisclosure, useToast, Text } from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { FaEnvelope, FaLock } from "react-icons/fa";
import { LuRocket } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { fetcher } from "../hooks/api/utils";
import { validateEmail, validatePassword } from "./Validation";

const LoginForm: React.FC = () => {
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    let navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onClose, onOpen } = useDisclosure();

    const promptErrorToast = (message?: string | undefined) => {
        return toast({
            title: 'Inloggning misslyckades',
            description: message || "Felaktig epost eller lösenord",
            status: 'warning',
            duration: 4000,
            isClosable: true,
        })
    }

    function getHost() {
        if (window.location.hostname === 'app.bilgo.se') {
            return 'AIzaSyB6kPch-QgLMto48Pu2sJawxQBxcI10jWQ'
        }
        return 'AIzaSyDcLADN0MLvytQwLIRingkKDgIbE8xpZOA'
    }

    function removeTokens() {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
    }

    // TODO: Put this in backend
    const handleLogin = () => {
        const { emailError, emailMessage } = validateEmail(email);
        const { pwError, pwMessage } = validatePassword(password);

        if (emailError || pwError) {
            return promptErrorToast(emailMessage || pwMessage)
        }

        setIsLoading(true)
        return axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${getHost()}`,
            {
                email,
                password,
                returnSecureToken: true
            })
            .then(res => {
                return fetcher('/api/user/users-v1', res.data.idToken)
                    .then(userData => {
                        if (userData.user_type === 'workshop') {
                            document.cookie = `token=${res.data.idToken}; max-age=3300`
                            localStorage.setItem('token', res.data.idToken)
                            localStorage.setItem('refreshToken', res.data.refreshToken)
                            setIsLoading(false)
                            return navigate('/')
                        }
                        setIsLoading(false)
                        promptErrorToast();
                        removeTokens()
                        return navigate('/login')
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        removeTokens()
                        if (error?.response?.data?.message === 'not.processed.yet') {
                            return onOpen()
                        }
                        return promptErrorToast()
                    })
            })
            .catch((error) => {
                setIsLoading(false)
                removeTokens()
                return promptErrorToast()
            })
    }

    const handleOnChange = (e, name) => {
        if (name === 'email') {
            setEmail(e.target.value)
        }
        if (name === 'password') {
            setPassword(e.target.value)
        }
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Er verkstad är snart där!
                        <Icon as={LuRocket} color={'teal.700'} fontSize={20} ml={4} />
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text fontSize={14}>Ditt konto finns registrerat hos oss och vi kommer att kontakta dig inom ett par dagar för att bekräfta er verkstad innan vi hjälper er att komma igång med plattformen. Tack för tålamodet!</Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant={'primary'} colorScheme='teal' size={'md'} mr={3} onClick={onClose}>
                            Stäng
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Box width={[360, 400, 400, 400]} height={[360, 400]} borderRadius={24} p={4}>
                <Stack alignItems='center' height="100%" width="100%" spacing={16} textAlign='left'>
                    <Heading size={'xl'} color={'blackAlpha.800'}>Logga in på verkstadsplattformen</Heading>
                    <Box pt={0} w={'100%'}>
                        <Stack spacing={6}>
                            <InputGroup >
                                <InputLeftElement
                                    pointerEvents='none'
                                    children={<Icon as={FaEnvelope} color='gray.300' />}
                                />
                                <Input type='email' placeholder='E-post'  data-testid='email-input' onChange={e => handleOnChange(e, 'email')} />
                            </InputGroup>

                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents='none'
                                    children={<Icon as={FaLock} color='gray.300' />}
                                />
                                <Input type="password" placeholder='Lösenord' data-testid='password-input' onChange={e => handleOnChange(e, 'password')} />
                            </InputGroup>
                            <Button isLoading={isLoading} variant={'primary'} data-testid='password-input' colorScheme='teal' onClick={() => handleLogin()}>
                                Logga in
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </>

    )
}

export default LoginForm;