import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, ModalHeader, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { Booking } from "../../../hooks/api/booking";
import AdditionalWorkForm from "./AdditionalWorkForm";

interface AddOfferConfirmationProps {
    isOpenModal: boolean;
    onCloseModal: () => void;
    booking: React.MutableRefObject<Booking>;
}
const AdditionalWorkModal: React.FC<AddOfferConfirmationProps> = ({ isOpenModal, onCloseModal, booking }) => {

    const [isMobile] = useMediaQuery('(max-width: 492px)')

    const renderModalBody = () => {
        return <AdditionalWorkForm booking={booking} onCloseModal={onCloseModal}/>
    }

    return (
        <>
            <Modal isOpen={isOpenModal} onClose={() => {
                onCloseModal()
            }} size={isMobile ? 'full' : '2xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontSize={16}>Tilläggsarbete</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p={[4,6]}>
                        {renderModalBody()}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AdditionalWorkModal;
