import { InfoIcon } from "@chakra-ui/icons";
import { Button, Icon, Stack, Input, InputGroup, Text, InputRightElement, Select, Textarea, HStack, Heading, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Badge, Tooltip, useMediaQuery, Box, Progress, Divider, Flex, Alert, Highlight, AlertIcon } from "@chakra-ui/react";
import moment from "moment";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { FaCheckCircle, FaEnvelopeOpenText, FaFilePdf, FaMinusCircle, FaPhotoVideo, FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import { getSeverityStatus } from "../../../common/utils";
import { Booking, useCurrentUnfinishedBookings, useServiceMessagesV2 } from "../../../hooks/api/booking";
import { axiosPost } from "../../../hooks/api/utils";
import { useCustomToast } from "../../../hooks/useCustomToast";
import { Spinner } from "../../Spinner";
import AdditionalWorkSuccess from "./AdditionalWorkSuccessBody";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import storage from "../../../utils/firebaseConfig";
import AddWorkDetailsForm, { formatMoney, getCostSum, getLaborCostSum, getVatCost, units, VALUE_TYPE } from "../AddOffer/AddWorkDetailsForm";
import { WorkArticle } from "../../Marketplace/types";
import { useCompanyInfo, useCompanyServices } from "../../../hooks/api/companyInfo";
import { SETTINGS, useCompanySettings } from "../../../hooks/api/companySettings";

export enum SeverityStatus {
    critical = 'critical',
    recommended = 'recommended',
    visual = 'visual'
}

interface AdditionalWorkFormFields {
    description: string;
    price: number | string;
    severityStatus: SeverityStatus | string;
    selectedArticles: WorkArticle[];
    laborCost: string;
    laborHours: string
}

interface AdditionalWorkFormProps {
    booking: React.MutableRefObject<Booking>
    onCloseModal: () => void;
}

const AdditionalWorkForm: React.FC<AdditionalWorkFormProps> = ({ booking, onCloseModal }) => {
    const { mutateBooking: mutateCurrentUnfinishedBookings } = useCurrentUnfinishedBookings();
    const additionalOfferMessageResult = useServiceMessagesV2(booking.current.bookingId)
    const { mutateCompanyServices } = useCompanyServices();
    const { settingValue: addonArticlePercentageCost } = useCompanySettings(SETTINGS.ADDON_ARTICLE_PERCENTAGE_COST);

    const [description, setDescription] = useState('');
    const [priceTotal, setPriceTotal] = useState('');
    const [severityStatus, setSeverityStatus] = useState('');
    const [mediaAttachment, setMediaAttachment]: [File, Dispatch<SetStateAction<File>>] = useState(null);
    const [pdfAttachment, setPdfAttachment]: [File, Dispatch<SetStateAction<File>>] = useState(null);
    const [additionalWorkList, setAdditionalWorkList]: [AdditionalWorkFormFields[], Dispatch<SetStateAction<AdditionalWorkFormFields[]>>] = useState([]);
    const [isLoadingSendingAdditionalWork, setIsLoadingSendingAdditionalWork] = useState(false);
    const [hasSentSuccessfully, setHasSentSuccessfully] = useState(null);
    const [completeDate, setCompleteDate] = useState('');
    const [shouldControlledReset, setControlledReset] = useState(false);
    const [, rerender] = useState<any>({})
    const [articleFieldsIsDirty, setArticleFieldsIsDirty] = useState(false);
    const [unFinishedWorkArticleError, setUnfinishedWorkArticleError] = useState(false);

    const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
    const [isCompleteUpload, setIsCompleteUpload] = useState<boolean>(false);

    const [isMobile] = useMediaQuery('(max-width: 492px)')

    const methods = useForm();
    const { companyInfo } = useCompanyInfo();
    const { infoToast } = useCustomToast();
    const mediaFileUploadInputRef = useRef(null);
    const pdfFileUploadInputRef = useRef(null);

    const laborCost = methods.getValues('laborCost');
    const laborHours = methods.getValues('laborHours');
    const addedWork = methods.getValues('addedWork');
    const articleRowRef = useRef(null);

    const isValidForm = () => {
        if (addedWork?.length > 0 && laborCost && laborCost > 0) {
            return true;
        }
        return false;
    }

    const addWorkToList = () => {
        if (!description) {
            return infoToast('Vänligen skriv en kort beskrivning på problemet')
        }
        if (!severityStatus) {
            return infoToast('Vänligen välj allvarlighetsgrad')
        }

        if (!isValidForm()) {
            return infoToast('Vänligen lägg till arbete och artiklar')
        }

        if (articleFieldsIsDirty) {
            setUnfinishedWorkArticleError(true)
            if (articleRowRef.current) {
                articleRowRef.current.focus()
            }
            return;
        }


        setAdditionalWorkList([...additionalWorkList, {
            description,
            price: parseInt(priceTotal),
            severityStatus,
            selectedArticles: addedWork,
            laborCost,
            laborHours
        }]);

        setDescription('');
        setPriceTotal('');
        setSeverityStatus('');

        // Reset forms
        methods.setValue('addedWork', null)
        setControlledReset(true);
    }

    const removeWorkFromList = (index: number) => {
        const filteredList = additionalWorkList.filter((item, i) => i !== index)
        setAdditionalWorkList(filteredList);
        setControlledReset(false)
    }

    const handleSaveAndSendAdditionalWorkMessage = async () => {

        if (additionalWorkList.length === 0) {
            return infoToast('Lägg till arbetet innan du skickar');
        }

        const { clientId, regNo, bookingId, email, offerId } = booking.current;
        setIsLoadingSendingAdditionalWork(true)

        let formData = new FormData();
        // Says photo but is also video
        formData.append("pdfAttachment", pdfAttachment);

        const mediaFiles = [];

        if (mediaAttachment) {
            mediaFiles.push({
                filename: mediaAttachment.name,
                originalname: mediaAttachment.name,
                mimetype: mediaAttachment.type,
                size: mediaAttachment.size
            })
        }

        const messagePayload = {
            mediaFiles,
            additionalWorkOffers: additionalWorkList,
            comment: description,
            companyName: companyInfo.companyName,
            companyLogo: companyInfo.companyLogo,
            companyAddress: companyInfo.fullAddress,
            companyEmail: companyInfo.companyEmail,
            companyPhoneNo: companyInfo.companyPhoneNo,
            addonArticlePercentageCost: addonArticlePercentageCost,
            email,
            clientId,
            regNo,
            bookingId,
            completeDate: completeDate || booking.current.pickupDate,
            phoneNo: booking.current.phoneNo,
            fullName: booking.current.fullName,
            bookingSource: booking.current.bookingSource,
            offerId
        }

        formData.append('payload', JSON.stringify(messagePayload));

        try {
            await axiosPost(`/api/communication/service-message-v1`, formData)
            const mutatedBooking = await mutateCurrentUnfinishedBookings();
            booking.current = mutatedBooking.find(b => b.bookingId === booking.current.bookingId);

            await additionalOfferMessageResult.mutate();
            mutateCompanyServices()
            if (booking.current.bookingSource === 'platform') {
                setCompleteDate('')
                setIsLoadingSendingAdditionalWork(false)
                setHasSentSuccessfully(true)
            }
        } catch (error) {
            // throw error, and do not continue
            setIsLoadingSendingAdditionalWork(false)
            setHasSentSuccessfully(false)
            return;
        }

        if (booking.current.bookingSource === 'mobile_app') {
            const payload = {
                userId: clientId,
                source: 'home_new_message',
                messageTemplate: {
                    sound: 'default',
                    title: `Verkstaden har upptäckt ytterligare fel på din bil`,
                    body: `Öppna för att se hela meddelandet från ${companyInfo.companyName}`,
                    data: { withSome: 'data' },
                }
            }

            try {
                await axiosPost(`/api/user/push-notifications-v1/notification/send`, payload)
            } catch (error) {
                // Log somewhere that there is an error when sending notification -> enables manually re-sending
                // throw error, and do not continue
                setIsLoadingSendingAdditionalWork(false)
                setHasSentSuccessfully(false)
                return;
            }

            setCompleteDate('')
            setIsLoadingSendingAdditionalWork(false)
            setHasSentSuccessfully(true)
        }
    }

    if (isLoadingSendingAdditionalWork) {
        return <Spinner />
    }

    if (hasSentSuccessfully) {
        return <AdditionalWorkSuccess onCloseModal={onCloseModal} />
    }

    const renderWorkList = () => {
        return additionalWorkList.map((item, index) => {
            const { sum, addonSum } = getCostSum(item.selectedArticles as any, addonArticlePercentageCost);
            const sumTotal = sum + addonSum + getLaborCostSum(item.laborCost, item.laborHours)
            const vat = formatMoney(getVatCost(sumTotal));
            return (
                <HStack key={index} alignItems={'center'} spacing={2}>
                    <Icon as={FaMinusCircle} color='red.400' onClick={() => removeWorkFromList(index)} cursor={'pointer'} />
                    <Accordion allowToggle allowMultiple w={'100%'}>
                        <AccordionItem border={'none'} background='gray.50' borderRadius={'md'} _hover={{ background: 'gray.100', borderRadius: 'md' }} >
                            <AccordionButton>
                                <HStack width={'100%'} justifyContent={'space-between'}>
                                    <Stack align={'start'}>
                                        <Heading size={'xs'}>#{index + 1}</Heading>
                                    </Stack>
                                    <Badge colorScheme={getSeverityStatus(item.severityStatus).color}>{getSeverityStatus(item.severityStatus).label}</Badge>
                                    <AccordionIcon />
                                </HStack>
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Stack spacing={4}>
                                    <Stack>
                                        <Heading size={'xs'}>Beskrivning</Heading>
                                        <Text>{item.description}</Text>
                                    </Stack>
                                    <Stack >
                                        <Heading size={'xs'}>Arbetsdetaljer</Heading>
                                        {item.selectedArticles?.map((article, i) => {
                                            const { articleAmount, articleName, articlePrice, articleUnit } = article;
                                            const unit = units.find(unit => unit.key === articleUnit as any);
                                            const articlePriceExludingAddons = parseFloat(articlePrice.replace(',', '.')) * articleAmount
                                            const addonCost = ((parseFloat(addonArticlePercentageCost || '0') / 100) + 0) * Number(articlePriceExludingAddons);
                                            const articlePriceIncludingAddonCosts = articlePriceExludingAddons + addonCost;
                                            return (
                                                <Box key={i}>
                                                    {article.valueType === VALUE_TYPE.SPARE_PARTS ? (
                                                        <>
                                                            <HStack justify={'space-between'}>
                                                                <HStack spacing={2}>
                                                                    <Text fontSize={14}>{articleAmount} {unit.label}</Text>
                                                                    <Text fontSize={14}>{articleName}</Text>
                                                                </HStack>
                                                                <Heading size={'xs'}>{formatMoney(articlePriceIncludingAddonCosts)}</Heading>
                                                            </HStack>
                                                            <Divider pt={2} borderColor={'gray.400'} />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <HStack w={'100%'} spacing={2} justifyContent='space-between'>
                                                                <Text fontSize={14}>{articleName}</Text>
                                                                <Heading size={'xs'}>Ingår i arbetskostnaden</Heading>
                                                            </HStack>
                                                            <Divider pt={2} borderColor={'gray.400'} />
                                                        </>
                                                    )}
                                                </Box>
                                            )
                                        })}
                                        <HStack justify={'space-between'}>
                                            <Text fontSize={11}>{`Varav påslag (${addonArticlePercentageCost ?? 0}%)`}</Text>
                                            <Heading fontSize={12}>{formatMoney(addonSum)}</Heading>
                                        </HStack>
                                        <HStack pt={1} justify={'space-between'}>
                                            <Text fontSize={14}>Arbetskostnad</Text>
                                            <Heading size={'xs'}>{formatMoney(getLaborCostSum(item.laborCost, item.laborHours))}</Heading>
                                        </HStack>
                                        <HStack pt={2} justify={'space-between'}>
                                            <Heading size={'sm'}>Totalt</Heading>
                                            <Heading size={'sm'}>{formatMoney(sumTotal)}</Heading>
                                        </HStack>
                                        <HStack justify={'space-between'}>
                                            <Text fontSize={11}>{'Varav moms (25%)'}</Text>
                                            <Heading fontSize={12}>{vat}</Heading>
                                        </HStack>
                                    </Stack>
                                </Stack>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </HStack>
            )
        })
    }

    function handleFirebaseFileUpload(file: File) {
        const storageRef = ref(storage, file.name)
        const uploadTask = uploadBytesResumable(storageRef, file);

        setIsLoadingUpload(true);
        uploadTask.on(
            "state_changed",
            (snapshot) => ({}),
            (err) => {
                setIsLoadingUpload(false);
            },
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setIsCompleteUpload(true)
                    setIsLoadingUpload(false);
                });
            }
        );
    }

    function uploadBox(attachmentFile: File, setValue: Dispatch<SetStateAction<File>>, type: 'pdf' | 'mp4') {

        const attachment = type === 'mp4' ? attachmentFile && isCompleteUpload && !isLoadingUpload : attachmentFile;

        return (
            <Stack
                justifyContent={'center'}
                alignItems={attachment ? 'normal' : 'center'}
                border={'1px'}
                borderColor={attachment ? 'green.400' : 'InactiveBorder'}
                borderStyle={attachment ? 'solid' : 'dashed'}
                borderRadius={'lg'}
                height={24}
                width={'100%'}
                //width={isMobile ? '100%' : '50%'} When allowing pdf back again only
                margin={'0 auto'}
                position={'relative'}
            >

                {!attachment && (
                    <Icon as={type === 'pdf' ? FaFilePdf : FaPhotoVideo} color='blackAlpha.300' boxSize={'10'} />
                )}

                {attachment && (
                    <HStack position={'absolute'} right={2} top={2}>
                        <Icon as={FaCheckCircle} color='green.400' boxSize={'4'} />
                        <Heading size={'xs'} color={'green.400'}>{'Uppladdning klar'}</Heading>
                    </HStack>
                )}

                <HStack spacing={4} pl={4} justifyContent={'flex-start'}>
                    {attachment && (
                        <Icon as={FaTrashAlt} color='red.400' boxSize={'4'} cursor={'pointer'} onClick={() => setValue(null)} />
                    )}

                    {attachment ? (
                        <Heading maxW={'400px'} size={'xs'} color={'blackAlpha.400'}>{attachmentFile.name}</Heading>
                    ) : (
                        <Button colorScheme='teal' variant={'link'} size={'sm'} onClick={(e) => {
                            if (type === 'mp4') {
                                mediaFileUploadInputRef.current.click();
                                return;
                            }
                            if (type === 'pdf') {
                                pdfFileUploadInputRef.current.click();
                                return;
                            }
                        }}>
                            <input
                                type="file"
                                ref={type === 'mp4' ? mediaFileUploadInputRef : type === 'pdf' ? pdfFileUploadInputRef : null}
                                onChange={e => {
                                    const mimeType = e.target.files[0].type;
                                    const isVideo = mimeType.includes('video');
                                    if (isVideo || mimeType.includes('pdf')) {
                                        const selectedFile = e.target.files[0];
                                        setValue(e.target.files[0]);
                                        if (isVideo) {
                                            handleFirebaseFileUpload(selectedFile)
                                        }
                                        return;
                                    }

                                    // TODO: Tillåt bilder också
                                    return alert(`Du försöker ladda upp fel filtyp ( ${mimeType} ) - Endast video tillåtet`);
                                }}
                                style={{ display: 'none' }}
                            />
                            {type === 'pdf' ? 'Lägg till arbetsdetaljer som pdf' :
                                type === 'mp4' && 'Lägg till video på felen'}
                        </Button>
                    )}
                </HStack>
            </Stack>
        )
    }

    function renderFinishedDateField() {
        return (
            <InputGroup zIndex={1} width={isMobile ? '100%' : '50%'}>
                <Stack spacing={1}>
                    <HStack spacing={2} alignItems={'center'}>
                        <Text fontSize={'12px'}>Välj nytt estimerat datum för upphämtning</Text>
                        <Tooltip placement="top" label={'Om tilläggsarbetet utökar det förväntade datumet för upphämtning så är det viktigt att din kund vet om det. Välj isåfall ett nytt datum här. Om inget är valt skickas det här förvalda datumet som är det datumet som valdes vid bokning.'} hasArrow bg="teal.700">
                            <InfoIcon />
                        </Tooltip>
                    </HStack>
                    <Input width={40} type='date' min={moment(Date.now()).format('YYYY-MM-DD')} value={completeDate || booking.current.pickupDate} onChange={e => setCompleteDate(e.target.value)} />
                </Stack>
            </InputGroup>
        )
    }

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSaveAndSendAdditionalWorkMessage)}>
                    <Stack spacing={4}>
                        <Stack spacing={2}>
                            {booking.current.email ? (
                                <Alert status='success' variant='subtle' mb={4} borderRadius={'md'} fontSize={14}>
                                    Ett tilläggsarbete kommer skickas till {booking.current.email}
                                </Alert>
                            ) : (
                                <Alert mb={4} status='error' borderRadius={'md'}>
                                    <Text fontSize={14}>
                                        <Highlight query={['Observera!', 'mejladress']} styles={{ fontWeight: 'bold', fontSize: 14 }}>
                                            {`Observera! Vi hittar ingen mejladress till kund. För att använda tilläggsarbete krävs för tillfället att du fyller i kundens mejladress när du skapar en bokning. Vi kommer snart att göra det möjligt via sms också 👊`}
                                        </Highlight>
                                    </Text>
                                </Alert>
                            )}
                            <HStack justify={'space-between'} align='center'>
                                <Heading size={'sm'}>Lägg till arbete</Heading>
                                <Heading size={'sm'}>{booking.current.regNo}</Heading>
                            </HStack>
                            <Text fontSize={'12px'}>I en offert för tilläggsarbete kan du lägga till flera arbeten efter varandra.</Text>
                        </Stack>
                        <InputGroup zIndex={1}>
                            <Textarea placeholder="Beskriv felet här" size={'md'} value={description} onChange={e => setDescription(e.target.value)} />
                            <InputRightElement
                                pointerEvents='none'
                                children={<Icon as={FaEnvelopeOpenText} color='gray.300' />}
                            />
                        </InputGroup>
                        <InputGroup zIndex={1}>
                            <Flex width={'100%'} gap={4} flexDir={isMobile ? 'column' : 'row'} align='center'>
                                <Select zIndex={1} placeholder="Välj allvarlighetsgrad" value={severityStatus} onChange={(e) => setSeverityStatus(e.target.value)}>
                                    <option value={SeverityStatus.critical}>Kritiskt</option>
                                    <option value={SeverityStatus.recommended}>Rekomenderat</option>
                                    <option value={SeverityStatus.visual}>Visuellt fel</option>
                                </Select>
                                {!isMobile && (
                                    <Tooltip placement="top" label={'Här får du uppskatta hur viktigt det är att arbetet genomförs. Detta ger din kund en bättre indikation på hur allvarligt problemet är. Man kan säga att kritisk allvarlighet kan'
                                        + ' t.ex. vara att det blir riskfyllt att köra på vägarna utan en åtgärd. Rekommenderad allvarlighet kan t.ex. vara något som inte är riskfyllt nu men kommer behöva åtgärdas inom närmaste tid ändå. Tänk på att ärlighet varar längst!'} hasArrow bg="teal.700">
                                        <InfoIcon />
                                    </Tooltip>
                                )}
                            </Flex>
                        </InputGroup>

                        <Stack pt={4}>
                            <AddWorkDetailsForm
                                setUnfinishedWorkArticleError={setUnfinishedWorkArticleError}
                                unFinishedWorkArticleError={unFinishedWorkArticleError}
                                setArticleFieldsIsDirty={setArticleFieldsIsDirty}
                                source='additionalOffer'
                                articleRowRef={articleRowRef}
                                resetFields={shouldControlledReset}
                                rerender={rerender} />
                        </Stack>
                        <HStack justifyContent={'space-between'} pt={4} pb={2} alignItems={'flex-start'}>
                            <Button leftIcon={
                                <Icon as={FaPlusCircle} color='teal.400' />
                            } colorScheme='teal' variant={'outline'} size={'md'} onClick={(e) => addWorkToList()}>
                                Lägg till arbete
                            </Button>
                        </HStack>

                        {renderWorkList()}

                        <Stack spacing={2} pt={4}>
                            <Heading size={'sm'}>Lägg till video</Heading>
                            <Text fontSize={'12px'}>Det är valfritt att lägga till en video på felet.</Text>
                        </Stack>
                        {!isMobile && (
                            <HStack justifyContent={'space-between'} alignItems={'flex-start'}>
                                {/* {uploadBox(pdfAttachment, setPdfAttachment, 'pdf')} */}
                                {uploadBox(mediaAttachment, setMediaAttachment, 'mp4')}
                            </HStack>
                        )}

                        {isMobile && (
                            <Stack spacing={4}>
                                {/* {uploadBox(pdfAttachment, setPdfAttachment, 'pdf')} */}
                                {uploadBox(mediaAttachment, setMediaAttachment, 'mp4')}
                            </Stack>
                        )}

                        <Box height={2}>
                            {isLoadingUpload && (
                                <Progress size='xs' isIndeterminate colorScheme={'teal'} width={'100%'} />
                            )}
                        </Box>
                        {renderFinishedDateField()}

                        <Stack spacing={8} pt={4} pb={4}>
                            <Button
                                type='submit'
                                variant='primary'
                            >
                                Bekräfta och skicka
                            </Button>
                        </Stack>

                    </Stack>
                </form>
            </FormProvider>
        </>
    )
}

export default AdditionalWorkForm;
