import { Box, Heading, HStack, Stat, StatLabel, Stack, Icon, Center, Alert, Badge, Flex } from "@chakra-ui/react"
import moment from "moment";
import { ReactNode } from "react";
import { FaChartPie, FaRocket } from "react-icons/fa";
import { useAdditionalWorkStatistics } from "../../hooks/api/statistics";

const AdditionalWorkStats: React.FC = () => {

    const {
        additionalWorkStatistics,
        isLoadingAdditionalWorkStatistics,
        isErrorAdditionalWorkStatistics
    } = useAdditionalWorkStatistics();

    if (isLoadingAdditionalWorkStatistics) return null;

    if (isErrorAdditionalWorkStatistics) {
        return (
            <Alert status='error' variant='subtle' borderRadius={'lg'}>
                <Heading size={'sm'}>{`Något gick fel när vi försökte hämta statistik. Vi jobbar på problemet, tack för ditt tålamod!`}</Heading>
            </Alert>
        )
    }

    const today = new Date();

    function renderCard(title: string, description: string, icon: any, kpi: ReactNode) {
        return (
            <Box bg={'white'} w={['100%', '50%', '50%', '50%']} minH={200} h={'100%'} boxShadow={'base'} borderRadius={'2xl'} border={'1px solid'} borderColor={'gray.400'}>
                <Stack >
                    <Stack padding={4}>
                        <Heading size={'xs'} fontWeight={'bold'} pb={0}>{title}</Heading>
                        <Box>
                            <Badge variant='outline' colorScheme='purple'>{moment(today).format('MMMM YYYY')}</Badge>
                        </Box>
                    </Stack>
                    <Stack padding={4} pt={0}>
                        <Stat>
                            <StatLabel fontSize={'xs'}>{description}</StatLabel>
                        </Stat>

                        <HStack justifyContent={'space-between'} alignItems={'end'}>
                            <Heading ml={8} fontWeight={'thin'} fontSize={'6xl'} color={'teal.700'}>{kpi}</Heading>
                            <Center w={12} h={12} bg={'purple.50'} borderRadius={'full'}>
                                <Icon as={icon} fontSize='2xl' color={'purple.600'} />
                            </Center>
                        </HStack>
                    </Stack>
                </Stack>
            </Box >
        )
    }


    return (
        <Stack spacing={4}>
            <Flex flexDir={['column', 'row', 'row', 'row']} gap={4}>
                {renderCard('Skickade tilläggsarbeten', 'Totala antalet utskick innehållande minst ett tilläggsarbete', FaChartPie, additionalWorkStatistics.totalSent)}
                {renderCard('Accepterade tilläggsarbeten', 'Totala antalet accepterade tilläggsarbeten den här månaden', FaRocket, additionalWorkStatistics.totalAmountOfWorkItemsAccepted)}
            </Flex>

        </Stack>

    )
}

export default AdditionalWorkStats;