import React from "react";
import BookingsTable from "../components/Bookings/BookingsTable";
import BaseLayout from "./BaseLayout";
import ReactGA from "react-ga4";

const Bookings: React.FC = () => {
    ReactGA.send({ hitType: "pageview", page: '/bookings', title: "Bokningslistan" });

    return (
        <BaseLayout>
            <BookingsTable />
        </BaseLayout>
    )
}

export default Bookings;