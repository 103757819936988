import React from "react";
import { GoogleMap, Marker } from '@react-google-maps/api'

type GoogleMapsProps = {
    position: any;
}

const GoogleMaps: React.FC<GoogleMapsProps> = ({ position }) => {

    const googlePos = new google.maps.LatLng(position);

    return (
        <GoogleMap zoom={16} center={googlePos} mapContainerStyle={{ width: '100%', height: 250 }}>
            <Marker position={googlePos} />
        </GoogleMap>
    )
}

export default GoogleMaps;