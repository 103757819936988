import { Box, Button, Image, Heading, Icon, Input, InputGroup, InputLeftElement, Stack, useDisclosure, Textarea, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useToast, HStack, useMediaQuery, InputRightElement, Text, Wrap, WrapItem, IconButton, SkeletonText, Flex } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { FaEdit, FaEnvelope, FaFileImage, FaMap, FaPhone, FaRocket, FaTrash, FaVideo } from "react-icons/fa";
import BaseLayout from "./BaseLayout";
import CompanyInfoDrawer from '../components/CompanyInfoDrawer';
import { axiosPost, axiosPut } from "../hooks/api/utils";
import { useCompanyInfo, useMediaAssets, useOpeningHours } from "../hooks/api/companyInfo";
import AssetsModal from "../components/modals/Assets";
import GoogleMaps from "../components/CompanyInfo/Map";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import Card from "../components/Card/Card";
import { Spinner } from "../components/Spinner";
import { ASSET_TYPE } from "../enums/assetTypes";
import { Video } from "../components/Video";
import { useCustomToast } from "../hooks/useCustomToast";
import moment from "moment";
import OpeningHoursModal from "../components/modals/OpeningHours/OpeningHoursModal";
import { getFirebaseImageUrl } from "../utils/firebase";
import { CompanyServicesSelect } from "../components/CreateBooking/CompanyServicesSelect";


const CompanyInfo: React.FC<{}> = () => {
    const { companyInfo, mutateCompanyInfo, isValidating } = useCompanyInfo();
    const { mediaAssets, isLoadingMediaAssets, mutateMediaAssets } = useMediaAssets();
    const { openingHours, isLoadingOpeningHours, mutateOpeningHours } = useOpeningHours();

    const [companyName, setCompanyName] = useState(null);
    const [companyEmail, setCompanyEmail] = useState(null);
    const [companyPhoneNo, setCompanyPhoneNo] = useState(null);
    const [description, setDescription] = useState(null);


    const [isLoadingUpdatePosition, setIsLoadingUpdatePosition] = useState(false);
    const [selectedCoords, setSelectedCoords] = useState(companyInfo && { lat: companyInfo?.latitude, lng: companyInfo?.longitude });
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [assetType, setAssetType] = useState(ASSET_TYPE.PREVIEW_IMAGE);
    const [isLoadingNewVideo, setIsLoadingNewVideo] = useState(false);
    const [, setOverrideVideoWarning] = useState(false);

    const { isOpen, onClose } = useDisclosure()
    const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure()
    const { isOpen: isOpenAddressModal, onOpen: onOpenAddressModal, onClose: onCloseAddressModal } = useDisclosure()
    const { isOpen: isOpenOpeningHoursModal, onOpen: onOpenOpeningHoursModal, onClose: onCloseOpeningHoursModal } = useDisclosure()
    const [isMobile] = useMediaQuery('(max-width: 492px)', { fallback: false })

    const btnRef = useRef()
    const toast = useToast();
    const { successToast } = useCustomToast();
    const fileUploadInputRef = React.useRef(null);

    const marketingAssetCap = 5;

    const marketingAssets = mediaAssets && mediaAssets?.length > 0 && mediaAssets?.filter(asset => asset.source === ASSET_TYPE.MARKETING)
    const videoPresentation = mediaAssets && mediaAssets?.length > 0 && mediaAssets?.filter(asset => asset.mimeType.includes('video'))

    const [isLargerThan1024] = useMediaQuery('(min-width: 992px)', { fallback: true })
    const [isLargerThanMobile] = useMediaQuery('(min-width: 500px)', { fallback: true })

    const onSaveCompanyInfo = async () => {
        const payload = {
            companyEmail: companyEmail || companyInfo?.companyEmail,
            companyName: companyName || companyInfo?.companyName,
            companyPhoneNo: companyPhoneNo || companyInfo?.companyPhoneNo,
            description: description || companyInfo?.description
        };

        try {
            const companyInfoResponse = await axiosPut('/api/company-info/info-v1/company-info', payload)
            mutateCompanyInfo(companyInfoResponse);
        } catch (error) {
            return toast({
                title: 'Något gick fel när företagsinformation försökte uppdateras',
                status: 'error',
                duration: 4000,
                position: "top",
                isClosable: true,
            })
        }
    }

    const savePosition = async () => {
        setIsLoadingUpdatePosition(true)

        const payload = {
            fullAddress: selectedAddress.fullAddress,
            latitude: selectedCoords.lat,
            longitude: selectedCoords.lng
        }

        try {
            await axiosPut('/api/company-info/info-v1/position', payload);
            setIsLoadingUpdatePosition(false)
            mutateCompanyInfo();
            onCloseAddressModal();

            return toast({
                title: 'Din position har nu uppdaterats',
                status: 'success',
                duration: 4000,
                position: "top",
                isClosable: true,
            })
        } catch (error) {
            setIsLoadingUpdatePosition(false)
            console.log(error)
        }
    }

    const onRemoveFromMarketingAssets = async (assetToken) => {
        const payload = {
            assetToken: assetToken,
        }

        try {
            await axiosPut('/api/company-info/info-v1/assets/update-marketing-source', payload);
            mutateMediaAssets();
            return toast({
                title: 'Bild borttagen',
                status: 'success',
                duration: 3000,
                position: "top",
                isClosable: true,
            })
        } catch (error) {
            console.log(error)
        }
    }

    const currentCoordinates = selectedCoords || { lat: companyInfo?.latitude, lng: companyInfo?.longitude }

    // Updates an existing video - can only have one presentational video as a starter rule
    const updateVideoInMediaAssets = async (video) => {
        let formData = new FormData();
        formData.append('video', video);

        setIsLoadingNewVideo(true);
        onCloseModal()
        try {
            const mediaAssetsResponse = await axiosPut('/api/company-info/info-v1/assets/update-video', formData, {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
            });
            mutateMediaAssets(mediaAssetsResponse)
            // TODO: used alot, make reusable with just a title prop intead
            setIsLoadingNewVideo(false)
            return successToast('Presentationsvideo uppdaterad')

        } catch (error) {
            setIsLoadingNewVideo(false)
            console.log(error)
        }
    }

    const uploadFileToMediaAssets = async (file) => {
        let formData = new FormData();
        formData.append('image', file);

        // TODO: Validera på backend - om namnet finns i assets för kunden -> returnera "already.exists"-fel
        try {
            const mediaAssetsResponse = await axiosPost('/api/company-info/info-v1/assets', formData, {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
            });
            mutateMediaAssets(mediaAssetsResponse)

        } catch (error) {
            console.log(error)
        }
    }

    const renderAddressEditModal = () => {
        return (
            <Modal isOpen={isOpenAddressModal} onClose={() => {
                onCloseAddressModal();
                setSelectedCoords(null)
            }} size={isLargerThan1024 ? '3xl' : 'xs'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody padding={8} minHeight={200}>
                        <Stack spacing={4} >
                            <Box zIndex={9999}>
                                <PlacesAutocomplete setSelectedCoords={setSelectedCoords} setSelectedAddress={setSelectedAddress} />
                            </Box>

                            <>
                                {currentCoordinates && (
                                    <GoogleMaps position={currentCoordinates} />
                                )}
                                <Box pt={4}>

                                    <Button
                                        w="200px"
                                        variant={'primary'}
                                        size="sm"
                                        onClick={() => savePosition()}
                                        isLoading={isLoadingUpdatePosition}
                                    >
                                        Spara min position
                                    </Button>
                                </Box>
                            </>

                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        );
    }

    const renderMarketingAssets = () => {
        if (isLoadingMediaAssets) {
            return <Spinner />
        }
        return (
            <Wrap spacing={8}>
                {marketingAssets && marketingAssets.length > 0 && marketingAssets?.map((asset, i) => (
                    <WrapItem key={i}>
                        <IconButton
                            variant='ghost'
                            colorScheme='red'
                            aria-label='Trash Can'
                            size='sm'
                            icon={<Icon as={FaTrash} />}
                            onClick={() => onRemoveFromMarketingAssets(asset.assetToken)}
                        />
                        <Image
                            pt={4}
                            height={['300px', '300px', '300px', '300px']}
                            width={['300px', '300px', '300px', '300px']}
                            objectFit='contain'
                            src={getFirebaseImageUrl(asset?.assetToken)}
                            alt='Företagsbild'
                        />
                    </WrapItem>
                ))}
            </Wrap>

        )
    }


    //TODO: New component for this one and move isLoading-useState to avoid re-render of the whole page but rather only the component itself
    const renderVideoPresentationContent = () => {
        if (isLoadingNewVideo) {
            return <Spinner />
        }
        if (!isLoadingNewVideo && videoPresentation?.length > 0) {
            return (
                <Stack spacing={6}>
                    <Video selectedVideo={getFirebaseImageUrl(videoPresentation[0]?.assetName)} w={800} h={250} />

                    <Stack spacing={2}>
                        <Button
                            width={'140px'}
                            colorScheme='teal'
                            variant='outline'
                            size="sm"
                            onClick={() => {
                                fileUploadInputRef.current.click()
                            }}
                            leftIcon={<Icon as={FaVideo} color='teal.500' />}
                        >
                            <input
                                type="file"
                                ref={fileUploadInputRef}
                                onChange={e => {
                                    updateVideoInMediaAssets(e.target.files[0])
                                    setOverrideVideoWarning(false)
                                }}
                                style={{ display: 'none' }}
                            />
                            Ändra video
                        </Button>
                        <Text as="em" fontSize="11px">Observera att vid ändring av din nuvarande video kommer den helt att ersättas med din nya</Text>
                    </Stack>
                </Stack>
            )
        }
        return (
            <>
                <Heading size={'xs'} opacity={.8} textAlign={'center'}>Lägg till en presentationsvideo på din verkstad</Heading>
                <Button
                    width={'140px'}
                    variant='secondary'
                    size="sm"
                    onClick={() => {
                        fileUploadInputRef.current.click()
                    }}
                    leftIcon={<Icon as={FaVideo} />}
                >
                    Lägg till
                    <input
                        type="file"
                        ref={fileUploadInputRef}
                        onChange={e => {
                            uploadFileToMediaAssets(e.target.files[0])
                        }}
                        style={{ display: 'none' }}
                    />
                </Button>
            </>
        )
    }

    return (
        <BaseLayout>
            {renderAddressEditModal()}
            <OpeningHoursModal isOpenModal={isOpenOpeningHoursModal} onCloseModal={onCloseOpeningHoursModal} openingHours={openingHours} mutateOpeningHours={mutateOpeningHours} />
            <CompanyInfoDrawer isOpen={isOpen} onClose={onClose} btnRef={btnRef} />
            <AssetsModal isOpenModal={isOpenModal} onCloseModal={onCloseModal} imageType={assetType} setIsLoadingNewVideo={setIsLoadingNewVideo} />

            <Stack spacing={8} mb={12}>
                <HStack flexDirection={isLargerThan1024 ? 'row' : 'column'} spacing={4} alignItems={isLargerThan1024 ? 'flex-start' : 'center'}>
                    <Stack spacing={4} width={['96%', '96%', '96%', '35%']}>
                        <Card padding={'md'}>
                            <Flex gap={6} flexDir={isMobile ? 'column' : 'row'} width='100%'>
                                <Image
                                    borderRadius="2xl"
                                    height={['130px', '130px', '130px', '130px']}
                                    objectFit={isMobile ? 'cover' : 'contain'}
                                    src={companyInfo?.marketingCardImage ? getFirebaseImageUrl(companyInfo?.marketingCardImage) : companyInfo?.marketingcardfallbackimage}
                                    alt='Företagsbild'
                                />
                                <Stack height={[24, 40, 40, 32]} w={'100%'} flexWrap={'wrap'}>
                                    <Stack justifyContent={'flex-start'}>
                                        <HStack align={'center'} justify={'space-between'}>
                                            <Heading size={'sm'}>{companyInfo?.companyName}</Heading>
                                            <IconButton
                                                colorScheme='blue'
                                                aria-label='Change image'
                                                size='xs'
                                                icon={<FaEdit />}
                                                onClick={(e) => {
                                                    setAssetType(ASSET_TYPE.PREVIEW_IMAGE)
                                                    onOpenModal()
                                                }} />

                                        </HStack>
                                        <Text fontSize={14}>{companyInfo?.fullAddress}</Text>
                                    </Stack>
                                </Stack>
                            </Flex>
                        </Card>

                        <Card padding={'lg'} >
                            {isLoadingOpeningHours ? (
                                <Stack spacing={4} top={12}>
                                    <SkeletonText mt='4' noOfLines={7} spacing='4' />
                                </Stack>
                            ) : (
                                <Stack justifyContent={'space-between'} minHeight={32}>
                                    <Stack justifyContent={'flex-start'}>
                                        <HStack justifyContent={'space-between'}>
                                            <Heading size={'sm'}>Öppetider</Heading>

                                            {openingHours?.length > 0 && (
                                                <Button
                                                    colorScheme='blue'
                                                    variant='link'
                                                    size="sm"
                                                    onClick={() => onOpenOpeningHoursModal()}
                                                >
                                                    Ändra
                                                </Button>
                                            )}
                                        </HStack>

                                        {openingHours?.length === 0 && (
                                            <Stack spacing={4} pt={4} justifyContent={'center'} align='center'>
                                                <Heading opacity={.8} size={'sm'}>Du har inga öppettider inlagda</Heading>
                                                <Button
                                                    width={'140px'}
                                                    variant='secondary'
                                                    size="sm"
                                                    onClick={() => onOpenOpeningHoursModal()}
                                                >
                                                    Lägg till
                                                </Button>
                                            </Stack>

                                        )}

                                        {openingHours?.map((currentPeriod, index) => {
                                            const prevPeriod = openingHours[index - 1]

                                            if (prevPeriod?.openingDay - 1 > currentPeriod?.openingDay) {
                                                return (
                                                    <Stack key={index}>
                                                        <HStack justifyContent={'space-between'}>
                                                            <Text>{moment().weekday(prevPeriod?.openingDay).format('dddd')}</Text>

                                                            <HStack spacing={4}>
                                                                <Heading size={'xs'}>Stängt</Heading>
                                                            </HStack>
                                                        </HStack>
                                                    </Stack>
                                                )
                                            }

                                            return (
                                                <Stack key={index}>
                                                    <HStack justifyContent={'space-between'}>
                                                        <Text>{moment().weekday(currentPeriod.openingDay).format('dddd')}</Text>

                                                        <HStack spacing={4}>
                                                            <Heading size={'xs'}>{`${currentPeriod.openingTime.slice(0, 2)}:${currentPeriod.openingTime.slice(2, 4)}`}</Heading>
                                                            <Heading size={'xs'}>-</Heading>
                                                            <Heading size={'xs'}>{`${currentPeriod.closingTime.slice(0, 2)}:${currentPeriod.closingTime.slice(2, 4)}`}</Heading>
                                                        </HStack>
                                                    </HStack>
                                                </Stack>
                                            )
                                        })}
                                    </Stack>
                                </Stack>
                            )}
                        </Card>

                        <Card padding={'md'}>
                            <Stack spacing={4}>
                                <Stack spacing={8} height={320} padding={4} justifyContent={'center'} alignItems={'center'} border={videoPresentation?.length === 0 && '1px dashed gray'} borderRadius={'lg'}>
                                    {renderVideoPresentationContent()}
                                </Stack>
                            </Stack>
                        </Card>
                    </Stack>
                    <Stack padding={isLargerThan1024 ? 0 : 4} pl={!isLargerThan1024 && -2} spacing={4} width={['100%', '100%', '100%', '65%']}>
                        <Card padding="lg">
                            <Stack spacing={8}>
                                <Heading size={'sm'}>Generell företagsinformation</Heading>
                                <Stack spacing={4}>

                                    <InputGroup size="md">
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<Icon as={FaRocket} color='gray.300' />}
                                        />
                                        <Input borderRadius={'md'} fontSize={15} defaultValue={companyName || companyInfo?.companyName} placeholder='Företagsnamn' onChange={e => setCompanyName(e.target.value)} />
                                        <InputRightElement width={160} justifyContent={'flex-end'} mr={4}
                                            children={<Text opacity={.6} fontSize={14}>Företagets namn</Text>}
                                        />
                                    </InputGroup>
                                    <InputGroup size="md">
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<Icon as={FaMap} color='gray.300' />}
                                        />
                                        <Input readOnly borderRadius={'md'} fontSize={15} defaultValue={isLargerThanMobile ? companyInfo?.fullAddress : `${companyInfo?.fullAddress ? companyInfo?.fullAddress?.slice(0, 22) + '...' : ''}`} placeholder='Address' />
                                        <InputRightElement justifyContent={'flex-end'} mr={4}
                                            children={
                                                <>
                                                    <Button
                                                        w="100px"
                                                        colorScheme='blue'
                                                        variant='link'
                                                        size="sm"
                                                        onClick={() => onOpenAddressModal()}
                                                    >
                                                        Ändra
                                                    </Button>
                                                </>
                                            }
                                        />
                                    </InputGroup>

                                    <InputGroup size="md">
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<Icon as={FaPhone} color='gray.300' />}
                                        />
                                        <Input borderRadius={'md'} fontSize={15} defaultValue={companyPhoneNo || companyInfo?.companyPhoneNo} type='number' placeholder='Telefonnummer' onChange={e => setCompanyPhoneNo(e.target.value)} />
                                        <InputRightElement width={160} justifyContent={'flex-end'} mr={4}
                                            children={<Text opacity={.6} fontSize={14}>Telefonnummer</Text>}
                                        />
                                    </InputGroup>

                                    {/* TODO: email-validering */}
                                    <InputGroup size="md">
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<Icon as={FaEnvelope} color='gray.300' />}
                                        />
                                        <Input type={'email'} borderRadius={'md'} fontSize={15} defaultValue={companyEmail || companyInfo?.companyEmail} placeholder='E-post' onChange={e => setCompanyEmail(e.target.value)} />
                                        <InputRightElement width={160} justifyContent={'flex-end'} mr={4}
                                            children={<Text opacity={.6} fontSize={14}>Företagets E-post</Text>}
                                        />
                                    </InputGroup>

                                    <Stack pt={4}>
                                        <Text fontSize={'sm'}>Ge en beskrivning till användarna av BilGo-appen om din verkstad och vilka ni är</Text>
                                        <Textarea
                                            maxLength={500}
                                            placeholder='Kort beskrivning av ditt företag'
                                            onChange={e => setDescription(e.target.value)}
                                            defaultValue={description || companyInfo?.description}
                                        />
                                        <Text fontSize={'12px'}>{`${description?.length || companyInfo?.description?.length || 0}/500`}</Text>
                                    </Stack>


                                </Stack>
                                <Box marginLeft="12">
                                    <Button
                                        colorScheme='teal'
                                        variant='primary'
                                        isLoading={isValidating}
                                        size="sm"
                                        onClick={() => onSaveCompanyInfo()}
                                    >
                                        Spara företagsinformation
                                    </Button>
                                </Box>
                            </Stack>
                        </Card>

                        <CompanyServicesSelect />

                        <Card padding="lg">
                            <Stack spacing={8}>
                                <Heading size={'sm'}>Din verkstad i bilder</Heading>
                                <Stack spacing={4}>
                                    <Heading size={'xs'}>Du har {marketingAssetCap - marketingAssets?.length || 0} bilder kvar att lägga till</Heading>

                                    {renderMarketingAssets()}

                                    <Button
                                        onClick={() => {
                                            setAssetType(ASSET_TYPE.MARKETING);
                                            onOpenModal()
                                        }}
                                        width={'140px'}
                                        disabled={marketingAssets?.length === marketingAssetCap}
                                        variant='secondary'
                                        size="sm"
                                    >
                                        {/* <input
                                            type="file"
                                            ref={marketingAssetRef}
                                            onChange={e => {
                                                uploadImageToMarketingAssets(e.target.files[0])
                                            }}
                                            style={{ display: 'none' }}
                                        /> */}
                                        Lägg till
                                    </Button>
                                </Stack>
                            </Stack>

                        </Card>
                    </Stack>
                </HStack>
            </Stack>
        </BaseLayout>
    )
}

export default CompanyInfo;