import { Button, Icon, Stack, Input, InputGroup, InputLeftElement, HStack, Text, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, InputRightElement, Heading, FormControl, FormErrorMessage, Box, Badge, Flex, Select, useDisclosure, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaCheck, FaMoneyBill, FaPaperclip, FaTrashAlt } from "react-icons/fa";
import { OfferEnquiry } from "../../Marketplace/types";
import { DayPicker } from "../../Marketplace/DayPickerV2";
import { useFormContext } from 'react-hook-form'
import { useCustomToast } from "../../../hooks/useCustomToast";
import HandInTimeIntervalSetting, { getFormattedTimeInterval } from "../../Settings/HandInTimeInverval";
import { SETTINGS, useCompanySettings } from "../../../hooks/api/companySettings";
import AddWorkDetailsForm from "./AddWorkDetailsForm";

interface AddOfferConfirmationProps {
    file: any;
    setFile: (val: any) => void;
    onSaveOffer: any;
    enquiry: OfferEnquiry;
    fileUploadInputRef: React.MutableRefObject<any>;
    setHandInDates: (val: any) => void;
    handInDates: object;
    isLoadingSaveOfferFile: boolean;
    setTab?: (val: string) => void;
    tab?: string;
    unFinishedWorkArticleError?: boolean;
    setUnfinishedWorkArticleError?: (val: boolean) => void;
}

const OfferForm: React.FC<AddOfferConfirmationProps> = ({ enquiry, file, setFile, onSaveOffer, fileUploadInputRef, setHandInDates, handInDates, isLoadingSaveOfferFile, tab, setTab, unFinishedWorkArticleError, setUnfinishedWorkArticleError }) => {
    const { register, handleSubmit, setValue, formState: { errors } } = useFormContext();
    const { onClose, onOpen, isOpen } = useDisclosure();
    const { infoToast } = useCustomToast();
    const { settingValue } = useCompanySettings(SETTINGS.HAND_IN_TIME_INTERVAL);
    const { fromTime, toTime } = getFormattedTimeInterval(settingValue)

    useEffect(() => {
        setValue('availableTimeFrom', fromTime)
        setValue('availableTimeTo', toTime)
    }, [fromTime, toTime, setValue])

    return (
        <>
            <form onSubmit={handleSubmit(onSaveOffer)}>
                <Stack spacing={4} padding={[4, 6]} pt={4}>
                    <Tabs variant='soft-rounded' size={'sm'} p={0} onChange={(index) => {
                        const value = index === 0 ? 'form' : 'file';
                        setTab(value);
                        setValue('workDetailsFormat', value);
                    }}>
                        {/* <TabList gap={4} pb={4}>
                            <Tab _hover={{ color: 'teal.700' }}>Skapa arbetsdetaljer</Tab>
                            <Tab _hover={{ color: 'teal.700' }}>Ladda upp arbetsdetaljer (.pdf)</Tab>
                        </TabList> */}
                        <TabPanels>
                            <TabPanel p={0}>
                                <Stack spacing={2} pt={2}>
                                    <AddWorkDetailsForm selectedOfferEnquiry={enquiry} unFinishedWorkArticleError={unFinishedWorkArticleError} setUnfinishedWorkArticleError={setUnfinishedWorkArticleError} source="offer" />
                                </Stack>
                            </TabPanel>
                            <TabPanel p={0}>
                                {tab === 'file' && (
                                    <Stack spacing={2} pt={4}>
                                        <Box>
                                            <Heading size={'sm'}>Ladda upp fullständiga arbetsdetaljer i .pdf-format</Heading>
                                            <Text fontSize={12}>Från ditt arbetsorder-system (tex. AD Bildelar) kan du ladda upp ordern här som kunden därefter får tillgång till direkt i appen</Text>
                                        </Box>
                                        <Box pt={2}>
                                            <HStack align={'center'} spacing={4}>
                                                <Button disabled={file} colorScheme='teal' variant={'outline'} rightIcon={<Icon color="teal.500" as={FaPaperclip} />} size={'md'} onClick={(e) => {
                                                    fileUploadInputRef.current.click();
                                                }}>
                                                    <input
                                                        type="file"
                                                        ref={fileUploadInputRef}
                                                        onChange={e => {
                                                            const mimeType = e.target.files[0].type;
                                                            const isPdf = mimeType.includes('pdf');
                                                            if (!isPdf) {
                                                                return infoToast('Du kan endast ladda upp .pdf-filer')
                                                            }
                                                            setFile(e.target.files[0])
                                                        }}
                                                        style={{ display: 'none' }}
                                                    />
                                                    Ladda upp arbetsdetaljer
                                                </Button>
                                                {file && (
                                                    <Icon color="teal.600" as={FaCheck} />
                                                )}
                                            </HStack>
                                        </Box>
                                        <FormControl isInvalid={'priceTotal' in errors}>
                                            <InputGroup>
                                                <InputLeftElement
                                                    pointerEvents='none'
                                                    children={<Icon as={FaMoneyBill} color='gray.300' />}
                                                />
                                                <Input
                                                    type='number'
                                                    min={1}
                                                    placeholder='Totalt pris inkl. moms'
                                                    {...register('priceTotal', {
                                                        required: 'Du behöver ange totalpriset av hela arbetet',
                                                        onChange(event) {
                                                            return event.target.value;
                                                        }
                                                    })}
                                                />
                                                <InputRightElement opacity={.7} mr={4}>
                                                    <Text>SEK</Text>
                                                </InputRightElement>
                                            </InputGroup>

                                            <FormErrorMessage>
                                                {errors?.priceTotal?.message as string}
                                            </FormErrorMessage>
                                        </FormControl>

                                    </Stack>
                                )}

                                {file && (
                                    <HStack spacing={4} alignItems={'center'}>
                                        <Accordion allowToggle pt={2} w={80}>
                                            <AccordionItem border={'none'} background='gray.50' borderRadius={'lg'} _hover={{ background: 'gray.100', borderRadius: 'lg' }}>
                                                <AccordionButton>
                                                    <HStack width={'100%'} justifyContent={'space-between'}>
                                                        <HStack>
                                                            <Icon color="teal.500" as={FaPaperclip} />
                                                            <Text>{file?.name}</Text>
                                                        </HStack>
                                                        <AccordionIcon />
                                                    </HStack>
                                                </AccordionButton>
                                                <AccordionPanel pb={4}>
                                                    <embed src={URL.createObjectURL(file)} height={300} type={'application/pdf'} />
                                                </AccordionPanel>
                                            </AccordionItem>
                                        </Accordion>
                                        <Icon color="red.500" as={FaTrashAlt} fontSize={20} cursor={'pointer'} onClick={() => setFile(null)} />
                                    </HStack>
                                )}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>


                    <Stack pt={8} spacing={6} pb={24}>
                        <Heading size={'sm'}>Tillgänglighet</Heading>
                        <Stack spacing={0}>
                            <Text fontSize={12}>Uppskatta tidsåtgång</Text>
                            <FormControl isInvalid={'durationInDays' in errors}>
                                <InputGroup>
                                    <Select width={'100%'} size={'md'} variant={'outline'} placeholder={'Välj antal arbetsdagar'} bg={'white'} borderRadius={'lg'} {...register('durationInDays', {
                                        required: 'Sätt ett ungefär på hur många dagar arbetet kommer ta att utföra',
                                        onChange(event) {
                                            return event.target.value;
                                        }
                                    })}>
                                        {Array.from({ length: 12 }, (v, i) => i).map((workDayAmount, i) => {
                                            return (
                                                <option value={workDayAmount} key={i}>{workDayAmount} dagar {workDayAmount === 0 && '(Färdig samma dag som inlämning)'}</option>
                                            )
                                        })}
                                    </Select>
                                </InputGroup>
                                <FormErrorMessage>
                                    {errors?.durationInDays?.message as string}
                                </FormErrorMessage>
                            </FormControl>

                        </Stack>
                        <Stack spacing={1}>
                            <Heading size={'xs'}>Välj inlämningstid och datum</Heading>
                            <Text fontSize={12}>Kunden har önskat följande datum</Text>
                            {enquiry.selectedDays?.length === 0 ? (
                                <Box>
                                    <Badge colorScheme='teal' borderRadius={'full'} px={2} py={.8}>Tidigast möjligt</Badge>
                                </Box>
                            ) : (
                                <Flex wrap={'wrap'} width={600} >
                                    {enquiry.selectedDays?.map((day, index) => (
                                        <Box key={index} padding={'2px'}>
                                            <Badge colorScheme='teal' borderRadius={'full'} px={2} py={.8}>
                                                {day}
                                            </Badge>
                                        </Box>
                                    ))}
                                </Flex>
                            )}
                        </Stack>

                        <HStack align={'center'} spacing={4}>
                            <FormControl isInvalid={'availableTimeFrom' in errors}>
                                <Text fontSize={12}>Inlämning från</Text>
                                <InputGroup>
                                    <Input
                                        type='time'
                                        defaultValue={fromTime}
                                        placeholder='Inlämningstid Från'
                                        {...register('availableTimeFrom', {
                                            required: 'Sätt en från-tid för när bilen kan hämtas',
                                            onChange(event) {
                                                return event.target.value;
                                            }
                                        })}
                                    />
                                </InputGroup>
                                <FormErrorMessage>
                                    {errors?.availableTimeFrom?.message as string}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={'availableTimeTo' in errors}>
                                <Text fontSize={12}>Inlämning till</Text>
                                <InputGroup>
                                    <Input
                                        type='time'
                                        defaultValue={toTime}
                                        placeholder='Inlämningstid Till'
                                        {...register('availableTimeTo', {
                                            required: 'Sätt en till-tid för när bilen kan hämtas',
                                            onChange(event) {
                                                return event.target.value;
                                            }
                                        })}
                                    />
                                </InputGroup>
                                <FormErrorMessage>
                                    {errors?.availableTimeTo?.message as string}
                                </FormErrorMessage>
                            </FormControl>
                            <HandInTimeIntervalSetting onClose={onClose} onOpen={onOpen} isOpen={isOpen} />
                        </HStack>
                        <DayPicker datesPayload={handInDates} setDatesPayload={setHandInDates} selectedDays={enquiry.selectedDays} />
                    </Stack>
                </Stack>

                <Box position={'fixed'} bg={'gray.100'} bottom={0} h={16} w={'100%'} boxShadow={'2xl'} zIndex={99}>
                    <HStack spacing={4} padding={3} ml={4} justify={'space-between'}>
                        <Button
                            colorScheme='teal'
                            size={'md'}
                            variant='primary'
                            type='submit'
                            isLoading={isLoadingSaveOfferFile}
                        >
                            Bekräfta och skicka
                        </Button>
                    </HStack>
                </Box>
            </form>
        </>
    )
}

export default OfferForm;
