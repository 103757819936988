import { Box, Flex, HStack, Image } from "@chakra-ui/react";
import React, { useEffect } from "react";
import LoginForm from "../components/LoginForm";
import appicon from '../assets/appicon.png'
import rightImage from '../assets/pranay-pareek-_k_J5lPCq_Q-unsplash.jpg'

const Login: React.FC = () => {

    useEffect(() => {
        // Always remove any signs of logged in state here
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('eventNotificationExpirationTime');
    }, [])

    return (
        <HStack bg={'white'} >
            <Box w={['100%', '100%', '50%', '50%']}>
                <a href="https://www.bilgo.se">
                    <Image cursor={'pointer'} _hover={{ opacity: .9 }} pos={'absolute'} top={4} left={8} h={12} w={12} borderRadius='full' objectFit='cover' src={appicon} />
                </a>
                <Flex
                    alignItems="center"
                    justify={'center'}
                    height="100vh"
                    background="white"
                >
                    <LoginForm />
                </Flex>
            </Box>

            <Box w={['0%', '0%', '50%', '50%']} p={0} m={0}>
                <Image p={0} m={0} height={'100vh'} w='100vw' objectFit='cover' src={rightImage} />
            </Box>
        </HStack>

    )
}

// https://framerbook.com/animation/example-animations/4-repeat/

export default Login;