import useSWR from "swr"
import { fetcher } from "./utils"

export interface NotificationLogResult {
    source: string;
    sentEmail: boolean;
    sentSms: boolean;
    sentPushNotification: boolean;
}

export function useNotificationLogResult(bookingId: string) {
    const { data, error, mutate, isValidating } = useSWR<NotificationLogResult[] | null>(bookingId ? `/api/communication//notification-log-v1/booking/${bookingId}` : null, fetcher);

    return {
        notificationData: data,
        isLoading: !error && !data,
        isError: error,
        mutateNotificationLogResult: mutate,
        isValidating
    }
}
