import { Heading, Text, Stack, Box } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { SelectedWorkCategorieEntity } from "../../hooks/api/booking";

const SelectedWorkCategoriesColumn: React.FC<{ selectedWorkCategories: SelectedWorkCategorieEntity[] }> = ({ selectedWorkCategories }) => {
    return (
        <Stack spacing={2}>
            {selectedWorkCategories?.map((category, i) => {
                return (
                    <Fragment key={i}>
                        <Stack spacing={2} justifyContent='center'>
                            <Heading size={'xs'}>{category?.label}</Heading>
                            <Box pl={6}>
                                {category?.items?.map((subCatData, i) => (
                                    <ul key={i}>
                                        <li>
                                            <Text key={i} fontSize={14}>{subCatData?.label}</Text>
                                        </li>
                                    </ul>
                                ))}
                            </Box>
                        </Stack>
                    </Fragment>
                )
            })}
        </Stack>
    )
}

export const SelectedWorkCategoriesColumnWithKeyValSubCategories: React.FC<{ selectedWorkCategories: SelectedWorkCategorieEntity[] }> = ({ selectedWorkCategories }) => {
    return (
        <>
            {selectedWorkCategories?.map((category, i) => {
                return (
                    <Fragment key={i}>
                        <Stack spacing={1} justifyContent='center'>
                            <Heading size={'xs'}>{category?.label}</Heading>
                            <Box>
                                {category.items?.map((subCatData, i) => (
                                    <Text key={i} margin={0} fontSize={10}>{subCatData?.label}{i !== category.items.length - 1 && ','}</Text>
                                ))}
                            </Box>
                        </Stack>
                    </Fragment>
                )
            })}
        </>
    )
}

export default SelectedWorkCategoriesColumn;