import React, { useState } from 'react';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import '../DrawerContent/CreateBookingForm.scss';
import { Box, Button, Heading, Stack, Text } from '@chakra-ui/react';
import { useCompanyServices } from '../../hooks/api/companyInfo';
import Card from '../Card/Card';
import { Endpoints } from '../../hooks/api/endpoints';
import { axiosDelete, axiosPost } from '../../hooks/api/utils';
import { useCustomToast } from '../../hooks/useCustomToast';

export const CompanyServicesSelect: React.FC = () => {
    const [selectedServices, setSelectedServices] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const { companyServices, isLoadingCompanyServices } = useCompanyServices();
    const toast = useCustomToast();

    if (isLoadingCompanyServices) {
        return <div>Loading...</div>
    }

    async function handleSave() {
        try {
            setIsSaving(true);
            const newlyAddedServices = selectedServices.filter(service => !companyServices.companyServices.includes(service));
            await axiosPost(Endpoints.COMPANY_SERVICES, { services: newlyAddedServices })
            toast.successToast("Tjänster uppdaterade")
            setIsSaving(false);
        } catch (error) {
            toast.infoToast("Något gick fel när du försökte lägga till tjänster")
            setIsSaving(false);
        }
    }

    const onChange = (
        newValue: OnChangeValue<string, true>,
        actionMeta: ActionMeta<any>
    ) => {
        switch (actionMeta.action) {
            
            case 'remove-value':
                // Delete api
                removeService(actionMeta.removedValue.value)
                break;
            case 'select-option':
                setSelectedServices([...newValue])
                break;
        }

        setSelectedServices([...newValue])
    };

    async function removeService(service: string) {
        try {
            await axiosDelete(`${Endpoints.COMPANY_SERVICES}/delete/${service}`)
        } catch (error) {
            toast.infoToast("Något gick fel när du försökte ta bort en tjänst")
        }
    }

    return (
        <Card padding="lg">
            <Stack spacing={8}>
                <Stack spacing={2}>
                    <Heading size={'sm'} color='CaptionText'>Tjänster</Heading>
                    <Text fontSize={14}>Här kan du välja tjänster som du erbjuder för en snabb överblick för BilGo-användarna.</Text>
                </Stack>
                <Stack spacing={4}>
                    <Select
                        isMulti
                        name="services"
                        defaultValue={companyServices?.companyServices as any}
                        onChange={onChange}
                        isClearable={false}
                        options={companyServices?.availableServices}
                        placeholder={'Välj tjänster som du erbjuder'}
                        className="basic-multi-select-2"
                        classNamePrefix="select-2"
                    />
                </Stack>

                <Box marginLeft="12">
                    <Button
                        w="140px"
                        variant='primary'
                        size="sm"
                        isLoading={isSaving}
                        onClick={() => handleSave()}
                    >
                        Spara
                    </Button>
                </Box>
            </Stack>
        </Card>
    )
}