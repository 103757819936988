import { Box, useDisclosure, Popover, PopoverTrigger, HStack, Icon, PopoverContent, PopoverCloseButton, PopoverBody, Stack, Text, Image } from "@chakra-ui/react";
import { CiImageOn } from "react-icons/ci";
import { OfferEnquiry, Service } from "../../Marketplace/types";
import { getFirebaseImageUrl } from "../../../utils/firebase";
import { getReparationsCagetory, getServiceCategory } from "../../Bookings/WorkCategoryUtils";
import { Video } from "../../Video";

const OfferEnquiryPopover: React.FC<{ selectedOfferEnquiry?: OfferEnquiry }> = ({ selectedOfferEnquiry }) => {
    const { onClose: onClosePopover, onOpen: onOpenPopover, isOpen: isOpenPopover } = useDisclosure();

    function renderService() {
        if (!selectedOfferEnquiry?.service) {
            return null
        }
        const serviceObject: Service = selectedOfferEnquiry?.service;
        const gearboxTranslate = serviceObject.gearbox === 'manual' ? 'Manuell' : 'Automat';

        return (
            <Stack>
                {getServiceCategory(serviceObject)}
                <HStack pt={2} spacing={1}>
                    <Text fontSize={12}>Miltal</Text>
                    <Text fontSize={12} as={'b'}>{serviceObject.mileage} mil</Text>
                </HStack>
                <HStack spacing={1}>
                    <Text fontSize={12}>Växellåda</Text>
                    <Text fontSize={12} as={'b'}>{gearboxTranslate}</Text>
                </HStack>

                {serviceObject?.comment && (
                    <HStack>
                        <Text fontSize={12}>Övrig kommentar</Text>
                        <Text fontSize={12} as={'b'}>{serviceObject.comment}</Text>
                    </HStack>
                )}
            </Stack>
        )
    }

    return (
        <Popover
            placement='bottom-start'
            isOpen={isOpenPopover}
            onOpen={onOpenPopover}
            onClose={onClosePopover}
        >
            <PopoverTrigger>
                <HStack cursor={'pointer'} display={'inline-flex'}>
                    <Box _hover={{ color: 'blue.400' }} h={6} w={6} border={'1px solid'} borderColor={'blue.100'} bg={'gray.100'} borderRadius={4} top={'20%'} left={-8} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Icon as={CiImageOn} fontSize={20} color={'blue.600'} />
                    </Box>
                    <Text _hover={{ color: 'blue.400' }} fontSize={12} color={'blue.600'}>Visa offertförfrågan</Text>
                </HStack>
            </PopoverTrigger>

            <PopoverContent p={5} boxShadow={'base'} borderRadius={16} >
                <PopoverCloseButton m={1} />
                <PopoverBody p={0} mt={4}>
                    <Stack spacing={4}>
                        {selectedOfferEnquiry?.comment && (
                            <Stack>
                                <Text fontSize={14} as={'b'}>Kommentar</Text>
                                <Text fontSize={14}>{selectedOfferEnquiry?.comment}</Text>
                            </Stack>
                        )}
                        {getReparationsCagetory(selectedOfferEnquiry?.reparations)}
                        {renderService()}
                        {selectedOfferEnquiry?.reparations.map(rep => {
                            const isVideoAttachment = rep?.attachments[0]?.mimeType?.includes('video')
                            const isPdfAttachment = rep?.attachments[0]?.mimeType?.includes('pdf');
                            const attachmentFile = rep?.attachments[0]?.fileName;

                            if (rep.category === 'inspection' || rep.category === 'reparation') {
                                return (
                                    <Stack>
                                        {rep.description && (
                                            <Text fontSize={14}>{rep.description}</Text>
                                        )}
                                        {attachmentFile && isVideoAttachment ? (
                                            <Video selectedVideo={getFirebaseImageUrl(attachmentFile)} w={250} h={380} />
                                        ) : attachmentFile && isPdfAttachment ? (
                                            <embed src={getFirebaseImageUrl(attachmentFile)} width="100%" height="600px" type="application/pdf" />
                                        ) : attachmentFile && (
                                            <Image maxW={200} objectFit={'cover'} src={getFirebaseImageUrl(attachmentFile)} alt='Dan Abramov' />
                                        )}
                                    </Stack>
                                )
                            }
                        })}
                    </Stack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

export default OfferEnquiryPopover;