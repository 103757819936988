import {
    FormControl,
    Input,
    Button,
    Icon,
    InputGroup,
    InputLeftElement,
    Stack,
    Text,
    Box,
    Heading,
    HStack,
    InputRightAddon,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Alert,
    CloseButton,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import { MutableRefObject, useRef, useState } from 'react';
import { useFormContext } from "react-hook-form";
import { FaMoneyBillAlt, FaPaperclip, FaCheck } from 'react-icons/fa'
import { useCustomToast } from '../../hooks/useCustomToast';
import './CreateBookingForm.scss';
import { CarInfo } from '../../hooks/types';
import AddWorkDetailsForm from '../modals/AddOffer/AddWorkDetailsForm';

export default function CreateBookingForm({ tab, setTab, unFinishedWorkArticleError, setUnfinishedWorkArticleError, articleRowRef }: { tab?: string; setTab?: (val: string) => void; unFinishedWorkArticleError: boolean; setUnfinishedWorkArticleError?: (val: boolean) => void; articleRowRef?: MutableRefObject<any> }) {
    const { register, formState: { errors }, setValue, getValues } = useFormContext();
    const fileUploadInputRef = useRef<any>();
    const { infoToast } = useCustomToast();
    const [selectedCarInfo, setSelectedCarInfo] = useState<CarInfo | null>(null);
    const attachment = getValues('attachment')

    const renderWarningMessage = (errorMessage) => (
        <Text color={'red.500'} fontSize={12}>{errorMessage}</Text>
    )

    return (
        <Box pt={4}>
            <FormControl>
                <Stack spacing={8}>
                    <Box>
                        <Stack spacing={4}>
                            <Box>
                                {selectedCarInfo && (
                                    <Alert status='info' borderRadius={'md'} mt={2}>
                                        <HStack justifyContent={'space-between'} w={'100%'}>
                                            <HStack justifyContent={'space-between'}>
                                                <Heading size={'sm'}>{selectedCarInfo.licensePlate}</Heading>
                                                <Heading size={'xs'}>{selectedCarInfo.fullDescription}</Heading>
                                            </HStack>
                                            <CloseButton position={'relative'} onClick={() => {
                                                setSelectedCarInfo(null)
                                                setValue('regNo', null)
                                            }} />
                                        </HStack>
                                    </Alert>
                                )}
                            </Box>

                            <Box pt={2} pb={4}>
                                <Tabs variant='soft-rounded' size={'sm'} p={0} onChange={(index) => {
                                    const value = index === 0 ? 'form' : 'file';
                                    setTab(value);
                                    setValue('workDetailsFormat', value);
                                }}>
                                    {/* <TabList gap={4} pb={4}>
                                        <Tab _hover={{ color: 'teal.700' }}>Skapa arbetsdetaljer</Tab>
                                        <Tab _hover={{ color: 'teal.700' }}>Ladda upp arbetsdetaljer (.pdf)</Tab>
                                    </TabList> */}
                                    <TabPanels>
                                        <TabPanel p={0}>
                                            <Stack spacing={2} pt={2}>
                                                <AddWorkDetailsForm articleRowRef={articleRowRef} unFinishedWorkArticleError={unFinishedWorkArticleError} setUnfinishedWorkArticleError={setUnfinishedWorkArticleError} />
                                            </Stack>
                                        </TabPanel>
                                        <TabPanel p={0}>
                                            {tab === 'file' && (
                                                <Stack spacing={4}>
                                                    <Box>
                                                        <InputGroup >
                                                            <InputLeftElement
                                                                zIndex={0}
                                                                pointerEvents='none'
                                                                children={<Icon as={FaMoneyBillAlt} color='gray.300' />}
                                                            />
                                                            <Input
                                                                id='priceTotal'
                                                                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                                type='number'
                                                                placeholder='Beräknat totalpris för arbetet (SEK)'
                                                                {...register('priceTotal', {
                                                                    required: 'Du behöver fylla i arbetets kostnad',
                                                                })}
                                                            />
                                                            <InputRightAddon children='Kr' />
                                                        </InputGroup>
                                                        {renderWarningMessage(errors.priceTotal && errors.priceTotal.message)}
                                                    </Box>

                                                    <Box pt={2}>
                                                        <Heading size={'sm'}>(Valfritt) Ladda upp fullständiga arbetsdetaljer i .pdf-format</Heading>
                                                        <Text fontSize={12}>Från ditt arbetsorder-system (tex. AD Bildelar) kan du ladda upp ordern här som kunden därefter får tillgång till direkt i appen</Text>
                                                    </Box>

                                                    <Box pt={2}>
                                                        <HStack align={'center'} spacing={4}>
                                                            <Button disabled={attachment} colorScheme='teal' variant={'outline'} rightIcon={<Icon color="teal.500" as={FaPaperclip} />} size={'md'} onClick={(e) => {
                                                                fileUploadInputRef.current.click();
                                                            }}>
                                                                <input
                                                                    type="file"
                                                                    ref={fileUploadInputRef}
                                                                    onChange={e => {
                                                                        if (e.target.files[0].type === 'application/pdf') {
                                                                            setValue('attachment', e.target.files[0]);
                                                                            return;
                                                                        }

                                                                        return infoToast("Du kan endast ladda upp filer i .pdf-format")
                                                                    }}
                                                                    style={{ display: 'none' }}
                                                                />
                                                                Ladda upp arbetsdetaljer
                                                            </Button>
                                                            {attachment && (
                                                                <Icon color="teal.600" as={FaCheck} />
                                                            )}
                                                        </HStack>
                                                    </Box>

                                                    {attachment && (
                                                        <Accordion allowToggle>
                                                            <AccordionItem border={'none'} background='gray.50' borderRadius={'md'} _hover={{ background: 'gray.100', borderRadius: 'md' }}>
                                                                <AccordionButton>
                                                                    <HStack width={'100%'} justifyContent={'space-between'}>
                                                                        <HStack>
                                                                            <Icon color="teal.500" as={FaPaperclip} />
                                                                            <Text>{attachment?.name}</Text>
                                                                        </HStack>
                                                                        <AccordionIcon />
                                                                    </HStack>
                                                                </AccordionButton>
                                                                <AccordionPanel pb={4}>
                                                                    <embed src={URL.createObjectURL(attachment)} height={300} type={'application/pdf'} />
                                                                </AccordionPanel>
                                                            </AccordionItem>
                                                        </Accordion>
                                                    )}
                                                </Stack>
                                            )}
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Box>
                        </Stack>
                    </Box>

                </Stack>
            </FormControl>
        </Box>
    )
}