import React from "react";
import { Box, Text, Heading, HStack } from "@chakra-ui/react";
import 'moment/locale/sv'
import { OfferEnquiry } from "./types";

const Service: React.FC<{ enquiry: OfferEnquiry }> = ({ enquiry }) => {
    if (!enquiry.service) {
        return null;
    }

    const { mileage, gearbox, selectedServiceCategories } = enquiry.service;
    const gearboxTranslate = gearbox === 'manual' ? 'Manuell' : 'Automat';
    const fontSize = [11, 14]
    return (
        <Box mb={[4, 4, 0, 0]}>
            <HStack spacing={1} width={'100%'}>
                {/* TODO: Denna titeln borde komma från backend och vara dynamisk i framtiden */}
                <Text as={'b'} fontSize={fontSize}>Service och underhåll</Text>
                <HStack spacing={1}>
                    <Text fontSize={fontSize}>({mileage}</Text>
                    <Text fontSize={fontSize}>mil</Text>
                    <Text fontSize={fontSize}>-</Text>
                    <Text fontSize={fontSize}>{gearboxTranslate} växellåda)</Text>
                </HStack>
            </HStack>
            {selectedServiceCategories?.map((category, i) => {
                return (
                    <Box pl={6} key={i}>
                        <ul>
                            <li>
                                <Text fontSize={fontSize}>{category.label}</Text>
                            </li>
                        </ul>
                    </Box>
                )
            })}
        </Box>
    )
}

export default Service;