import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, ModalHeader, useMediaQuery, Box, Stack, Heading, Text, Image, Highlight } from "@chakra-ui/react";
import React from "react";
import work1 from '../../assets/guide-arbete-1.png';
import work2 from '../../assets/guide-arbete-3.png';

interface WorkOfferHelpModalProps {
    isOpenModal: boolean;
    onCloseModal: () => void;

}
const WorkOfferHelpModal: React.FC<WorkOfferHelpModalProps> = ({ isOpenModal, onCloseModal }) => {

    const [isMobile] = useMediaQuery('(max-width: 492px)')

    const renderModalBody = () => {
        return (
            <Box pb={8}>
                <Stack spacing={8}>
                    <Stack spacing={4}>
                        <Heading size={'sm'}>Guide för nytt arbete</Heading>
                        <Stack>
                            <Box>
                                <Text fontSize={14}><Highlight query={'1.'} styles={{ fontWeight: 'bold' }}>1. </Highlight> <Highlight query={["plus-knappen", "Kom ihåg arbetet"]} styles={{ color: 'blue.600', fontWeight: 'bold' }}> Börja med att välja det arbetet som du ska utföra åt kunden och tryck på plus-knappen till höger om fältet för att lägga till det. Hittar du inte arbetet som du ska utföra i listan så kan du själv alltid lägga till det genom att skriva vad du ska göra och sedan trycka på knappen Kom ihåg arbetet som sparar arbetet åt dig så du kan använda det igen när du vill. Du kan lägga till flera arbeten om kunden ska göra flera arbeten. Den totala arbetskostnaden lägger du senare till där det står "Arbetskostnad i timmen"</Highlight></Text>
                                <Image w={'70%'} src={work1} />
                            </Box>

                            <Box>
                                <Text fontSize={14}><Highlight query={'2.'} styles={{ fontWeight: 'bold' }}>2. </Highlight> <Highlight query={"Lägg till reservdelar/material +"} styles={{ color: 'blue.600', fontWeight: 'bold' }}
                                > När du valt arbetet du ska utföra så trycker du på Lägg till reservdelar/material + för att lägga till de reservdelar eller material som behövs. Du kan välja mellan olika enheter som passar beroende på materialet som du vill specificera.
                                    Observera att beräkningen av priset är pris * enhet. Exempelvis 2 meter plåt som kostar 1000kr per meter blir 1000kr i pris och 2 meter i enhet vilket motsvarar ett prisbelopp på 2000kr (1000 x 2).
                                </Highlight>
                                </Text>
                                <Image w={'70%'} src={work2} />
                            </Box>
                        </Stack>
                    </Stack>

                    <Stack spacing={4}>
                        <Heading size={'sm'}>Varför måste jag specificera arbetet?</Heading>
                        <Stack>
                            <Text fontSize={14}>Du kan i dagsläget inte endast skriva in reservdelar/material, utan du behöver också specificera vilket arbete du ska utföra. Detta är viktigt så att kunden vet vilket arbete du ska utföra samt att du själv vet vad du gjort för arbete åt kunden. Systemet kan också då hjälpa dig veta vad din kund gjort för arbeten hos dig historiskt och förhindra eventuella missförstånd mellan dig och din kund.</Text>
                        </Stack>
                    </Stack>

                    <Stack spacing={4}>
                        <Heading size={'sm'}>Arbteskostnad</Heading>
                        <Stack>
                            <Text fontSize={14}><Highlight query={"Arbetskostnad i timmen"} styles={{ bg: 'gray.100', px: 1 }}>Oavsett hur många arbeten du ska utföra åt kunden så är det under Arbetskostnad i timmen som du specificerar den totala arbetskostnaden för alla arbeten. Du väljer helt själv vad du vill ta för arbetskostnad. Antalet timmar visas inte utåt mot kunden i dagsläget, utan kunden får bara veta den totala arbetskostnaden.</Highlight></Text>
                        </Stack>
                    </Stack>
                </Stack>
            </Box >
        )
    }

    return (
        <>
            <Modal isOpen={isOpenModal} onClose={() => {
                onCloseModal()
            }} size={isMobile ? 'full' : '2xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontSize={16}>Hjälp guide</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {renderModalBody()}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default WorkOfferHelpModal;
