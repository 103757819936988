import React, { useState } from "react";
import {
    Badge,
    Box,
    Flex,
    Heading,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Stack,
    Table,
    TableCaption,
    Tbody,
    Td,
    Text,
    Tr,
    useDisclosure,
    useMediaQuery,

} from '@chakra-ui/react'
import {  FaStream } from "react-icons/fa";
import moment from "moment";
import { useServiceMessagesV2 } from "../../hooks/api/booking";
import { useParams } from "react-router-dom";
import noBookingImage from '../../common/undraw_automobile_repair.svg'
import AdditionalWorkSummaryItem from "../modals/AdditionalWork/AdditionalWorkSummaryItem";

// TODO: bookingSource should be a enum typed - now on multiple places
const Activities: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [clickedMessage, setClickedMessage]: any = useState(null);
    const [isMobile] = useMediaQuery('(max-width: 492px)')

    let params = useParams();
    const { bookingId } = params; // Använd bookingId för att hämta relevanta meddelanden för bokningen
    const { messages, isLoading } = useServiceMessagesV2(bookingId);

    function renderStatusForAdditionalWork(selectedAction: string | null, minified?: boolean) {
        return (
            <>
                {selectedAction ? (
                    selectedAction.toLowerCase() === 'accepted' ? (
                        <Badge fontSize={minified ? 10 : 12} px={2} py={.5} rounded={'full'} colorScheme={'green'}>{minified ? 'Accepterad' : 'Kunden har accepterat offert'}</Badge>
                    ) : (
                        <Badge fontSize={minified ? 10 : 12} px={2} py={.5} rounded={'full'} colorScheme={'orange'}>{minified ? 'Avböjd' : 'Kunden har avböjt offert'}</Badge>

                    )
                ) : (
                    <Badge fontSize={minified ? 10 : 12} px={2} py={.5} rounded={'full'} colorScheme={'blue'}>{minified ? 'Ej besvarad' : 'Kunden har ännu inte svarat'}</Badge>
                )}
            </>
        )
    }

    const renderLoader = () => {
        if (isLoading) {
            return (
                <Tr display="flex" justifyContent="center" alignItems="center">
                    <Td>
                        <Spinner
                            thickness='2px'
                            speed='.3s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='lg'
                        />
                    </Td>
                </Tr>
            )
        }
    }

    return (
        <Box backgroundColor="white" boxShadow="md" borderRadius="2xl">
            <Stack spacing={4} padding={6}>
                <Flex justifyContent="space-between" alignItems="center">
                    <Heading size={'sm'} color={'CaptionText'}>Tilläggsarbeten</Heading>
                    <Icon as={FaStream} color={'gray.700'} />
                </Flex>
                <Box>
                    <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader pb={0} fontSize={16}>Detaljer</ModalHeader>
                            <ModalCloseButton margin={2} bg='white' />
                            <ModalBody pl={6} pr={6} pb={6} pt={0} display="flex">
                                <AdditionalWorkSummaryItem files={clickedMessage?.files} additionalWorkOffers={clickedMessage?.additionalWorkOffers} />
                            </ModalBody>
                        </ModalContent>
                    </Modal>

                    {(!messages || messages.length === 0) && !isLoading && (
                        <Stack mt={2} justifyContent={'center'} width={'100%'} align='center' p={6} bg={'gray.100'} borderRadius={16}>
                            <Text fontSize="12px" textAlign={'center'}>{'Det finns inga tilläggsarbeten för det här arbetet.'}</Text>
                            <img src={noBookingImage} width={isMobile ? 80 : 120} alt="bild som visas när data saknas" />
                        </Stack>
                    )}
                    <Table variant='simple'>
                        <TableCaption>Tilläggsarbeten för det här besöket</TableCaption>
                        <Tbody display={'flex'} gap={2} flexDir={'column'} w={'100%'} alignItems={'space-between'}>
                            {renderLoader()}
                            {messages?.map((message, i) => {
                                const { imageToken, createdAt } = message.singleMessage;
                                return (
                                    <Tr borderRadius={16} bg={'gray.100'} _hover={{ bg: 'gray.200' }} key={i} cursor="pointer" onClick={() => {
                                        setClickedMessage({ singleMessage: message.singleMessage, additionalWorkOffers: message.additionalWorkOffers, files: message.files })
                                        onOpen()
                                    }}>
                                        <Td border={'none'} w={'100vw'} p={4}>
                                            <Flex justifyContent="space-between">
                                                <Box>
                                                    <Heading size={'xs'}>Tilläggsarbete</Heading>
                                                    <Text fontSize="12px">{moment(createdAt, 'YYYY-MM-DDTHH:mm:ss').fromNow()}</Text>
                                                </Box>
                                                <Stack align={'flex-end'}>
                                                    <Box>
                                                        {renderStatusForAdditionalWork(message.additionalWorkOffers.find(work => work.selectedAction !== null)?.selectedAction, true)}
                                                    </Box>
                                                    <Text as="em" fontSize="10px">{`Skickad ${moment(createdAt, 'YYYY-MM-DDTHH:mm:ss').format('LLLL')}`}</Text>
                                                </Stack>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </Box>
            </Stack>
        </Box>

    )
}

export default Activities;