import { Box, Center, Divider, Heading, HStack, Stack, Stat, StatArrow, StatHelpText, Text, useMediaQuery } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { BarChart as BarChartRecharts, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import { useBookingStatistics } from '../../../hooks/api/statistics';
import isEmpty from 'lodash/isEmpty';
import noChartImage from '../../../common/undraw_Data_re_80ws.png';

const BarChart: React.FC = () => {

    const { bookingStatistics, isLoading, isError } = useBookingStatistics()
    const [isMobileScreen] = useMediaQuery('(max-width: 430px)')

    if (isLoading) {
        return null;
    }

    const charHeight = 240;

    function renderEmptyMessage() {
        return (
            <Box bg={'gray.50'} borderRadius={'md'} width={isMobileScreen ? 'auto' : 260}>
                <Center padding={4}>
                    <Text fontSize={'14px'}>
                        Det finns för tillfället ingen statistik att visa. När du slutfört din första bokning via plattformen kommer statistik att börja genereras omgående.
                    </Text>
                </Center>
            </Box>
        )
    }

    if (isEmpty(bookingStatistics)) {
        return (
            <Box height={isMobileScreen ? 'auto' : charHeight} width={'100%'}>
                <Stack padding={4} justifyContent={'center'} alignItems={'center'}>
                    {!isMobileScreen && (
                        <HStack>
                            <img src={noChartImage} width={220} alt="bild som visas när data för grafer saknas" />
                            <HStack gap={4}>
                                <Divider orientation='vertical' height={150} />
                                {renderEmptyMessage()}
                            </HStack>
                        </HStack>
                    )}
                    {isMobileScreen && (
                        <Stack spacing={4} align={'center'}>
                            <img src={noChartImage} width={220} alt="bild som visas när data för grafer saknas" />
                            <Divider />
                            {renderEmptyMessage()}
                        </Stack>
                    )}
                </Stack>
            </Box>
        )
    }

    return (
        <ResponsiveContainer width={'100%'} height={charHeight}>
            <BarChartRecharts
                margin={{
                    left: 0
                }}
                //@ts-ignore
                data={bookingStatistics}
            >
                <CartesianGrid vertical={false} />
                {/* @ts-ignore */}
                <XAxis tickFormatter={(val) => moment(`${val}`, 'M').format('MMM')} padding={{ left: 12 }} tickMargin={8} dataKey="month" tick={true} fontSize={'10px'} />
                <YAxis allowDecimals={false} width={isMobileScreen ? 22 : 32} fontSize={isMobileScreen ? '10px' : '14px'} />
                <Tooltip cursor={false} content={(props) => {
                    const { active, payload } = props;

                    if (!active && !payload || payload?.length === 0) {
                        return null;
                    }
                    const amount = payload && payload[0]?.payload?.totalBookingsCount || null;
                    const month = payload && payload[0]?.payload?.month || null;
                    const diff = payload && payload[0]?.payload?.diffFromPrevMonth || null;
                    if (!amount && !month && !diff) {
                        return null
                    }

                    return (
                        <Box width={160} boxShadow="xl" height="auto" rounded="md" background="white" color={'teal.700'} border={'1px solid gray'}>
                            <Box padding={4}>
                                <Stack spacing={4}>
                                    <Text>{moment(`${month}`, 'M').format('MMMM')}</Text>
                                    <HStack alignItems={'end'} justifyContent={'space-between'}>
                                        <Stack>
                                            <Heading size={'xs'}>{'Avslutade arbeten'}</Heading>
                                            <Heading size={'lg'}>{amount}</Heading>
                                        </Stack>
                                        <Stat flex={'none'}>
                                            <StatHelpText fontSize={'lg'} color={'teal.700'}>
                                                <StatArrow type={diff < 0 ? 'decrease' : 'increase'} />
                                                {diff || 0}
                                            </StatHelpText>
                                        </Stat>
                                    </HStack>
                                </Stack>
                            </Box>
                        </Box>
                    )
                }} />
                <Bar dataKey="totalBookingsCount" barSize={32}>
                    {bookingStatistics?.map((entry, index) => {
                        const isCurrentBar = bookingStatistics.length -1 === index;
                        if (isCurrentBar) {
                            return <Cell key={`cell-${index}`} fill={'#2c7a7b'} radius={8} />;
                        }
                        return <Cell key={`cell-${index}`} fill={'#553C9A'} radius={8} />;
                    })}
                </Bar>
            </BarChartRecharts >
        </ResponsiveContainer>
    )
}

export default BarChart;