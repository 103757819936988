export const Endpoints = {
    COMPANY_INFO: '/api/company-info/info-v1',
    COMPANY_ASSETS: '/api/company-info/assets-v1',
    COMPANY_OPENING_HOURS: '/api/company-info/opening_hours-v1',
    BOOKING_SCHEDULE: '/api/booking-schedule/schedule-v1',
    BOOKINGS: '/api/bookings/bookings-v1/company',
    OFFER_ENQUIRY: '/api/offer-request/enquiry-v1',
    COMPANY_SERVICES: '/api/company-info/company_services-v1',
    FEATURES: '/api/features/features-v1',
    EVENTS_V1: '/api/events/events-v1',
    COMPANY_SETTINGS_V1: '/api/company-settings/company-settings-v1',
    ERROR_REPORT_V1: '/api/external-messaging/error-report-v1'
}