import { Box, Center, Heading, HStack, Image, Stack, Text, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect } from "react";
import appicon from '../assets/appicon.png'
import rightImage from '../assets/pranay-pareek-_k_J5lPCq_Q-unsplash.jpg'
import SignupForm from "../components/SignUpForm";

const SignUp: React.FC = () => {

    useEffect(() => {
        // Always remove any signs of logged in state here
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('eventNotificationExpirationTime');
    }, [])

    const [isMobile] = useMediaQuery('(max-width: 480px)')

    function renderInformation() {
        return (
            <Box padding={8} pl={12}>
                <Stack spacing={16} maxW={[450, 450, 450, 500]}>
                    <HStack spacing={4}>
                        <Center p={6} bg={'teal.700'} borderRadius={'full'} w={16} h={16}>
                            <Heading color={'white'}>1</Heading>
                        </Center>
                        <Stack>
                            <Heading size={'xs'}>Registrera din verkstad</Heading>
                            <Text fontSize={14}>Använd verkstadens e-postadress och ett starkt lösenord.</Text>
                        </Stack>
                    </HStack>
                    <HStack spacing={4}>
                        <Center p={6} bg={'teal.700'} borderRadius={'full'} w={16} h={16}>
                            <Heading color={'white'}>2</Heading>
                        </Center>
                        <Stack>
                            <Heading size={'xs'}>Vi kontaktar dig</Heading>
                            <Text fontSize={14}>För att bekräfta att du äger verkstaden tar vi kontakt med dig inom 1-3 arbetsdagar.</Text>
                        </Stack>
                    </HStack>
                    <HStack spacing={4}>
                        <Center p={6} bg={'teal.700'} borderRadius={'full'} w={16} h={16}>
                            <Heading color={'white'}>3</Heading>
                        </Center>
                        <Stack>
                            <Heading size={'xs'}>Kom igång med BilGo!</Heading>
                            <Text fontSize={14}>När vi bekräftat din verkstad är du redo att komma igång och ta ditt företag till nya nivåer.</Text>
                        </Stack>
                    </HStack>
                </Stack>
            </Box>
        )
    }

    if (isMobile) {
        return (
            <Box bg={'white'}>
                <Box p={4}>

                    <a href="https://www.bilgo.se">
                        <Image cursor={'pointer'} _hover={{ opacity: .9 }} h={12} w={12} borderRadius='full' objectFit='cover' src={appicon} />
                    </a>
                </Box>
                <Box m={4} mt={4} borderRadius={'2xl'} padding={8} shadow={'2xl'} bg='white' zIndex={999}>
                    <SignupForm />
                </Box>
                <Box pt={4} pb={8}>
                    {renderInformation()}
                </Box>
            </Box>
        )
    }

    return (
        <HStack bg={'white'} pos={'relative'}>
            <Box w={['100%', '100%', '55%', '55%']}>
                <a href="https://www.bilgo.se">
                    <Image cursor={'pointer'} _hover={{ opacity: .9 }} pos={'absolute'} top={4} left={8} h={12} w={12} borderRadius='full' objectFit='cover' src={appicon} />
                </a>
                {!isMobile && (
                    renderInformation()
                )}
            </Box>
            <Box borderRadius={'2xl'} padding={8} shadow={'2xl'} bg='white' pos={'absolute'} top={'50%'} left={'54%'} transform={'translate(-50%, -50%)'} zIndex={999}>
                <SignupForm />
            </Box>
            <Box w={['0%', '0%', '45%', '45%']} p={0} m={0}>
                <Image p={0} m={0} height={'100vh'} w='100vw' objectFit='cover' src={rightImage} />
            </Box>
        </HStack>

    )
}

// https://framerbook.com/animation/example-animations/4-repeat/

export default SignUp;