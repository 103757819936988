import { AdditionalWork } from "../hooks/api/booking"

const bookingStatusColor = {
    "waiting": "purple",
    "offerPending": "yellow", // (Offert har skickats -> Status på servicen tex: "Inte påbörjad") - GUL
    "offerWaiting": "purple", //(Offert tillagd men inte skickad -> Status på servicen tex: "Inte påbörjad") - LILA
    "offerMissing": "orange", //(Offert inte tillagd -> Status på servicen tex: "Inte påbörjad") - ORANGE
    "offerAccepted": "green", // (Offert inte tillagd -> Status på servicen tex: "Inte påbörjad") - GRÖN
    "offerDeclined": "red", // (Offert inte tillagd -> Status på servicen tex: "Inte påbörjad") - RÖD LJUS
    "bookingDeclined": "red", // (Hela bokningen avböjd av företagaren) - RÖD MÖRK
}

const workStatusColor = {
    "waiting": "purple",
    "ongoing": "blue",
    "finished": "green",
    "dismissed": "red",
    "canceled": "red",
    "delayed": "yellow"
}

// There is one in core-backend aswell - should perhaps only be there and not be used here bur rather just formatted in backend and sent back to avoid changes in multiple places.
const severityStatus = {
    'critical': {
        label: "Kritiskt",
        color: 'red'
    },
    'recommended': {
        label: "Rekommenderad",
        color: 'blue'
    },
    'visual': {
        label: 'Endast visuellt fel',
        color: 'green'
    },
}

export const getMessageStatus = (additionalWork: AdditionalWork) => {
    if (additionalWork.selectedAction === 'accepted') {
        return {
            label: 'Accepterad',
            color: 'green'
        }
    }

    if (additionalWork.selectedAction === 'rejected') {
        return {
            label: 'Nekad',
            color: 'red'
        }
    }

    return {
        label: 'Obesvarad',
        color: 'messenger'
    }
}

export const getSourceType = (bookingSource: string) => {
    if (bookingSource === 'platform') {
        return {
            label: 'Plattformen',
            badgeColor: 'blue'
        }
    }
    if (bookingSource === 'mobile_app') {
        return {
            label: 'Mobilappen',
            badgeColor: 'green'
        }
    }
    return {
        label: '',
        badgeColor: 'blue'
    }
}

export const getSeverityStatus = (statusKey) => severityStatus[statusKey];
export const getBookingStatusColor = (statusKey) => bookingStatusColor[statusKey];
export const getWorkStatusColor = (statusKey) => workStatusColor[statusKey];
