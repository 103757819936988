import { Icon } from "@chakra-ui/icons";
import { Box, Button, Heading, Input, InputGroup, InputLeftElement, Stack, useToast, Text, HStack } from "@chakra-ui/react";
import Lottie from "lottie-react";
import React, { useState } from "react";
import { FaBuilding, FaEnvelope, FaLock, FaPhoneAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { axiosPostPublic } from "../hooks/api/utils";
import SuccessAnimation from '../Lotties/Animation - 1705355399319.json';
import { validateCompanyName, validateEmail, validatePassword, validatePhoneNo } from "./Validation";

const SignupForm: React.FC = () => {
    const [email, setEmail] = useState(null)
    const [companyPhoneNo, setCompanyPhoneNo] = useState(null)
    const [password, setPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [companyName, setCompanyName] = useState(null)
    const [isLoadingSignup, setIsLoadingSignup] = useState(null)
    const [hasCompletedSignup, setHasCompletedSignup] = useState(null)

    let navigate = useNavigate();
    const toast = useToast();

    const promptErrorToast = (message?: string | undefined) => {
        return toast({
            title: 'Registreringen misslyckades',
            description: message || "Felaktig epost eller lösenord",
            status: 'info',
            duration: 5000,
            position: 'top-left',
            isClosable: true,
        })
    }

    const handleSignup = async () => {
        const { pwError, pwMessage } = validatePassword(password, confirmPassword);
        const { emailError, emailMessage } = validateEmail(email);
        const { companyNameError, companyNameMessage } = validateCompanyName(companyName);
        const { phoneNoError, companyPhoneNoMessage } = validatePhoneNo(companyPhoneNo);
        if (pwError || emailError || companyNameError || phoneNoError) {
            return promptErrorToast(pwMessage || emailMessage || companyNameMessage || companyPhoneNoMessage)
        }

        setIsLoadingSignup(true)
        try {
            await axiosPostPublic('/api/user-public/users-v1', {
                email,
                password,
                companyName,
                companyPhoneNo
            })
            setHasCompletedSignup(true)
            setIsLoadingSignup(false)
        } catch (error) {
            setIsLoadingSignup(false)
            promptErrorToast();
        }
    }

    const handleOnChange = (e, name) => {
        if (name === 'email') {
            setEmail(e.target.value)
        }
        if (name === 'password') {
            setPassword(e.target.value)
        }
        if (name === 'confirmPassword') {
            setConfirmPassword(e.target.value)
        }
        if (name === 'companyName') {
            setCompanyName(e.target.value)
        }
        if (name === 'companyPhoneNo') {
            setCompanyPhoneNo(e.target.value)
        }
    }

    function renderFormBody() {
        return (
            <Stack spacing={16} w={'100%'}>
                <Heading size={'md'} color={'blackAlpha.800'}>Registrera din verkstad</Heading>
                <Box pt={0}>
                    <Stack spacing={6}>
                        <InputGroup >
                            <InputLeftElement
                                pointerEvents='none'
                                children={<Icon as={FaBuilding} color='gray.300' />}
                            />
                            <Input placeholder='Företagsnamn' onChange={e => handleOnChange(e, 'companyName')} />
                        </InputGroup>

                        <InputGroup >
                            <InputLeftElement
                                pointerEvents='none'
                                children={<Icon as={FaPhoneAlt} color='gray.300' />}
                            />
                            <Input type='number' placeholder='Telefonnummer' onChange={e => handleOnChange(e, 'companyPhoneNo')} />
                        </InputGroup>

                        <InputGroup >
                            <InputLeftElement
                                pointerEvents='none'
                                children={<Icon as={FaEnvelope} color='gray.300' />}
                            />
                            <Input type='email' placeholder='E-post' onChange={e => handleOnChange(e, 'email')} />
                        </InputGroup>

                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<Icon as={FaLock} color='gray.300' />}
                            />
                            <Input type="password" placeholder='Lösenord' onChange={e => handleOnChange(e, 'password')} />
                        </InputGroup>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<Icon as={FaLock} color='gray.300' />}
                            />
                            <Input type="password" placeholder='Bekräfta lösenordet' onChange={e => handleOnChange(e, 'confirmPassword')} />
                        </InputGroup>

                        <Button isLoading={isLoadingSignup} variant={'primary'} colorScheme='teal' onClick={() => handleSignup()}>
                            Skapa konto
                        </Button>
                        <HStack align={'center'}>
                            <Text fontSize={14}>Har du ett konto?</Text>
                            <Button variant={'link'} size='sm' colorScheme='teal' onClick={() => navigate('/login')}>
                                Logga in
                            </Button>
                        </HStack>
                    </Stack>
                </Box>
            </Stack>
        )
    }

    return (
        <Box width={['100%', 400, 400, 400]} height={['100%', 580, 580, 580]} borderRadius={24} p={4}>
            <Stack alignItems='center' height="100%" width="100%" spacing={16} textAlign='left'>
                {hasCompletedSignup ? (
                    <Stack align='center'>
                        <Lottie animationData={SuccessAnimation} loop={false} />
                        <Heading size={'sm'} textAlign='center'>Vi har mottagit en registrering från {companyName}!</Heading>
                        <Text fontSize={14} textAlign='center'>Tack för din registrering på BilGo, nu kommer vi höra av oss till er inom ett par dagar och hjälpa er att komma igång.</Text>
                    </Stack>
                ) : (
                    renderFormBody()
                )}
            </Stack>
        </Box>
    )
}

export default SignupForm;