import { Badge, Box, Heading, HStack, Icon, SkeletonText, Stack, Table, Tbody, Td, Th, Text, Thead, TableCaption, Tr, useDisclosure, Grid, GridItem, IconButton, Menu, MenuButton, MenuItem, MenuList, useMediaQuery, Divider, Tooltip, Tab, TabList, TabPanel, TabPanels, Tabs, TabIndicator, chakra, shouldForwardProp, Center } from "@chakra-ui/react";
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { FaBolt, FaChartBar, FaEllipsisH, FaExclamation, FaRocket } from "react-icons/fa";
import { getSourceType, getWorkStatusColor } from "../common/utils";
import SelectedWorkCategoriesColumn from "../components/Bookings/SelectedWorkCategories";
import LicensePlate from "../components/LicensePlate";
import AdditionalWorkModal from "../components/modals/AdditionalWork/AdditionalWork";
import AdditionalWorkSummaryModal from "../components/modals/AdditionalWork/AdditionalWorkSummary";
import { Booking, useCurrentUnfinishedBookings } from "../hooks/api/booking";
import { axiosPost, axiosPut } from "../hooks/api/utils";
import BaseLayout from "./BaseLayout";
import { AddIcon, CheckIcon, InfoIcon } from "@chakra-ui/icons";
import { Spinner } from "../components/Spinner";
import { useCustomToast } from "../hooks/useCustomToast";
import BarChart from "../components/Statistics/Charts/BarChart";
import noBookingImage from '../common/undraw_automobile_repair.svg';
import { useBookingStatistics } from "../hooks/api/statistics";
import AdditionalWorkStats from "../components/Statistics/AdditionalWorkStats";
import CancelBookingModal from "../components/modals/CancelBooking";
import { useNavigate, useSearchParams } from "react-router-dom";
import { motion, isValidMotionProp } from 'framer-motion';
import FinishBookingModal from "../components/modals/FinishBookingModal";
import ReactGA from "react-ga4";
import { useCompanyInfo } from "../hooks/api/companyInfo";

const ChakraIcon = chakra(motion.div, {
    /**
     * Allow motion props and non-Chakra props to be forwarded.
     */
    shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const Home: React.FC = () => {
    const { bookings: unfinishedBookings, mutateBooking: mutateCurrentUnfinishedBookings, isValidatingBookings: isValidaingCurrentUnfinishedBookings, isLoading } = useCurrentUnfinishedBookings();
    const { mutateBookingStatistics } = useBookingStatistics();
    const navigate = useNavigate();

    useEffect(() => {
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: "/landingpage", title: "Landing Page" });
    }, [])

    const timestamp = new Date();
    const today = moment(timestamp).format('YYYY-MM-DD');

    const [isMobile] = useMediaQuery('(max-width: 440px)')
    const [isLargeScreen] = useMediaQuery('(min-width: 1920px)')
    const [isLoadingCancelBooking, setIsLoadingCancelBooking] = useState(false);
    const [isLoadingFinishBooking, setIsLoadingFinishBooking] = useState(false);

    const [searchParams] = useSearchParams();
    const highlightedBookingId = searchParams.get('highlight');

    const { companyInfo } = useCompanyInfo();

    function isBookingDelayed(booking: Booking) {
        return booking.updatedAcceptedDate ? moment(booking.updatedAcceptedDate).isBefore(today)
            : moment(booking.pickupDate).isBefore(today)
    }

    const { isOpen: isOpenAddWorkModal, onOpen: onOpenAddWorkModal, onClose: onCloseAddWorkModal } = useDisclosure();
    const { isOpen: isOpenAdditionalWorkModal, onOpen: onOpenAdditionalWorkModal, onClose: onCloseAdditionalWorkModal } = useDisclosure();
    const { isOpen: isOpenCancelBookingModal, onOpen: onOpenCancelBookingModal, onClose: onCloseCancelBookingModal } = useDisclosure();
    const { isOpen: isOpenFinishModal, onClose: onCloseFinishModal, onOpen: onOpenFinishModal } = useDisclosure();

    const bookingRef: React.MutableRefObject<Booking | null> = useRef(null);
    const bookingToLoad: React.MutableRefObject<string | null> = useRef(null);

    const bookingsToDisplay = Array.from(new Set([...unfinishedBookings || []]));

    const { successToast, errorToast } = useCustomToast({ duration: 5000 });

    const handleFinishJob = async (payload: any) => {
        const { smsConfirmation } = payload;
        
        setIsLoadingFinishBooking(true)
        try {
            // include booking source to know whether to send email or not
            // Actually it should not matter, we should send email in both cases - make change in backend to send email but include 
            // bookingSource to adjust the email content
            await axiosPut(`/api/bookings/bookings-v1/${bookingRef.current.bookingId}/status/finished?bookingSource=${bookingRef.current.bookingSource}`, {
                companyName: companyInfo.companyName,
                email: bookingRef.current.email,
                companyLogo: companyInfo.companyLogo,
                companyAddress: companyInfo.fullAddress,
                companyPhoneNo: companyInfo.companyPhoneNo,
                companyEmail: companyInfo.companyEmail,
                regNo: bookingRef.current.regNo,
                fullName: bookingRef.current.fullName,
                phoneNo: bookingRef.current.phoneNo,
                smsConfirmation
            })

            const payload = {
                userId: bookingRef.current.clientId,
                source: 'home_web_booking_start',
                messageTemplate: {
                    sound: 'default',
                    title: 'Din bil är färdig att hämtas',
                    body: `Hälsningar ${companyInfo.companyName} 👋`,
                    data: { withSome: 'data' },
                }
            }

            if (bookingRef.current.bookingSource === 'mobile_app') {
                await axiosPost(`/api/user/push-notifications-v1/notification/send`, payload)
                    .catch(err => {
                        // Log somewhere that there is an error when sending notification -> enables manually re-sending
                    })
            }

            bookingToLoad.current = bookingRef.current.bookingId;
            await mutateCurrentUnfinishedBookings()
            await mutateBookingStatistics();
            setIsLoadingFinishBooking(false)
            onCloseFinishModal();
            successToast(`Arbetet har avslutats och kunden har meddelats att bilen är färdig att hämtas`)

        } catch (error) {
            setIsLoadingFinishBooking(false)
            errorToast('Något gick fel, försök igen snart eller skriv till oss på chatten så hjälper vi dig.')
        }
    }

    const renderMenuItems = (booking: Booking, isHighlightedAdditionalWork?: boolean) => {
        return (
            <Menu>
                <MenuButton disabled={booking.status.key === 'finished'} variant='outline' as={IconButton} icon={<Icon as={FaEllipsisH} color={'purple.600'} />} py={2} />
                <MenuList fontSize={'lg'}>
                    {(booking.status.key === 'ongoing' || booking.status.key === 'waiting') && (
                        <>
                            <MenuItem
                                icon={
                                    <HStack align={'center'} justify='center' borderRadius={'full'} bg='blue.100' h={7} w={7}>
                                        <AddIcon fontSize={'lg'} />
                                    </HStack>
                                }
                                h={12}
                                onClick={() => {
                                    bookingRef.current = booking;
                                    onOpenAddWorkModal()
                                }}
                            >
                                Tilläggsarbete
                            </MenuItem>
                            <MenuItem
                                icon={
                                    <HStack align={'center'} justify='center' borderRadius={'full'} h={7} w={7}>
                                        <InfoIcon fontSize={'lg'} />
                                    </HStack>
                                }
                                h={12}
                                onClick={() => {
                                    return navigate(`/bookings/${booking.bookingId}`, {
                                        state: { ...booking, updateData: isHighlightedAdditionalWork ? true : false },
                                    })
                                }}
                            >
                                Visa detaljer
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                icon={
                                    <HStack align={'center'} justify='center' borderRadius={'full'} bg='green.100' h={7} w={7}>
                                        <CheckIcon fontSize={'sm'} />
                                    </HStack>
                                }
                                h={12}
                                onClick={() => {
                                    bookingRef.current = booking;
                                    onOpenFinishModal()
                                }}>
                                Avsluta arbete
                            </MenuItem>
                        </>
                    )}
                </MenuList>
            </Menu>
        )
    }
    /**
     * TODO: 
     * - Visa per bokning status för tilläggsarbete? 
     *
     */
    function renderMobileViewTodaysBookings(bookingsList: Booking[]) {

        if (bookingsList?.length === 0 && !isLoading) {
            return (
                <Box padding={4}>
                    <Stack spacing={4}>
                        {renderEmptyBookings()}
                        <Heading textAlign={'center'} size={'xs'}>Du har inga pågående arbeten</Heading>
                    </Stack>
                </Box>
            )
        }

        return (
            <Box height={'auto'} bg={'white'} borderRadius={'2xl'} borderColor={'gray.200'}>
                {bookingsList?.map((booking, i) => {
                    const { label, badgeColor } = getSourceType(booking.bookingSource);

                    const isRevalidating = isValidaingCurrentUnfinishedBookings && bookingToLoad.current === booking.bookingId;

                    const hasAdditionalWork = booking?.messages?.length !== 0;
                    const isHighlightedAdditionalWork = booking.bookingId === highlightedBookingId;

                    return (
                        <Fragment key={i}>
                            {i !== 0 && <Divider />}
                            <Box padding={4} opacity={isRevalidating ? .5 : 1} bg={isHighlightedAdditionalWork ? 'gray.100' : 'none'}>
                                <Stack spacing={1}>
                                    <HStack justifyContent={'space-between'}>
                                        <Heading size={'sm'}>{booking.regNo}</Heading>
                                        {(booking.status.key === 'ongoing' || booking.status.key === 'waiting') && (
                                            <Badge colorScheme={'messenger'}>Påbörjas idag</Badge>
                                        )}

                                        {booking.status.key === 'finished' && (
                                            <Badge colorScheme={getWorkStatusColor(booking.status.key)}>{booking.status.label}</Badge>
                                        )}
                                    </HStack>
                                    <Box>
                                        <Badge colorScheme={badgeColor}>{label}</Badge>
                                    </Box>
                                    <Stack pt={4}>
                                        <HStack justifyContent={'space-between'}>
                                            <Text fontSize={14}>Förväntas färdig</Text>
                                            {isBookingDelayed(booking) ? (
                                                <Tooltip
                                                    hasArrow
                                                    bg='teal.500'
                                                    color='white'
                                                    placement='top'
                                                    label={`Det här arbetet förväntades bli färdigt ${booking.updatedAcceptedDate || booking.pickupDate}`}
                                                >
                                                    <Text cursor={'pointer'} ml={1} fontSize={12} display={'inline-block'} bg={'red.700'} color="white" borderRadius={'2xl'} px='1.5' py='.5' mr={2}>{booking.updatedAcceptedDate ? moment(booking.updatedAcceptedDate).format('LL') : moment(booking.pickupDate).format('LL')}</Text>
                                                </Tooltip>
                                            ) : (
                                                <Text ml={1} fontSize={12} display={'inline-block'} bg={'teal.700'} color="white" borderRadius={'2xl'} px='1.5' py='.5' mr={2}>{booking.updatedAcceptedDate ? moment(booking.updatedAcceptedDate).format('LL') : moment(booking.pickupDate).format('LL')}</Text>
                                            )}
                                        </HStack>
                                        <HStack justifyContent={'space-between'}>
                                            <Text fontSize={14}>Lämnades in</Text>
                                            <Text ml={1} fontSize={12} display={'inline-block'} bg='teal.700' color="white" borderRadius={'2xl'} px='1.5' py='.5' mr={2}>{moment(booking.acceptedDate).format('LL')}</Text>
                                        </HStack>
                                    </Stack>

                                    <HStack pt={4} justifyContent={'space-between'} gap={4} alignItems={'center'}>
                                        <Stack width={'70%'}>
                                            <SelectedWorkCategoriesColumn selectedWorkCategories={booking.selectedWorkCategories} />
                                            {hasAdditionalWork && (
                                                isHighlightedAdditionalWork ? (
                                                    <HStack>
                                                        <Text borderBottom={'1px solid'} color={'blue.500'} fontSize={14} cursor='pointer' onClick={() => {
                                                            bookingRef.current = booking;
                                                            onOpenAdditionalWorkModal();
                                                        }}>Tilläggsarbete {`(${booking.messages?.length})`}</Text>
                                                        <ChakraIcon
                                                            animate={{
                                                                scale: [1, 1.3, 1, 1.3, 1],
                                                            }}
                                                            transition={{
                                                                duration: 4,
                                                                // @ts-ignore
                                                                repeat: Infinity,
                                                                repeatType: "loop",
                                                            }}
                                                        >
                                                            <Center bg={'teal.100'} borderRadius={'full'} p={1}>
                                                                <Icon as={FaExclamation} color={'teal.700'} fontSize={12} _hover={{ color: 'teal.800' }} />
                                                            </Center>
                                                        </ChakraIcon>
                                                    </HStack>
                                                ) : (
                                                    <Text color={'blue.500'} fontSize={14} cursor='pointer' onClick={() => {
                                                        bookingRef.current = booking;
                                                        onOpenAdditionalWorkModal();
                                                    }}>Tilläggsarbete {`(${booking.messages?.length})`}</Text>
                                                )
                                            )}
                                        </Stack>
                                        <HStack width={'30%'} justifyContent='flex-end'>
                                            {isRevalidating ? (
                                                <Spinner height={20} size='lg' />
                                            ) : renderMenuItems(booking, isHighlightedAdditionalWork)}
                                        </HStack>
                                    </HStack>
                                </Stack>
                            </Box>
                        </Fragment>
                    )
                })}
            </Box>
        )
    }

    function renderEmptyBookings() {
        return (
            <HStack justifyContent={'center'} width={'100%'}>
                <img src={noBookingImage} width={120} alt="bild som visas när data saknas" />
            </HStack>
        )
    }

    const renderBookingsTable = (bookingsList: Booking[]) => {
        return (
            <Table variant='simple' size={isLargeScreen ? 'md' : 'md'}>
                <TableCaption>
                    {bookingsList?.length === 0 && !isLoading ? (
                        <Stack spacing={8} padding={2}>
                            <HStack>
                                {renderEmptyBookings()}
                            </HStack>
                            <Heading fontFamily={'Montserrat'} size={'sm'}>Du har inga pågående arbeten</Heading>
                        </Stack>
                    ) : (
                        'Arbetena ovan omfattar både pågående och de som är planerade att starta idag'
                    )}
                </TableCaption>

                <Thead height={12}>
                    <Tr>
                        <Th>Reg-nummer</Th>
                        <Th>Status</Th>
                        <Th>Förväntas färdig</Th>
                        <Th>Lämnades in</Th>
                        <Th>
                            <HStack spacing={2}>
                                <p>Bokat via</p>
                                <Tooltip placement="top" label={'Specificerar källan till bokningen, antingen via mobilappen eller via plattformen.'} hasArrow bg="teal.700">
                                    <InfoIcon />
                                </Tooltip>
                            </HStack>
                        </Th>
                        <Th></Th>
                    </Tr>
                </Thead>

                <Tbody >
                    {isLoading ? (
                        <Tr>
                            <Td>
                                <SkeletonText mt='6' noOfLines={6} spacing='6' skeletonHeight={'8'} />
                            </Td>
                            <Td>
                                <SkeletonText mt='6' noOfLines={6} spacing='6' skeletonHeight={'8'} />
                            </Td>
                            <Td>
                                <SkeletonText mt='6' noOfLines={6} spacing='6' skeletonHeight={'8'} />
                            </Td>
                            <Td>
                                <SkeletonText mt='6' noOfLines={6} spacing='6' skeletonHeight={'8'} />
                            </Td>
                            <Td>
                                <SkeletonText mt='6' noOfLines={6} spacing='6' skeletonHeight={'8'} />
                            </Td>
                            <Td>
                                <SkeletonText mt='6' noOfLines={6} spacing='6' skeletonHeight={'8'} />
                            </Td>
                        </Tr>
                    ) : (
                        <>
                            {bookingsList?.map((booking, i) => {
                                const hasAdditionalWork = booking?.messages?.length !== 0;

                                const { label, badgeColor } = getSourceType(booking.bookingSource);

                                const isRevalidating = isValidaingCurrentUnfinishedBookings && bookingToLoad.current === booking.bookingId;
                                const isHighlightedAdditionalWork = booking.bookingId === highlightedBookingId;

                                return (
                                    <Tr
                                        borderLeft={isHighlightedAdditionalWork ? '4px solid' : 'none'}
                                        borderColor={'green.500'}
                                        bg={isHighlightedAdditionalWork ? 'gray.100' : 'none'}
                                        key={i}
                                        opacity={isRevalidating ? .5 : 1}
                                    >
                                        <Td width={isLargeScreen ? '280px' : '256px'}>
                                            <LicensePlate regNo={booking.regNo} size='xs' />
                                        </Td>

                                        <Td>
                                            {(booking.status.key === 'ongoing' || booking.status.key === 'waiting') && (
                                                <Badge colorScheme={'messenger'}>Pågående</Badge>
                                            )}

                                            {booking.status.key === 'finished' && (
                                                <Badge colorScheme={getWorkStatusColor(booking.status.key)}>{booking.status.label}</Badge>
                                            )}
                                        </Td>

                                        <Td>
                                            {isBookingDelayed(booking) ? (
                                                <Tooltip
                                                    hasArrow
                                                    bg='teal.500'
                                                    color='white'
                                                    placement='top'
                                                    label={`Det här arbetet förväntades bli färdigt ${booking.updatedAcceptedDate || booking.pickupDate}`}
                                                >
                                                    <Text cursor={'pointer'} ml={1} fontSize={12} display={'inline-block'} bg={'red.700'} color="white" borderRadius={'2xl'} px='1.5' py='.5' mr={2}>{booking.updatedAcceptedDate ? moment(booking.updatedAcceptedDate).format('LL') : moment(booking.pickupDate).format('LL')}</Text>
                                                </Tooltip>
                                            ) : (
                                                <Text ml={1} fontSize={12} display={'inline-block'} bg={'teal.700'} color="white" borderRadius={'2xl'} px='1.5' py='.5' mr={2}>{booking.updatedAcceptedDate ? moment(booking.updatedAcceptedDate).format('LL') : moment(booking.pickupDate).format('LL')}</Text>
                                            )}
                                        </Td>
                                        <Td>
                                            <Text fontSize={12} display={'inline-block'} bg='teal.700' color="white" borderRadius={'2xl'} px='1.5' py='.5'>{moment(booking.acceptedDate).format('LL')}</Text>
                                        </Td>
                                        <Td>
                                            <Badge colorScheme={badgeColor}>{label}</Badge>
                                        </Td>
                                        <Td>
                                            <HStack justifyContent={'center'}>
                                                {isRevalidating ? (
                                                    <Box pl={2}>
                                                        <Spinner height={10} size='lg' />
                                                    </Box>
                                                ) : (
                                                    <HStack w={16} spacing={4} justifyContent={'space-between'}>
                                                        {renderMenuItems(booking, isHighlightedAdditionalWork)}

                                                        <Stack>
                                                            {hasAdditionalWork && (
                                                                isHighlightedAdditionalWork ? (
                                                                    <HStack position={'relative'} cursor='pointer' onClick={() => {
                                                                        bookingRef.current = booking;
                                                                        onOpenAdditionalWorkModal();
                                                                    }}>
                                                                        <Text borderBottom={'1px solid'} color={'blue.500'} fontSize={14}>{`(${booking.messages?.length})`}</Text>
                                                                        <ChakraIcon
                                                                            animate={{
                                                                                scale: [1, 1.3, 1, 1.3, 1],
                                                                            }}
                                                                            transition={{
                                                                                duration: 4,
                                                                                // @ts-ignore
                                                                                repeat: Infinity,
                                                                                repeatType: "loop",
                                                                            }}
                                                                        >
                                                                            <Center bg={'teal.100'} borderRadius={'full'} p={1}>
                                                                                <Icon as={FaBolt} color={'teal.700'} fontSize={16} _hover={{ color: 'teal.800' }} />
                                                                            </Center>
                                                                        </ChakraIcon>
                                                                    </HStack>
                                                                ) : (
                                                                    <HStack cursor='pointer' onClick={() => {
                                                                        bookingRef.current = booking;
                                                                        onOpenAdditionalWorkModal();
                                                                    }}>
                                                                        <Text color={'blue.500'} fontSize={14} >{`(${booking.messages?.length})`}</Text>
                                                                        <Center bg={'teal.100'} borderRadius={'full'} p={1}>
                                                                            <Icon as={FaBolt} color={'teal.700'} fontSize={16} _hover={{ color: 'teal.800' }} />
                                                                        </Center>
                                                                    </HStack>
                                                                )
                                                            )}
                                                        </Stack>
                                                    </HStack>
                                                )}
                                            </HStack>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </>
                    )}
                </Tbody>
            </Table>
        )
    }

    async function handleCancelBooking() {
        try {
            setIsLoadingCancelBooking(true)
            bookingToLoad.current = bookingRef.current.bookingId;
            await axiosPut(`/api/bookings/bookings-v1/${bookingRef.current.bookingId}/status/canceled`, {
                companyName: companyInfo.companyName,
                companyAddress: companyInfo.fullAddress,
                companyPhoneNo: companyInfo.companyPhoneNo,
                companyEmail: companyInfo.companyEmail,
                regNo: bookingRef.current.regNo,
                handinDate: bookingRef.current.acceptedDate,
                email: bookingRef.current.email,
            })

            mutateCurrentUnfinishedBookings()
            setIsLoadingCancelBooking(false)
            successToast('Bokningen har sparats som avbruten. Du kan fortfarande se information om bokningen under "Bokningar" ')
            onCloseCancelBookingModal()
        } catch (error) {
            setIsLoadingCancelBooking(false)
            return errorToast('Något gick fel när du försökte avboka, försök igen snart eller ring vår support')
        }
    }

    // TODO: Gör en mobilvariant av tabellen fullt mobilanpassad just

    return (
        <BaseLayout>
            <CancelBookingModal handleCancelBooking={handleCancelBooking} isLoadingCancelBooking={isLoadingCancelBooking} isOpen={isOpenCancelBookingModal} onClose={onCloseCancelBookingModal} />
            <AdditionalWorkModal booking={bookingRef} isOpenModal={isOpenAddWorkModal} onCloseModal={onCloseAddWorkModal} />
            <AdditionalWorkSummaryModal booking={bookingRef} isOpenModal={isOpenAdditionalWorkModal} onCloseModal={onCloseAdditionalWorkModal} />
            <FinishBookingModal booking={bookingRef?.current} handleFinishBooking={(payload) => handleFinishJob(payload)} isLoadingFinishBooking={isLoadingFinishBooking} onClose={onCloseFinishModal} isOpen={isOpenFinishModal} />
            <Box>
                <Tabs position="relative" variant="unstyled">
                    <TabList pl={4}>
                        <Tab>Aktuella arbeten</Tab>
                        <Tab>Analys</Tab>
                    </TabList>
                    <TabIndicator
                        mt="-1.5px"
                        height="2px"
                        bg="blue.500"
                        borderRadius="1px"
                    />
                    <TabPanels>
                        <TabPanel p={0} pt={4}>
                            <Grid templateColumns={'repeat(1, 1fr)'} gap={4}>
                                {isMobile ? (
                                    <GridItem colSpan={5} p={4}>
                                        <Box boxShadow="base" height="auto" rounded="2xl" background="white">
                                            <HStack padding={4} justifyContent={'space-between'}>
                                                <Heading size={'sm'}>Aktuella arbeten</Heading>
                                                <Icon as={FaRocket} color={'purple.600'} />
                                            </HStack>
                                            <Stack spacing={2}>
                                                {renderMobileViewTodaysBookings(bookingsToDisplay)}
                                            </Stack>
                                        </Box>
                                    </GridItem>
                                ) : (
                                    <GridItem colSpan={[5, 5, 5, 5, 3]} mb={6}>
                                        <Box boxShadow="base" height="auto" rounded="2xl" background="white">
                                            <Stack spacing={2}>
                                                <Box>
                                                    <Box p={4}>
                                                        <Heading mb={2} size={'sm'}>Aktuella arbeten ({bookingsToDisplay.length})</Heading>
                                                        <Text fontSize={14}>Nedan presenteras en sammanställning av pågående arbeten samt de som är planerade att påbörjas idag.</Text>
                                                    </Box>
                                                    {renderBookingsTable(bookingsToDisplay)}
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </GridItem>
                                )}
                            </Grid>
                        </TabPanel>
                        <TabPanel p={0} pt={4}>
                            <Grid templateColumns={'repeat(5, 1fr)'} gap={4}>
                                <GridItem colSpan={[5, 5, 5, 3]}>
                                    <HStack boxShadow="base" height="auto" rounded="2xl" background="white" alignItems={'center'} border={'1px solid'} borderColor={'gray.400'}>
                                        <Stack padding={2} spacing={4} width={'100%'}>
                                            <HStack padding={2} justifyContent={'space-between'} alignItems={'start'}>
                                                <Stack spacing={2}>
                                                    <Heading size={'sm'}>Hur går det för din verkstad?</Heading>
                                                    <Text width={'85%'} fontSize={14}>{'Här visas totala antalet avslutade (genomförda) arbeten per månad. Differansen från föregående månad visas också när du har muspekaren över varje stapel.'}</Text>
                                                </Stack>
                                                <Icon as={FaChartBar} fontSize='lg' color={'purple.600'} />
                                            </HStack>
                                            <BarChart />
                                        </Stack>
                                    </HStack>
                                </GridItem>
                                <GridItem colSpan={[5, 5, 5, 2]}>
                                    <AdditionalWorkStats />
                                </GridItem>
                            </Grid>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </BaseLayout >
    )
}

export default Home;