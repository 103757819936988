import { Center, Stack, Heading, Text, Button, Box, Flex } from '@chakra-ui/react';
import React, { Component } from 'react';
import { Endpoints } from '../hooks/api/endpoints';
import { axiosPost } from '../hooks/api/utils';
import { isDevelopment, isProduction } from '../utils/networkConfig';

interface Props {
    children: React.ReactNode;
    errorOnPage: string
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error | null, errorInfo: React.ErrorInfo) {
        // TODO: add support for ?platform in backend
        if (isDevelopment || isProduction) {
            return axiosPost(`${Endpoints.ERROR_REPORT_V1}?platform`, { message: `Fel i plattformen - På vy: ${this.props.errorOnPage} - ${error?.message} - Error info: ${JSON.stringify(errorInfo)}` }).finally(() => ({}))
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <Flex justify={'center'} align='center' h={'100vh'}>
                    <Stack spacing={2}>
                        <Heading textAlign={'center'} size={'md'}>Ett fel har uppstått</Heading>
                        <Text textAlign={'center'}>Försök gärna igen genom att starta uppdatera sidan</Text>
                        <Box pt={4}>
                            <Button w={'100%'} variant={'primary'} onClick={() => window.location.reload()}>Uppdatera sidan</Button>
                        </Box>
                    </Stack>
                </Flex>

            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary;