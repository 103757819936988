import { Box, Button, Image, Flex, Heading, Icon, SimpleGrid, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, ModalHeader, HStack, Stack, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaCheckSquare } from "react-icons/fa";
import { ASSET_TYPE } from "../../enums/assetTypes";
import { useCompanyInfo, useMediaAssets } from "../../hooks/api/companyInfo";
import { Endpoints } from "../../hooks/api/endpoints";
import { axiosDelete, axiosPost, axiosPut } from "../../hooks/api/utils";
import { MediaAsset } from "../../hooks/types";
import { useCustomToast } from "../../hooks/useCustomToast";
import { getFirebaseImageUrl } from "../../utils/firebase";

interface AssetsModalProps {
    isOpenModal: boolean;
    onCloseModal: () => void;
    imageType?: 'companyLogo' | 'companyPreviewImage' | 'marketing' | string;
    setIsLoadingNewVideo?: (val: boolean) => void;
}

const AssetsModal: React.FC<AssetsModalProps> = ({ isOpenModal, onCloseModal, imageType, setIsLoadingNewVideo }) => {
    const { mutateCompanyInfo } = useCompanyInfo();
    const { mediaAssets, isLoadingMediaAssets, mutateMediaAssets } = useMediaAssets();
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [markedMediaAsset, setMarkedMediaAsset] = useState<MediaAsset>(null);
    const [isMobile] = useMediaQuery('(max-width: 492px)')

    const { successToast, infoToast } = useCustomToast();

    useEffect(() => {
        return () => setMarkedMediaAsset(null);
    }, [])

    const fileUploadInputRef = React.useRef(null);

    if (isLoadingMediaAssets) {
        return null;
    }


    const uploadFileToMediaAssets = async (file) => {
        let formData = new FormData();
        formData.append('image', file);

        // TODO: Validera på backend - om namnet finns i assets för kunden -> returnera "already.exists"-fel
        try {
            const mediaAssetsResponse = await axiosPost('/api/company-info/info-v1/assets', formData, {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
            });
            mutateMediaAssets(mediaAssetsResponse)

        } catch (error) {
            console.log(error)
        }
    }

    const handleUploadFileToAssets = (file: File) => {
        return uploadFileToMediaAssets(file)
    }

    const changePreviewImage = async (image) => {
        const payload = {
            assetToken: image.assetToken,
            imageType
        }

        onCloseModal();
        try {
            await axiosPut(`${Endpoints.COMPANY_INFO}/update`, payload);
            if (imageType === ASSET_TYPE.MARKETING) {
                mutateMediaAssets();
                successToast('Ny bild på din verkstad tillagd')
                return;
            }
            mutateCompanyInfo();
        } catch (error) {
            console.log(error)
        }
    }

    const removeSelectedAsset = async (selectedAsset: MediaAsset) => {
        setIsLoadingDelete(true)
        try {
            await axiosDelete(`${Endpoints.COMPANY_INFO}/assets/${selectedAsset.id}/file/${selectedAsset.assetToken}`)
            mutateMediaAssets();
            setIsLoadingDelete(false)
        } catch (error) {
            setIsLoadingDelete(false)
            return infoToast("Det gick inte att ta bort bilden, försök snart igen")
        }

    }

    const renderAssetName = (assetName) => {
        if (assetName.length > 23) {
            return `${assetName.slice(0, 23)}...`;
        }
        return assetName;
    }

    return (
        <>
            <Modal isOpen={isOpenModal} onClose={onCloseModal} size={isMobile ? 'xs' : '4xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Dina bilder</ModalHeader>
                    <ModalHeader>
                        <HStack spacing={3}>
                            <Button size={'sm'} colorScheme='blue' onClick={() => {
                                fileUploadInputRef.current.click()
                            }}>
                                Ladda upp
                                <input
                                    type="file"
                                    ref={fileUploadInputRef}
                                    onChange={e => {
                                        handleUploadFileToAssets(e.target.files[0])
                                    }}
                                    style={{ display: 'none' }}
                                />
                            </Button>
                            <Button size={'sm'} colorScheme='green' onClick={() => changePreviewImage(markedMediaAsset)}>Välj markerad</Button>
                            <Button size={'sm'} isLoading={isLoadingDelete} colorScheme='red' variant='outline' onClick={() => removeSelectedAsset(markedMediaAsset)}>Ta bort</Button>
                        </HStack>

                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        {mediaAssets?.length === 0 && (
                            <Stack spacing={8}>
                                <Heading size={'sm'}>{'Du har inga upplade bilder att visa'}</Heading>
                                {/* <Box
                                    onDragEnter={() => ({})}
                                    onDragOver={() => ({})}
                                    onDragLeave={() => ({})}
                                    onDrop={(e) => handleUploadFileToAssets(e.dataTransfer.files[0])}
                                    borderRadius={'2xl'}
                                    border={'1px dashed'}
                                    width='100%'
                                    height={40}
                                    bg={'gray.100'}
                                >

                                </Box> */}
                            </Stack>

                        )}
                        <SimpleGrid columns={[1, 1, 3, 3]} spacing={[4, 4, 10, 10]} marginBottom={12}>
                            {mediaAssets?.length > 0 && mediaAssets.filter(asset => !asset.mimeType?.includes('video') && !asset.mimeType?.includes('mp4')).map((asset, i) => {
                                return (
                                    <Box
                                        key={i}
                                        cursor="pointer"
                                        onClick={() => setMarkedMediaAsset(asset)}
                                        padding="4"
                                        boxShadow={markedMediaAsset?.assetToken === asset.assetToken ? 'lg' : 'none'}
                                        borderRadius="lg"
                                    >
                                        <Image
                                            borderRadius="lg"
                                            htmlWidth='100%'
                                            height={'200px'}
                                            objectFit='contain'
                                            src={getFirebaseImageUrl(asset.assetToken)}
                                            alt='Företagsbild'
                                        />
                                        <Flex justifyContent="space-between" alignItems="center" marginTop={4}>
                                            <Heading size={'xs'}>{renderAssetName(asset.assetName)}</Heading>
                                            {markedMediaAsset?.assetToken === asset.assetToken && (
                                                <Icon as={FaCheckSquare} color='green.500' />
                                            )}
                                        </Flex>
                                    </Box>
                                )
                            })}
                        </SimpleGrid>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AssetsModal;
