import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, ModalHeader, HStack, Stack, Button, Box, Container, Input, Heading, useMediaQuery } from "@chakra-ui/react";
import moment from "moment";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Endpoints } from "../../../hooks/api/endpoints";
import { axiosDelete, axiosPost } from "../../../hooks/api/utils";
import { OpeningHours } from "../../../hooks/types";
import { useCustomToast } from "../../../hooks/useCustomToast";

interface OpeningHoursProps {
    isOpenModal: boolean;
    onCloseModal: () => void;
    openingHours?: OpeningHours[]
    mutateOpeningHours?: () => void;
}

interface OpeningHoursUI extends OpeningHours {
    isOpen?: boolean;
}

interface OpeningHoursPayload {
    openingDay: number;
    openingTime?: string;
    closingTime?: string;
    id?: number;
}

const OpeningHoursModal: React.FC<OpeningHoursProps> = ({ isOpenModal, onCloseModal, openingHours, mutateOpeningHours }) => {

    const [openingHoursPayload, setOpeningHoursPayload]: [OpeningHoursUI[], Dispatch<SetStateAction<OpeningHoursUI[]>>] = useState(openingHours || []);
    const [openTime, setOpenTime] = useState(null);
    const [closeTime, setCloseTime] = useState(null);
    const [isMobile] = useMediaQuery('(max-width: 492px)')

    const { successToast, errorToast } = useCustomToast();

    useEffect(() => {
        setOpeningHoursPayload(openingHours)
    }, [openingHours, setOpeningHoursPayload])

    const totalAvailableDays = [0, 1, 2, 3, 4, 5, 6]
    const currentOpenedDays: number[] = openingHours?.map(openedHour => openedHour.openingDay);

    const closedDays = totalAvailableDays.map((availableDay) => {
        if (currentOpenedDays?.indexOf(availableDay) == -1) {
            return availableDay
        }
        return null;
    })

    const resetState = () => {
        setOpenTime(null)
        setCloseTime(null)
    }

    const updateOpeningHours = async (openingHours: OpeningHoursPayload[], updateType?: 'toOpen' | 'toClose' | 'toChangeTime') => {
        try {
            const payload = {
                openingHours
            }
            await axiosPost(Endpoints.COMPANY_OPENING_HOURS, payload)
            mutateOpeningHours()
            successToast(updateType === 'toChangeTime' ? 'Dina tider har sparats' : 'Öppettiderna har uppdaterats')
            if (updateType === 'toChangeTime') {
                resetState()
            }
        } catch (error) {
            return errorToast('Det gick inte att lägga till en ny tid just nu, försök igen snart')
        }
    }

    const deleteOpeningHour = async (id: number, openingDay: number) => {
        try {
            await axiosDelete(`${Endpoints.COMPANY_OPENING_HOURS}/delete/${id}`)
            mutateOpeningHours()
            successToast(`${moment().weekday(openingDay).format('dddd')} har ändrats till stängd`)
        } catch (error) {
            return errorToast('Det gick inte att lägga till en ny tid just nu, försök igen snart')
        }
    }

    return (
        <Modal isOpen={isOpenModal} onClose={() => {
            onCloseModal()
        }} size={isMobile ? 'xs' : 'md'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={16}>Öppettider</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box pb={4} pt={4}>
                        <Stack spacing={12}>
                            <Stack spacing={4}>
                                <Heading size={'xs'}>Öppna dagar</Heading>
                                <Stack spacing={6}>
                                    {openingHoursPayload && openingHoursPayload?.map((openingHour, i) => {
                                        const minifiedWeekDayLabel = moment().weekday(openingHour?.openingDay).format('dd')

                                        return (
                                            <HStack key={i} spacing={4} justify={'space-between'}>
                                                <Button
                                                    width={isMobile ? 8 : 10}
                                                    height={isMobile ? 8 : 10}
                                                    size={'sm'}
                                                    borderRadius={'full'}
                                                    colorScheme='teal'
                                                    variant={'solid'}
                                                    onClick={() => {
                                                        deleteOpeningHour(openingHour.id, openingHour.openingDay)
                                                    }}
                                                >
                                                    {minifiedWeekDayLabel}
                                                </Button>

                                                <HStack spacing={2}>
                                                    <Input
                                                        defaultValue={`${openingHour.openingTime.slice(0, 2)}:${openingHour.openingTime.slice(2, 4)}`}
                                                        value={openTime?.openingTime}
                                                        type='time'
                                                        size={isMobile ? 'xs' : 'sm'}
                                                        placeholder='Från'
                                                        onChange={v => setOpenTime({ ...openTime, [openingHour.id]: { openingTime: v.target.value } })} />
                                                    <Box width={8} height={1} bg={'blackAlpha.700'} />
                                                    <Input
                                                        defaultValue={`${openingHour.closingTime.slice(0, 2)}:${openingHour.closingTime.slice(2, 4)}`}
                                                        value={closeTime?.closingTime}
                                                        type='time'
                                                        size={isMobile ? 'xs' : 'sm'}
                                                        placeholder='Till'
                                                        onChange={v => setCloseTime({ ...closeTime, [openingHour.id]: { closingTime: v.target.value } })} />
                                                </HStack>
                                            </HStack>
                                        )
                                    })}
                                </Stack>

                                <Heading size={'xs'}>Stängda dagar</Heading>
                                {closedDays?.map((closedDay, i) => {
                                    if (closedDay === null) {
                                        return null;
                                    }
                                    return (
                                        <Button
                                            key={i}
                                            width={isMobile ? 8 : 10}
                                            height={isMobile ? 8 : 10}
                                            size={'sm'}
                                            borderRadius={'full'}
                                            colorScheme='teal'
                                            variant={'outline'}
                                            onClick={() => {
                                                updateOpeningHours([{
                                                    openingDay: closedDay,
                                                    openingTime: '0800',
                                                    closingTime: '1700'
                                                }], 'toOpen')
                                                return;
                                            }}
                                        >
                                            {moment().weekday(closedDay).format('dd')}
                                        </Button>
                                    )
                                })}

                            </Stack>

                            <Button
                                width={'140px'}
                                colorScheme='teal'
                                variant='primary'
                                size="sm"
                                onClick={() => {
                                    if (!openTime && !closeTime) {
                                        return successToast('Nu är allt sparat!')
                                    }

                                    const modifiedTimes: OpeningHoursPayload[] = openingHours?.length > 0 && openingHours.filter(openingHour => (openTime && openTime[openingHour?.id]) || (closeTime && closeTime[openingHour?.id]))
                                        .map(openingHour => {
                                            const openingTime: string = (openTime && openTime[openingHour.id]?.openingTime) || openingHour?.openingTime
                                            const closingTime: string = (closeTime && closeTime[openingHour.id]?.closingTime) || openingHour?.closingTime
                                            return {
                                                openingDay: openingHour.openingDay,
                                                openingTime: openingTime.replace(':', ''),
                                                closingTime: closingTime.replace(':', ''),
                                                id: openingHour.id
                                            }
                                        })

                                    return updateOpeningHours(modifiedTimes, 'toChangeTime')
                                }}
                            >
                                Spara
                            </Button>
                        </Stack>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default OpeningHoursModal;
