import useSWRImmutable from 'swr/immutable'
import { AdditionalWorkStatistics, BookingStatistics } from '../types'
import { fetcher } from "./utils"

export function useBookingStatistics() {
    const { data, error, mutate } = useSWRImmutable<BookingStatistics[]>(`/api/statistics/statistics-v1/bookings`, fetcher)

    return {
        bookingStatistics: data,
        mutateBookingStatistics: mutate,
        isLoading: !error && !data,
        isError: error,
    }
}


export function useAdditionalWorkStatistics() {
    const { data, error, mutate } = useSWRImmutable<AdditionalWorkStatistics>(`/api/statistics/statistics-v1/additional-work`, fetcher)

    return {
        additionalWorkStatistics: data,
        mutateAdditionalWorkStatistics: mutate,
        isLoadingAdditionalWorkStatistics: !error && !data,
        isErrorAdditionalWorkStatistics: error,
    }
}