import React, { useState } from "react";
import { Box, Text, Flex, Heading, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure, HStack } from "@chakra-ui/react";
import 'moment/locale/sv'
import { OfferEnquiry } from "./types";
import { Video } from "../Video";
import { getFirebaseImageUrl } from "../../utils/firebase";

// TODO: Översätt category till Svenska: Troubleshoot -> Felsökning osv
export function translateReparationSubCategories(category: string) {
    if (category === 'reparation') {
        return 'Reparation enligt beskrivning'
    }
    if (category === 'troubleshoot') {
        return 'Felsökning enligt beskrivning'
    }
    if (category === 'inspection') {
        return 'Fel enligt besiktningsprotokoll'
    }
}

const Reparations: React.FC<{ enquiry: OfferEnquiry }> = ({ enquiry }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [attachmentClicked, setAttachmentClicked] = useState(null);

    if (enquiry?.reparations?.length === 0) {
        return null;
    }

    function getMedia() {
        const url = getFirebaseImageUrl(attachmentClicked?.fileName);
        if (attachmentClicked?.mimeType?.includes('video')) {
            return <Video selectedVideo={url} w="400" h="100" />
        }

        if (attachmentClicked?.mimeType?.includes('image')) {
            return <img src={url} alt={'some image'} />
        }
    }

    const renderMediaModal = () => {
        return (
            <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton bg={'white'} borderWidth={1} border={'gray.800'} mt={2} mr={1} zIndex={2} _hover={{ bg: 'gray.200' }} />
                    <ModalBody>
                        <Box>
                            {getMedia()}
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        )
    }


    return (
        <>
            {renderMediaModal()}

            {enquiry?.reparations?.map((reparation, i) => (
                <Box key={i} mb={[4, 4, 0, 0]}>
                    <Flex gap={[4, 8, 8, 4]} flexDir={['column', 'row', 'row', 'row']} align={'start'} justify='start'>
                        <Box>
                            <HStack align={'center'} spacing={2} width={'max-content'}>
                                <Heading size={'xs'}>{translateReparationSubCategories(reparation.category)}</Heading>
                                {/* TODO: Skilj på besiktningsprotokoll och fil på reparation/felsökning så vi säger tex "Öppna besikningsprotokoll" istället, blir tydligare */}
                                {reparation?.attachments?.map((attachment, i) => {
                                    const isVideo = attachment?.mimeType?.includes('video');
                                    const isPdf = attachment?.mimeType?.includes('pdf');
                                    if (isPdf) {
                                        return (
                                            <a key={i} style={{ fontSize: 12 }} target="_blank" rel="noreferrer" href={getFirebaseImageUrl(attachment.fileName)}>
                                                <Button variant={'link'} size={'xs'} colorScheme={'blue'}>{'(Öppna besiktningsprotokoll)'}</Button>
                                            </a>
                                        )
                                    }

                                    return (
                                        <Button variant={'link'} size={'xs'} colorScheme={'blue'} onClick={() => {
                                            setAttachmentClicked(attachment)
                                            onOpen()
                                        }}>{isVideo ? '(Öppna video)' : '(Öppna bild)'}</Button>
                                    )
                                })}
                            </HStack>
                            {reparation.description && (
                                <Text fontSize={12}>{reparation.description}</Text>
                            )}
                        </Box>
                    </Flex>
                </Box>
            ))}
        </>

    )

}

export default Reparations;