import { Stack, Box, HStack, Flex, Divider, Heading, Badge, Icon, Text } from "@chakra-ui/react";
import { FaPaperclip } from "react-icons/fa";
import { getSeverityStatus } from "../../../common/utils";
import { AdditionalWork } from "../../../hooks/api/booking";
import { getFirebaseImageUrl } from "../../../utils/firebase";
import { SelectedWorkCategoriesColumnWithKeyValSubCategories } from "../../Bookings/SelectedWorkCategories";
import { Video } from "../../Video";
import WorkArticleDetails from "../../WorkArticleDetails";

const AdditionalWorkSummaryItem: React.FC<{ additionalWorkOffers: AdditionalWork[]; files: any[] }> = ({ additionalWorkOffers, files }) => {
    const renderAdditionalWork = () => {
        if (!additionalWorkOffers) {
            return;
        }

        if (additionalWorkOffers?.length === 0) return <Text>Inga arbeten här</Text>

        return (
            <Stack spacing={4}>
                {additionalWorkOffers?.map((addedWorkItem, i) => {
                    const { workDescription, severityStatus, selectedAction, workCategories } = addedWorkItem;

                    return (
                        <Box key={`${addedWorkItem}-${i}`} bg={'gray.100'} p={4} borderRadius={'md'}>
                            <Stack spacing={4} paddingTop={2}>
                                <HStack justify={'space-between'} align={'start'}>
                                    <SelectedWorkCategoriesColumnWithKeyValSubCategories selectedWorkCategories={workCategories} />
                                    <Flex alignItems="center">
                                        {renderStatusForAdditionalWork(selectedAction)}
                                    </Flex>
                                </HStack>
                                <Divider borderColor={'gray.400'} />
                                <Box>
                                    <Heading size="xs" paddingRight={2}>beskrivning</Heading>
                                    <Text fontSize={14}>{workDescription}</Text>
                                </Box>
                                <Divider borderColor={'gray.400'} />
                                <HStack align={'flex-start'} justify='space-between'>
                                    <Heading size="xs" paddingRight={2}>Allvarlighetsgrad</Heading>
                                    <Badge colorScheme={getSeverityStatus(severityStatus).color}>{getSeverityStatus(severityStatus).label}</Badge>
                                </HStack>
                                <Divider borderColor={'gray.400'} />

                                <WorkArticleDetails data={addedWorkItem} />
                            </Stack>
                        </Box>
                    )
                })}
            </Stack>
        )

    }

    function renderStatusForAdditionalWork(selectedAction: string | null, minified?: boolean) {
        return (
            <>
                {selectedAction ? (
                    selectedAction.toLowerCase() === 'accepted' ? (
                        <Badge fontSize={minified ? 10 : 12} px={2} py={.5} rounded={'full'} colorScheme={'green'}>{minified ? 'Accepterad' : 'Kunden har accepterat offert'}</Badge>
                    ) : (
                        <Badge fontSize={minified ? 10 : 12} px={2} py={.5} rounded={'full'} colorScheme={'orange'}>{minified ? 'Avböjd' : 'Kunden har avböjt offert'}</Badge>

                    )
                ) : (
                    <Badge fontSize={minified ? 10 : 12} px={2} py={.5} rounded={'full'} colorScheme={'blue'}>{minified ? 'Ej besvarad' : 'Kunden har ännu inte svarat'}</Badge>
                )}
            </>

        )
    }

    const renderContent = () => {

        const videoFromWorkshop = files?.find(file => file.mimeType.includes('mp4') || file.mimeType.includes('quicktime'));
        const pdfOfferFromWorkshop = files?.find(file => file.mimeType.includes('pdf'));
        const imageFromWorkshop = files?.find(file => !file.mimeType.includes('mp4') && !file.mimeType.includes('quicktime') && !file.mimeType.includes('pdf'));


        return (
            <Stack w={'100%'} pt={videoFromWorkshop || imageFromWorkshop ? 0 : 2}>
                {videoFromWorkshop && (
                    <HStack mt={4} mb={2} justifyContent={'center'} bg={'blackAlpha.800'} borderRadius={'md'}>
                        <Video selectedVideo={getFirebaseImageUrl(videoFromWorkshop.path)} w={300} h={300} />
                    </HStack>
                )}
                {imageFromWorkshop && (
                    <HStack mt={4} mb={2} justifyContent={'center'} borderRadius={'md'}>
                        <img width={320} height={100} src={getFirebaseImageUrl(imageFromWorkshop.path)} alt={'Arbetsdetaljer i .pdf format'} />
                    </HStack>
                )}

                {renderAdditionalWork()}
                {pdfOfferFromWorkshop && (
                    <>
                        <Box pt={4}>
                            <Divider mb={4} />
                            <Heading size="xs" pb={2}>Arbetsdetaljer</Heading>
                            <HStack spacing={2} _hover={{ color: 'teal.700' }} cursor='pointer'>
                                <Icon color="teal.500" as={FaPaperclip} />
                                <a style={{ fontSize: 12 }} target="_blank" rel="noreferrer" href={getFirebaseImageUrl(pdfOfferFromWorkshop.path)}>{pdfOfferFromWorkshop.fileName}</a>
                            </HStack>
                        </Box>
                    </>

                )}
            </Stack>
        )
    }

    return renderContent();
}

export default AdditionalWorkSummaryItem;