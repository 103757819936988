import { Box, useDisclosure, HStack, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Divider } from "@chakra-ui/react";
import React from "react";
import BaseLayout from "./BaseLayout";
import 'moment/locale/sv'
import FilterDrawer from "../components/Marketplace/FilterDrawer";
import HandledEnquiries from "../components/Marketplace/HandledEnquiries";
import OfferEnquiriesList from "../components/Marketplace/OfferEnquiriesList";

export const MARKETPLACE_CONTENT_WIDTH = ['100%', '92%', '80%', '70%'];

const Marketplace: React.FC<{}> = () => {
    const { isOpen: isOpenFilterDrawer, onClose: onCloseFilterDrawer } = useDisclosure()
    const btnRef = React.useRef()

    return (
        <BaseLayout>
            <FilterDrawer isOpen={isOpenFilterDrawer} onClose={onCloseFilterDrawer} btnRef={btnRef} />
            <Box borderRadius={16} bg='white' boxShadow={'base'} p={[4, 4, 0, 0]}>

                <Tabs variant="unstyled">
                    <HStack alignItems={'flex-end'} pos='relative' p={4} pb={0}>
                        <TabList h={12}>
                            <Tab fontSize={[10, 16]}>{'Inkomna offertförfrågningar'}</Tab>
                            <Tab fontSize={[10, 16]}>{'Hanterade offertförfrågningar'}</Tab>
                        </TabList>
                        <TabIndicator
                            mt="-1.5px"
                            height="2px"
                            bg="blue.500"
                            borderRadius="1px"
                        />
                    </HStack>

                    <Divider pt={4} borderColor={'gray.400'} />

                    <TabPanels>
                        <TabPanel p={0}>
                            <OfferEnquiriesList />
                        </TabPanel>
                        <TabPanel p={0}>
                            <HandledEnquiries />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>

        </BaseLayout >
    )
}

export default Marketplace;