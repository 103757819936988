import { InputGroup, InputLeftElement, Icon, Input, HStack, useMediaQuery, IconButton } from "@chakra-ui/react";
import React, { useState } from "react";
import { FaCheck, FaSearch, FaTimes } from "react-icons/fa";
import isEmpty from 'lodash/isEmpty'

interface SearchBoxProps {
    setRegNoParam: (val: string) => void;
    width?: number | string;
    inputWidth?: number | string;
}

export const SearchBox: React.FC<SearchBoxProps> = ({ setRegNoParam, width, inputWidth }) => {
    const [regNoSearch, setRegNoSearch] = useState('');
    const [isMobile] = useMediaQuery('(max-width: 492px)')

    const onKeyboardEnter = (event: any) => {
        if (event.keyCode === 13) {
            return setRegNoParam(regNoSearch);
        }
    }

    return (
        <HStack alignItems={'center'} justifyContent={'center'} spacing={2} minWidth={width || 96} width={width}>
            {!isEmpty(regNoSearch) ? (
                <Icon borderRadius={'full'} cursor={'pointer'} fontSize={'xl'} as={FaTimes} color={'teal.700'} _hover={{ color: 'teal.900' }} onClick={() => {
                    setRegNoSearch('');
                    setRegNoParam('');
                }} />
            ) : <Icon fontSize={'xl'} visibility={'hidden'} />}
            <InputGroup width={inputWidth || 52} size={'sm'}>
                <InputLeftElement
                    pointerEvents='none'
                    children={<Icon as={FaSearch} color='gray.300' />}
                />
                <Input borderRadius={12} onKeyDown={(e) => onKeyboardEnter(e)} variant={'filled'} type='text' placeholder='Sök regnr.' value={regNoSearch.toUpperCase()} onChange={e => setRegNoSearch(e.target.value)} />

            </InputGroup>
            {isMobile && (
                <IconButton
                    colorScheme='teal'
                    aria-label='Apply car search'
                    size='xs'
                    icon={<FaCheck />}
                    onClick={() => {
                        setRegNoParam(regNoSearch)
                    }} />
            )}
        </HStack>

    )
}