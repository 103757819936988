import Home from "./pages/Home";
import Login from "./pages/Login";
import CompanyInfo from "./pages/CompanyInfo";
import Marketplace from "./pages/Marketplace";
import Bookings from "./pages/Bookings";
import BookingDetails from "./pages/BookingDetails";
import SignUp from "./pages/SignUp";
import ErrorBoundary from "./components/ErrorBoundary";

const routes = [
  {
    component: (
      <ErrorBoundary errorOnPage="Översikten">
        <Home />
      </ErrorBoundary>
    ),
    path: '/',
    isAuthProtected: true
  },
  {
    component: (
      <ErrorBoundary errorOnPage="Bokningar">
        <Bookings />
      </ErrorBoundary>
    ),
    path: '/bookings',
    isAuthProtected: true
  },
  {
    component: (
      <ErrorBoundary errorOnPage="Logga in">
        <Login />
      </ErrorBoundary>
    ),
    path: '/login',
    isAuthProtected: false
  },
  {
    component: (
      <ErrorBoundary errorOnPage="Registrera företag">
        <SignUp />
      </ErrorBoundary>
    ),
    path: '/signup',
    isAuthProtected: false
  },
  {
    component: (
      <ErrorBoundary errorOnPage="Mitt Bilgo">
        <CompanyInfo />
      </ErrorBoundary>
    ),
    path: '/my-company',
    isAuthProtected: true
  },
  {
    component: (
      <ErrorBoundary errorOnPage="Bokningsdetaljer">
        <BookingDetails />
      </ErrorBoundary>
    ),
    path: '/bookings/:bookingId',
    isAuthProtected: true
  },
  {
    component: (
      <ErrorBoundary errorOnPage="Offertförfrågan">
        <Marketplace />
      </ErrorBoundary>
    ),
    path: '/marketplace',
    isAuthProtected: true
  },
]

export default routes