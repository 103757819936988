import axios from 'axios'
import { getHost } from "../../utils/networkConfig"

export const fetcher = (endpoint: string, token?: string) => {
    const url = `${getHost()}${endpoint}`
    return axios.get(url, {
        headers: {
            'Authorization': token || localStorage.getItem('token'),
        },
    }).then(res => res.data)
}


export const axiosDelete = endpoint => {
    const url = `${getHost()}${endpoint}`
    return axios.delete(url, {
        headers: {
            'Authorization': localStorage.getItem('token'),
        },
    }).then(res => res.data)
}

export function axiosPost(endpoint: string, payload?: any, config?: any): Promise<any> {
    if (!localStorage.getItem('token')) {
        console.warn("Unauthorized");
        throw new Error('Unauthorized')
    }
    const url = `${getHost()}${endpoint}`
    return axios.post(url, payload, {
        headers: {
            ...config,
            'Authorization': localStorage.getItem('token'),
        },
    })
}

export function axiosPostPublic(endpoint: string, payload?: any, config?: any): Promise<any> {
    const url = `${getHost()}${endpoint}`
    return axios.post(url, payload, {
        headers: {
            ...config,
        },
    })
}

export function axiosPut(endpoint: string, payload?: any, config?: any): Promise<any> {
    const url = `${getHost()}${endpoint}`
    return axios.put(url, payload, {
        headers: {
            ...config,
            'Authorization': localStorage.getItem('token'),
        },
    })
}
