import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Stack, Heading, Button, Text } from "@chakra-ui/react";

const CancelBookingModal: React.FC<{
    handleCancelBooking: () => void;
    isLoadingCancelBooking: boolean;
    isOpen: boolean;
    onClose: () => void;
}> = ({ handleCancelBooking, isLoadingCancelBooking, isOpen, onClose }) => {

    return (
        <Modal isOpen={isOpen} onClose={() => onClose()} size={'2xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Bekräfta avbokning</ModalHeader>
                <ModalCloseButton />
                <ModalBody padding={8} minHeight={200}>
                    <Stack spacing={12}>
                        <Stack spacing={4}>
                            <Heading size={'md'}>Är du säker på att du vill avboka besöket?</Heading>
                            <Text fontSize={14}>När du avbokar så meddelas kunden automatiskt att bokningen och arbetet på bilen inte blir av, och du kommer inte kunna återuppta den här bokningen utan får isåfall skapa en ny bokning.</Text>
                        </Stack>
                        <Button
                            w="200px"
                            colorScheme='green'
                            size="sm"
                            onClick={() => handleCancelBooking()}
                            isLoading={isLoadingCancelBooking}
                        >
                            Avboka
                        </Button>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )

}


export default CancelBookingModal;