import React from "react";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";

import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import styles from "./PlacesAutocomplete.module.scss";

interface PlacesAutocompleteProps {
    setSelectedCoords: ({ }) => void;
    setSelectedAddress: ({ }) => void;
}

const PlacesAutocomplete: React.FC<PlacesAutocompleteProps> = ({ setSelectedCoords, setSelectedAddress }) => {

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            language: 'sv',
            componentRestrictions: {
                country: ['se', 'sv', 'swe']
            }
        },
    });

    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();
        
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);

        setSelectedAddress({
            fullAddress: results[0].formatted_address,
        })

        setSelectedCoords({ lat, lng });
    };

    return (
        <Combobox onSelect={handleSelect}>
            <ComboboxInput
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready}
                className={styles.combobox}
                placeholder="Sök på din adress eller företagsnamn"
            />
            <ComboboxPopover portal={false}>
                <ComboboxList>
                    {status === "OK" &&
                        data.map(({ place_id, description }) => (
                            <ComboboxOption key={place_id} value={description} />
                        ))}
                </ComboboxList>
            </ComboboxPopover>
        </Combobox>
    )
}

export default PlacesAutocomplete;